// Copyright © 2023 CATTLEytics Inc.

import React, { FormEvent, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Placeholder, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AlertErrorForModal from '../common/components/AlertErrorForModal';
import { ButtonVariant } from '../common/components/Button';
import ButtonModal from '../common/components/ButtonModal';
import Modal from '../common/components/Modal';
import Required from '../common/components/Required';
import { useFocusField } from '../common/hooks/useFocusField';
import { api, IconSave } from '../common/utilities';
import { AnimalGroup, AnimalGroupType, ApiResourceV1, HttpMethod, QueryKey } from '../shared';

/**
 * Component input properties.
 */
interface Props {
  /**
   * ID of the animal group to edit.
   */
  animalGroupId?: number;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  onSave: (animalGroupId: number) => void;
}

/**
 * Note modal component for creating/editing notes.
 */
export const AnimalGroupsModal = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const [description, setDescription] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<AnimalGroupType>(AnimalGroupType.Static);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const query = useQuery<AnimalGroup | undefined>(
    [QueryKey.AnimalGroups, props.animalGroupId],
    () =>
      api<AnimalGroup | undefined>(
        HttpMethod.Get,
        `${ApiResourceV1.AnimalGroups}/${props.animalGroupId}`,
      ),
    {
      enabled: !!props.animalGroupId,
    },
  );

  useEffect(() => {
    if (!query.data) {
      return;
    }
    const data = query.data;

    setName(data.name);
    setType(data.type);
    if (data.description) {
      setDescription(data.description);
    }
  }, [query.data]);

  useFocusField({ validated });

  const mutation = useMutation(
    () => {
      const payload: Record<string, undefined | string> = {
        description,
        name,
        type,
      };

      if (props.animalGroupId) {
        return api<AnimalGroup>(
          HttpMethod.Patch,
          `${ApiResourceV1.AnimalGroups}/${props.animalGroupId}`,
          { body: payload },
        );
      } else {
        return api<AnimalGroup>(HttpMethod.Post, ApiResourceV1.AnimalGroups, { body: payload });
      }
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.AnimalGroups);
      },
    },
  );

  const queryClient = useQueryClient();

  const onFormSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      const animalGroup = await mutation.mutateAsync();
      if (animalGroup) {
        props.onSave(animalGroup.id);
      } else {
        props.onClose();
      }
    } catch (err) {
      console.error('Animal group could not be saved.', err);
      setErrorMessage(t('animalGroupsModal|errorSaving'));
    }
  };

  const fields = (
    <>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Form.Label>
          {t('animalGroupsModal|nameFieldLabel')} <Required />
        </Form.Label>
        <Form.Control
          name={'name'}
          onChange={(e): void => setName(e.target.value)}
          required
          type={'text'}
          value={name}
        />
        <Form.Text className={'text-muted'}>{t('animalGroupsModal|nameFieldHint')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formDescription">
        <Form.Label>{t('animalGroupsModal|descriptionFieldLabel')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'description'}
          onChange={(e): void => setDescription(e.target.value)}
          rows={4}
          value={description}
        />
        <Form.Text className={'text-muted'}>
          {t('animalGroupsModal|descriptionFieldHint')}
        </Form.Text>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formType">
        <Form.Label>
          {t('animalGroupsModal|typeFieldLabel')} <Required />
        </Form.Label>
        <Row>
          <Col md={6}>
            <Form.Check
              checked={type === AnimalGroupType.Static}
              id={'type-static'}
              label={t('animalGroupsModal|typeStaticFieldLabel')}
              name={'type'}
              onChange={(e): void => setType(e.target.value as AnimalGroupType)}
              type={'radio'}
              value={String(AnimalGroupType.Static)}
            />
            <Form.Text className={'text-muted'}>
              {t('animalGroupsModal|typeStaticFieldHint')}
            </Form.Text>
          </Col>
          <Col md={6}>
            <Form.Check
              checked={type === AnimalGroupType.Dynamic}
              id={'type-dynamic'}
              label={t('animalGroupsModal|typeDynamicFieldLabel')}
              name={'type'}
              onChange={(e): void => setType(e.target.value as AnimalGroupType)}
              type={'radio'}
              value={String(AnimalGroupType.Dynamic)}
            />
            <Form.Text className={'text-muted'}>
              {t('animalGroupsModal|typeDynamicFieldHint')}
            </Form.Text>
          </Col>
        </Row>
      </FormGroup>
    </>
  );

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
    </Placeholder>
  );

  return (
    <Modal
      onClose={props.onClose}
      size={'lg'}
      title={
        props.animalGroupId
          ? t('animalGroupsModal|modalTitleEdit')
          : t('animalGroupsModal|modalTitleCreate')
      }
      visible={true}
    >
      <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
        {query.isLoading ? placeholder : fields}
        <AlertErrorForModal message={errorMessage} />
        <div className="modal-footer modal-footer-in-form">
          <ButtonModal
            disabled={mutation.isLoading || query.isLoading}
            label={t('animalGroupsModal|cancelButton')}
            onClick={props.onClose}
            type={'button'}
            variant={ButtonVariant.Secondary}
          />
          <ButtonModal
            busy={mutation.isLoading}
            disabled={mutation.isLoading || query.isLoading}
            icon={IconSave}
            label={t('animalGroupsModal|saveButton')}
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};
