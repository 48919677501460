// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button from '../../common/components/Button';
import { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import { Sort } from '../../common/enums';
import UserService from '../../common/services/userService';
import { useAuth } from '../../common/store/useAuth';
import { IconAssign, IconCheck, IconDelete, IconEdit } from '../../common/utilities';
import { isSiteAdminOrAbove, isSuperAdmin } from '../../common/utilities';
import { Role, RoleEnum } from '../../shared';
import { QueryKey } from '../../shared/enums';
import UserAssignRoleModal from './UserAssignRoleModal';
import UserAttachToSiteModal from './UserAttachToSiteModal';
import UserManageRolesModal from './UserManageRolesModal';
import UserModal from './UserModal';

interface Props {
  /**
   * Additional CSS classes to add to component.
   */
  className?: string;

  /**
   * Keys to filter data.
   */
  filters?: Record<string, string>;

  /**
   * Number of users to show.
   */
  initialLimit?: number;

  /**
   * Number of users to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;
}

/**
 * A table component containing animal event data.
 */
const UsersTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const userService = useInjection<UserService>(TYPES.userService);

  const auth = useAuth();
  const [editId, setEditId] = useState<number>();
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [attachToSiteModalVisible, setAttachToSiteModalVisible] = useState<boolean>(false);
  const [assignRoleModalVisible, setAssignRoleModalVisible] = useState<boolean>(false);
  const [manageRolesModalVisible, setManageRolesModalVisible] = useState<boolean>(false);

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'lastName');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );
  const filter: undefined | Record<string, string> = props.filters;

  const query = useQuery(
    [QueryKey.Users, filter, limit, offset, sortField, sortDirection],
    () =>
      userService.list({
        ...filter,
        ...{
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    { keepPreviousData: true },
  );

  const showEditModal = (editId: number): void => {
    setEditId(editId);
    setEditModalVisible(true);
  };

  const showAttachToSiteModal = (editId: number): void => {
    setEditId(editId);
    setAttachToSiteModalVisible(true);
  };

  const showAssignRoleModal = (editId: number): void => {
    setEditId(editId);
    setAssignRoleModalVisible(true);
  };

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await userService.delete(deleteId);
      await queryClient.invalidateQueries(QueryKey.Users);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
    }
    setIsDeleting(false);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return (
      <AlertError
        message={t('common|unexpectedRetrievalError', {
          value: t('users'),
        })}
      />
    );
  }

  const headers: DataTableHeader[] = [
    {
      name: 'firstName',
      label: t('First Name'),
      classNameMobile: 'col-6',
    },
    {
      name: 'lastName',
      label: t('Last Name'),
      classNameMobile: 'col-6',
    },
    {
      name: 'email',
      label: t('Email'),
      classNameMobile: 'col-12',
    },
    {
      name: 'phone',
      label: t('Phone'),
      classNameMobile: 'col-12',
    },
    {
      name: 'role',
      label: t('Role'),
      classNameMobile: 'col-12',
    },
  ];

  if (isSuperAdmin(auth)) {
    headers.push({
      name: 'isSuperAdmin',
      label: t('Super Admin'),
      classNameMobile: 'col-12',
    });
  }

  if (isSiteAdminOrAbove(auth)) {
    headers.push({ name: 'actions', sortable: false, label: 'Actions', hideMobile: true });
  }

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row) => ({
        id: String(row.id),
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        phone: row.phone,
        isSuperAdmin: row.isSuperAdmin ? <IconCheck /> : '',
        role: ((): string => {
          if (!row.roles) {
            return '-';
          }
          const found = row.roles.find((role: Role) => role.siteId === auth.siteId);
          return found ? found.role : '-';
        })(),
        actions:
          auth.isSuperAdmin || auth.role === RoleEnum.SiteAdministrator ? (
            <div onClick={(e): void => e.stopPropagation()}>
              <Dropdown as={ButtonGroup}>
                <Button
                  onClick={(): void => showEditModal(row.id)}
                  size="sm"
                  variant="outline-primary"
                >
                  <IconEdit className={'me-1'} /> {t('Edit')}
                </Button>
                <Dropdown.Toggle size="sm" split variant="outline-primary" />
                <Dropdown.Menu align="end">
                  <Dropdown.Item onClick={(): void => showEditModal(row.id)}>
                    <IconEdit className={'me-1'} /> {t('Edit')}
                  </Dropdown.Item>
                  {/*// @TODO enable site wide admin roles */}
                  {/*{false && (*/}
                  {/*  <Dropdown.Item onClick={(): void => showManageRolesModal(row.id)}>*/}
                  {/*    <IconManageRoles className={'me-1'} /> {t('Manage roles')}*/}
                  {/*  </Dropdown.Item>*/}
                  {/*)}*/}

                  <Dropdown.Item onClick={(): void => showAssignRoleModal(row.id)}>
                    <IconAssign className={'me-1'} /> {t('Change role')}
                  </Dropdown.Item>

                  <Dropdown.Item onClick={(): void => showAttachToSiteModal(row.id)}>
                    <IconAssign className={'me-1'} /> {t('Manage sites')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(): void => showDeleteConfirmModal(row.id)}>
                    <IconDelete className={'me-1'} />
                    {t('Delete')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            ''
          ),
      }));

  return (
    <div>
      <DataTable
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('usersTable|noDataFoundMessage', { value: t('users') })}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={
          auth.isSuperAdmin || auth.role === RoleEnum.SiteAdministrator
            ? (row): void => showEditModal(Number(row.id))
            : undefined
        }
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />

      {editModalVisible && (
        <UserModal editId={editId as number} onClose={(): void => setEditModalVisible(false)} />
      )}
      {attachToSiteModalVisible && (
        <UserAttachToSiteModal
          onClose={(): void => setAttachToSiteModalVisible(false)}
          userId={editId as number}
        />
      )}
      {assignRoleModalVisible && (
        <UserAssignRoleModal
          onClose={(): void => setAssignRoleModalVisible(false)}
          userId={editId as number}
        />
      )}
      {manageRolesModalVisible && (
        <UserManageRolesModal
          onClose={(): void => setManageRolesModalVisible(false)}
          userId={editId as number}
        />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: 'user' })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: 'user' })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: 'user' })}
      </ConfirmModal>
    </div>
  );
};

export default UsersTable;
