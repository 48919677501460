// Copyright © 2024 CATTLEytics Inc.

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import androidButton from '../../assets/img/android-button.png';
import iosButton from '../../assets/img/ios-button.png';
import Modal from '../common/components/Modal';
import { useAuth } from '../common/store/useAuth';
import { api } from '../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, Site } from '../shared';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * A modal to provide a user with getting started information.
 */
export const GettingStartedModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const [site, setSite] = useState<Site>();
  const auth = useAuth();

  const querySite = useQuery<Site>(
    [QueryKey.Sites, auth.siteId],
    () => api<Site>(HttpMethod.Get, `${ApiResourceV1.Sites}/${auth.siteId}`),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  useEffect(() => {
    const data = querySite.data;
    if (data) {
      setSite(data);
    }
  }, [querySite.data]);

  return (
    <Modal
      onClose={props.onClose}
      size={'lg'}
      title={t('gettingStartedModal|title')}
      visible={true}
    >
      <p>{t('gettingStartedModal|congratulations', { siteName: site?.name })}</p>
      <p>
        <strong>{t('gettingStartedModal|taskHeading')}</strong>
      </p>
      <ol>
        <li className={'mb-3'}>
          {t('gettingStartedModal|step1')}
          <div className={'d-flex'}>
            <div className={'me-3'}>
              <a
                href="https://apps.apple.com/ca/app/moo-to-do/id6448990980"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt={t('gettingStartedModal|downloadIosApp')}
                  src={iosButton}
                  style={{ height: '50px' }}
                />
              </a>
            </div>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.cattleytics.milkshake"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt={t('gettingStartedModal|downloadAndroidApp')}
                  src={androidButton}
                  style={{ height: '50px' }}
                />
              </a>
            </div>
          </div>
        </li>
        <li className={'mb-3'}>{t('gettingStartedModal|step2')}</li>
        <li className={'mb-3'}>{t('gettingStartedModal|step3')}</li>
        <li className={'mb-3'}>{t('gettingStartedModal|step4')}</li>
      </ol>
    </Modal>
  );
};
