// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, FormGroup, Placeholder, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import { useFocusField } from '../../common/hooks/useFocusField';
import SiteService from '../../common/services/siteService';
import Logger from '../../logger/logger';
import { Site } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component's input properties.
 */
interface Props {
  // Additional class names to pass to the component.
  className?: string;

  // Identifier of the item we want to edit
  editId?: number;

  // Callback when modal is closed.
  onClose: () => void;
}

/**
 * Modal to add/edit an site.
 */
const SiteModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const siteService = useInjection<SiteService>(TYPES.siteService);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);
  const [data, setData] = useState<Record<string, string>>({
    name: '',
  });

  const query = useQuery<Site | undefined>(
    [QueryKey.Sites, props.editId],
    () => siteService.getSite(props.editId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  useEffect(() => {
    if (query.data) {
      setData({
        name: query.data.name,
      });
    }
  }, [query.data]);

  const mutation = useMutation(
    () => {
      if (!data) {
        throw Error('no data to save');
      }
      const newData: Record<string, string> = {
        name: data.name,
      };

      if (props.editId) {
        return siteService.patch(props.editId, newData);
      } else {
        return siteService.post(newData);
      }
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries(QueryKey.Sites);
      },
    },
  );

  useFocusField({ validated });

  const queryClient = useQueryClient();

  const onHandleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      logger.error(`Site could not be saved.`, err, data);
      setErrorMessage(t(`Site could not be saved.`));
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Form.Label>
          {t('Name')} <Required />
        </Form.Label>
        <Form.Control
          name={'name'}
          onChange={onHandleInputChange}
          placeholder={t('Example: {{value}}', { value: `Maggie's Farm` })}
          required
          type={'text'}
          value={data.name}
        />
        <Form.Text className={'text-muted'}>The name of this site.</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>

      <AlertErrorForModal message={errorMessage} />

      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`${props.editId ? 'Edit' : 'Add'} Site`}
      visible={true}
    >
      {query.isError && (
        <AlertError message={`Something unexpected hapsiteed while retrieving site.`} />
      )}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          <PlaceholderButton xs={2} />
        </div>
      )}
    </Modal>
  );
};

export default SiteModal;
