// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Form, FormGroup, Placeholder, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button from '../../common/components/Button';
import { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import DiagnosisSelect from '../../common/components/DiagnosisSelect';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import DiagnosisRegime from '../../common/entities/diagnosisRegime';
import { useFocusField } from '../../common/hooks/useFocusField';
import DiagnosisRegimeService from '../../common/services/diagnosisRegimeService';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared/enums';

/**
 * Component's input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * Callback on successful save. Note: onClose is NOT called when onSave is set
   * @param diagnosisRegime
   */
  onSave?: (diagnosisRegime: DiagnosisRegime) => void;
}

/**
 * Modal to add/edit a diagnosis regime.
 */
const DiagnosisRegimeModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);

  const diagnosisRegimeService = useInjection<DiagnosisRegimeService>(TYPES.diagnosisRegimeService);

  const [showAddDiagnosisModal, setShowAddDiagnosisModal] = useState<boolean>(false);
  //const [diagnosisId, setDiagnosisId] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [diagnosisId, setDiagnosisId] = useState<string>('');

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();

  const query = useQuery<DiagnosisRegime | undefined>(
    [QueryKey.DiagnosisRegimes, props.editId],
    () => diagnosisRegimeService.get(props.editId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      onSuccess: (data) => {
        if (!data) {
          return;
        }
        if (data.description) {
          setDescription(data.description);
        }
        setName(data.name);
        setDiagnosisId(String(data.diagnosisId));
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const mutation = useMutation<DiagnosisRegime | undefined>(
    () => {
      const payload = {
        name: name,
        description: description,
        diagnosisId: Number(diagnosisId),
      };

      if (props.editId) {
        return diagnosisRegimeService.patch(props.editId, payload);
      } else {
        return diagnosisRegimeService.post(payload);
      }
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries(QueryKey.DiagnosisRegimes);
      },
    },
  );

  useFocusField({ validated });

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!props.editId) {
      return;
    }
    setIsDeleting(true);
    try {
      await diagnosisRegimeService.delete(props.editId);
      await queryClient.invalidateQueries(QueryKey.DiagnosisRegimes);
      setDeleteConfirmModalVisible(false);
      setIsDeleting(false);
      props.onClose();
    } catch (err) {
      setIsDeleting(false);
      logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage(t('common|itemCannotDeleteError'));
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      const dr = await mutation.mutateAsync();
      if (props.onSave && dr) {
        props.onSave(dr);
      } else {
        props.onClose();
      }
    } catch (err) {
      logger.error(`Diagnosis regime could not be saved.`, err);
      setErrorMessage(
        t(`{{typeLabel}} could not be saved.`, {
          typeLabel: t('Diagnosis Regime'),
        }),
      );
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <Form.Group className="form-group mb-3" controlId="formDiagnosis">
        <Form.Label>
          {t('Diagnosis')} <Required />
        </Form.Label>
        <DiagnosisSelect
          onChange={(e): void => {
            setDiagnosisId(e.target.value);
            if (!name && Number(e.target.value) > 0) {
              setName(`${e.target.options[e.target.selectedIndex].text} - Custom`);
            }
          }}
          onCloseAddModal={(): void => setShowAddDiagnosisModal(false)}
          required={true}
          showAddModal={showAddDiagnosisModal}
          value={diagnosisId}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a diagnosis from the dropdown or')}</span>
          <Button onClick={(): void => setShowAddDiagnosisModal(true)} size={'sm'} variant={'link'}>
            {t('add diagnosis')}
          </Button>
        </Form.Text>
      </Form.Group>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Form.Label>
          {t('Name')} <Required />
        </Form.Label>
        <Form.Control
          name={'name'}
          onChange={(e): void => setName(e.target.value)}
          placeholder={t('diagnosisRegimeModal|regimeNamePlaceholder')}
          required
          type={'text'}
          value={name}
        />
        <Form.Text className={'text-muted'}>
          {t('diagnosisRegimeModal|regimeNameInstructions')}
        </Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formDescription">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'description'}
          onChange={(e): void => setDescription(e.target.value)}
          placeholder={t('Example: {{value}}', {
            value: t('diagnosisRegimeModal|regimeDescriptionExample'),
          })}
          rows={4}
          value={description}
        />
        <Form.Text className={'text-muted'}>
          {t('diagnosisRegimeModal|regimeDescriptionInstructions')}
        </Form.Text>
      </FormGroup>
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete
            disabled={mutation.isLoading || query.isLoading}
            onClick={(): void => setDeleteConfirmModalVisible(true)}
          />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`${props.editId ? t('Edit') : t('common|add')} Diagnosis Regime`}
      visible={true}
    >
      {query.isError && (
        <AlertErrorForModal message={t('diagnosisRegimeModal|diagnosisRetrievalError')} />
      )}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          {props.editId && <PlaceholderButton variant={'danger'} xs={2} />}
          <PlaceholderButton xs={2} />
        </div>
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('regime') })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('regime') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: t('regime') })}
      </ConfirmModal>
    </Modal>
  );
};

export default DiagnosisRegimeModal;
