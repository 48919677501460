// Copyright © 2023 CATTLEytics Inc.

import { FC, useCallback, useState } from 'react';
import { Dropdown, DropdownButton, InputGroup, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  Mention,
  MentionsInput,
  MentionsInputProps,
  SuggestionDataItem,
} from 'react-mentions-continued';
import { useQuery } from 'react-query';

import {
  Animal,
  ApiResourceV1,
  HttpMethod,
  isNullOrWhitespace,
  nameTag,
  QueryKey,
  TaskTemplate,
} from '../../../shared';
import { WithCount } from '../../../tasks/components/utilities';
import { api, IconEvent } from '../../utilities';
import Button from '../Button';

interface Props extends Omit<MentionsInputProps, 'children'> {
  isInvalid?: boolean;
  isValid?: boolean;
  required?: boolean;
}

/**
 * Gets the list of animals for the MentionsInput api.
 *
 * Based on example from https://github.com/signavio/react-mentions/blob/master/demo/src/examples/AsyncGithubUserMentions.js
 */
function fetchAnimals(query: string, callback: (data: SuggestionDataItem[]) => void): void {
  api<Animal[]>('GET', ApiResourceV1.Animals, {
    params: { search: query, includeReferenceAnimals: String(false), status: 'active' },
  })
    .then((res) =>
      res.map<SuggestionDataItem>(({ id, name, primaryTag }) => ({
        id,
        display: nameTag(primaryTag, name),
      })),
    )
    .then(callback);
}

const pageSize = 50_000;

export const AnimalMentionsProtocolsInput: FC<
  Props & { onTemplateClick: (template: TaskTemplate | undefined) => void; template?: TaskTemplate }
> = (props) => {
  const { t } = useTranslation();
  // The following input element is a way to trigger a javascript invalid
  // state so HTML5 form validation can be used since the input element
  // inside the typeahead component does not support this
  // if one or more items have been selected this input element will be
  // set to 1 thus satisfying the required validator
  const inputHelper = (
    <input
      aria-hidden={true}
      name={props.name}
      readOnly={true}
      required={props.required}
      style={{ display: 'none' }}
      tabIndex={-1}
      type={'text'}
      value={
        props.isInvalid || (props.isValid && props.required && isNullOrWhitespace(props.value))
          ? ''
          : '1'
      }
    />
  );

  const onChange = useCallback(
    (ev, nv, pv, m) => {
      setSearchQuery(ev.target.value);
      if (props.onChange) {
        props.onChange(ev, nv, pv, m);
      }
    },
    [props],
  );
  const onTemplateChange = useCallback(
    (taskTemplate) => {
      setSelected(taskTemplate);

      if (props.onTemplateClick) {
        props.onTemplateClick(taskTemplate);
      }
    },
    [props],
  );

  const [selected, setSelected] = useState<TaskTemplate | undefined>(props.template);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [limit] = useState<number>(pageSize);
  const [offset] = useState<number>(0);

  const { data: templates } = useQuery<WithCount<TaskTemplate>>(
    [QueryKey.TaskTemplates, 'templates', limit, offset, searchQuery],
    () =>
      api(HttpMethod.Get, ApiResourceV1.TaskTemplates, {
        params: {
          search: searchQuery,
          sortField: 'title',
          sortDirection: 'asc',
          limit: String(limit),
          offset: String(offset),
        },
      }),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  return (
    <div className={`${props.isInvalid || !props.isValid ? 'is-invalid' : 'is-valid'}`}>
      {inputHelper}
      <InputGroup>
        <MentionsInput
          singleLine
          {...props}
          className="mentions"
          onChange={onChange}
          style={{
            width: !selected ? '100%' : '60%',
            boxSizing: 'border-box',
            borderEndEndRadius: 0,
            borderRadius: 0,
            flexShrink: 1,
          }}
        >
          <Mention
            data={fetchAnimals}
            displayTransform={(_id, name): string => `@${name}`}
            trigger="@"
          />
        </MentionsInput>
        {selected ? (
          <>
            <InputGroup.Text>
              {t('Use template')}: {selected.title}{' '}
            </InputGroup.Text>
            <Button onClick={(): void => onTemplateChange(undefined)}>{t('Unlink')}</Button>
          </>
        ) : (
          <></>
        )}

        {!selected && (
          <TemplateSuggestions
            onClick={onTemplateChange}
            search={searchQuery}
            templates={templates}
          />
        )}
      </InputGroup>
    </div>
  );
};

function TemplateSuggestions(props: {
  onClick: (template: TaskTemplate) => void;
  search: string;
  templates: WithCount<TaskTemplate> | undefined;
}): JSX.Element {
  const protocolsShown = 3;
  if (props.templates === undefined) {
    return <></>;
  }
  if (isNullOrWhitespace(props.search) && props.templates.items.length === 0) {
    return <></>;
  }
  if (isNullOrWhitespace(props.search) || props.templates.items.length > 0) {
    const len = props.templates.items.length;
    return (
      <Stack
        className={`${
          len >= protocolsShown ? 'justify-content-between' : 'justify-content-start '
        } align-items-start m-1 gap-2`}
        direction="horizontal"
      >
        <span className=" m-1">Link to Protocol:</span>
        <Stack
          className={`${
            len >= protocolsShown ? 'flex-shrink-1' : ''
          }  align-items-start justify-content-start gap-2`}
          direction="horizontal"
        >
          {props.templates.items.slice(0, protocolsShown).map((item, index) => {
            return (
              <Button
                key={index}
                onClick={(): void => {
                  props.onClick(item);
                }}
                style={{}}
                variant="outline-primary"
              >
                <IconEvent style={{ marginInlineEnd: 5, marginInlineStart: 5 }} />
                {item.title}
              </Button>
            );
          })}
        </Stack>
        {len > protocolsShown && (
          <DropdownButton className=" m-1" title={'More Protocols'} variant="outline-primary">
            {props.templates.items.slice(protocolsShown).map((item, _index) => {
              return (
                <Dropdown.Item
                  onClick={(): void => {
                    props.onClick(item);
                  }}
                >
                  <IconEvent style={{ marginInlineEnd: 5, marginInlineStart: 5 }} />
                  {item.title}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        )}
      </Stack>
    );
  }
  return <></>;
}
