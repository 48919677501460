// Copyright © 2024 CATTLEytics Inc.

import {
  add,
  addDays,
  addHours,
  endOfDay,
  isAfter,
  isBefore,
  isPast,
  isSameDay,
  isToday,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { endOfSiteDay, isNullOrWhitespace, Task, TaskPriority } from '../../shared';
import { TaskMode } from '../../shared/enums/taskMode';
import { isOverdue } from '../../shared/utilities/task';

export function trimTo(description?: string, size = 50): string {
  const validDescription = description?.trim() ?? '';
  return validDescription.length > size
    ? validDescription.substring(0, size) + '...'
    : validDescription;
}

interface TaskState {
  asap: boolean;
  overdue: boolean;
  thisWeek: boolean;
  today: boolean;
  whenever: boolean;
}

export function getTaskStatus(state: TaskState): string {
  const taskGroupName = state.overdue
    ? 'Overdue'
    : state.today
    ? 'Today'
    : state.thisWeek
    ? 'Week'
    : 'Whenever';
  return taskGroupName;
}

export function getTaskState(task: Task, timeZone: string): TaskState {
  const now = new Date();
  const todayStart = startOfDay(now);
  const todayEnd = endOfDay(now);
  const weekStart = addDays(startOfDay(now), 1);
  const weekEnd = addDays(endOfDay(weekStart), 7);

  const startDate = task.startDate ? utcToZonedTime(task.startDate, timeZone) : undefined;
  // const dueDate = task.dueDate ? utcToZonedTime(task.dueDate, timeZone) : undefined;

  const isTaskOverdue = isOverdue(task, timeZone);

  const isTodayTask = startDate ? startDate >= todayStart && startDate <= todayEnd : false;
  const isWeekTask = startDate ? startDate >= weekStart && startDate <= weekEnd : false;

  return {
    overdue: isTaskOverdue,
    today: isTodayTask,
    thisWeek: isWeekTask,
    whenever: false,
    asap: false,
  };
}

export function getTaskStateByDueDate(dueDate: string, timeZone: string): TaskState {
  if (isNullOrWhitespace(dueDate)) {
    return {
      overdue: false,
      today: false,
      thisWeek: false,
      whenever: true,
      asap: false,
    };
  }
  const validDueDate = parseISO(dueDate);
  const isOverdue = !!dueDate && !isToday(validDueDate) && isPast(validDueDate);

  const isTodayTask =
    endOfSiteDay(validDueDate, timeZone).toISOString() ===
    endOfSiteDay(new Date(), timeZone).toISOString();
  const isWeekTask =
    endOfSiteDay(validDueDate, timeZone).toISOString() ===
    endOfSiteDay(addDays(new Date(), 7), timeZone).toISOString();
  const isAsap = validDueDate.toISOString() === addHours(new Date(), 1).toISOString();
  const isWhenever = !isTodayTask && !isWeekTask && !isAsap;

  return {
    overdue: isOverdue,
    today: isTodayTask,
    thisWeek: isWeekTask,
    whenever: isWhenever,
    asap: isAsap,
  };
}

export function getPriorityOrdered(): TaskPriority[] {
  const items = Object.values([
    TaskPriority.Highest,
    TaskPriority.High,
    TaskPriority.Medium,
    TaskPriority.Low,
    TaskPriority.Lowest,
  ]);

  return items as TaskPriority[];
}

export interface WithCount<T> {
  count: number;
  items: T[];
}

export const days = ['SUN', 'MON', 'TUE', 'WED', 'THR', 'FRI', 'SAT'];

/**
 * Converts the input array of indexes into a string representation of the days of the week.
 */
export function convertDaysFromIndex(input: number[]): string {
  if (input == null || input === undefined || input.length === 0) {
    return '';
  }
  // converts the input [0,1] into a string like 'SUN,MON'. And sorts the day of week order.
  return days.filter((_, i) => input.includes(i)).join(',');
}

/**
 * Converts the input string into an array of indexes representing the days of the week.
 */
export function convertDaysFromString(input: string): number[] {
  if (isNullOrWhitespace(input)) {
    return [];
  }

  return input.split(',').map((d) => days.indexOf(d));
}

export function computeCurrentTaskDate(
  task: Pick<
    Task,
    | 'startDate'
    | 'scheduleReset'
    | 'completedDate'
    | 'dueDate'
    | 'scheduleMode'
    | 'scheduleRepeatInterval'
    | 'scheduleRepeatDayOfPeriod'
    | 'scheduleReset'
    | 'scheduleRepeat'
    | 'scheduleRepeatDayOfWeek'
  >,
  options: { timeZone: string },
): { dueDate: Date; startDate: Date } | undefined {
  if (!task.startDate) {
    // is this correct?
    return undefined;
  }

  return computeNextTaskDate(
    { ...task, dueDate: task.startDate, completedDate: undefined },
    options,
  );
}

export function computeNextTaskDate(
  task: Pick<
    Task,
    | 'startDate'
    | 'scheduleReset'
    | 'completedDate'
    | 'dueDate'
    | 'scheduleMode'
    | 'scheduleRepeatInterval'
    | 'scheduleRepeatDayOfPeriod'
    | 'scheduleReset'
    | 'scheduleRepeat'
    | 'scheduleRepeatDayOfWeek'
  >,
  options: { timeZone: string },
): { dueDate: Date; startDate: Date } | undefined {
  if (!task.startDate) {
    // is this correct?
    return undefined;
  }
  // console.debug(`computeNextTaskDate`, { task, options });

  // important fields
  // startDate, dueDate, and completedDate
  // eg.
  // 2025-01-12, 2025-01-19, 2025-01-17
  // repeat weekly, on sundays (j19, j26, f2)
  const isResetScheduleMode = task.scheduleReset ?? false;
  // const isComplete = task.completed ?? false;
  let isCompletedMoveForward = false;

  const dueDate = parseISO(task.dueDate ?? '');
  const startDate = parseISO(task.startDate);
  const completedDate = parseISO(task.completedDate ?? '');

  // use completedDate when available.
  let nextStartDate = !isResetScheduleMode
    ? dueDate
    : isAfter(startDate, completedDate)
    ? // when someone completes it really early, use the dueDate as the next start
      dueDate
    : isBefore(dueDate, completedDate)
    ? completedDate ?? dueDate
    : dueDate;

  if (task.completedDate && !isResetScheduleMode) {
    if (isBefore(dueDate, completedDate) || isSameDay(dueDate, completedDate)) {
      isCompletedMoveForward = true;
      // then what
      // dueDate = '2024-01-18'
      // completedDate = '2024-01-19'
      // daily: every 1 day
      // nextStartDate: '2024-01-20'
      // daily: every 3 days, no reset
      // nextStartDate: '2024-01-18', dueDate: '2024-01-20'
      // daily: every 3 days, reset
      // nextStartDate: '2024-01-18', dueDate: '2024-01-20'
    }
  }
  if (task.scheduleReset && isAfter(completedDate, startDate)) {
    isCompletedMoveForward = true;
    nextStartDate = completedDate;
  }

  if (task.scheduleMode === TaskMode.Repeat) {
    if (task.scheduleRepeat === 'daily') {
      if (isCompletedMoveForward) {
        nextStartDate = add(nextStartDate, { days: task.scheduleRepeatInterval ?? 1 });
      }
      let nextDueDate = add(nextStartDate, { days: task.scheduleRepeatInterval ?? 1 });

      if (isSameDay(nextStartDate, nextDueDate)) {
        nextDueDate = add(nextDueDate, { days: task.scheduleRepeatInterval ?? 1 });
      }

      return { startDate: nextStartDate, dueDate: nextDueDate };
    }
    if (task.scheduleRepeat === 'weekly') {
      // get the planned days of week - default to SUNDAY
      const plannedDaysOfWeek = convertDaysFromString(task.scheduleRepeatDayOfWeek ?? 'SUN'); // MON,TUE,THR => [1,2,4]
      //
      const upcomingPlannedDays = plannedDaysOfWeek.filter((t) => t > nextStartDate.getDay());
      // const nextPlannedDays = plannedDaysOfWeek;
      // if the tt list has a dow that is > than the current day week, the next day will be in the current week.
      const interval = task.scheduleRepeatInterval ?? 1;
      // when interval is 1, check if any of the days occur in the current week passed today.
      // thisweek?
      const nextPlannedDays =
        interval === 1
          ? upcomingPlannedDays.length > 0
            ? upcomingPlannedDays
            : plannedDaysOfWeek
          : plannedDaysOfWeek;

      const addWeeks = interval === 1 ? (upcomingPlannedDays.length > 0 ? 0 : 1) : interval;

      const nextDate = add(
        startOfWeek(add(nextStartDate, { weeks: addWeeks }), { weekStartsOn: 0 }),
        {
          days: nextPlannedDays[0],
        },
      );

      return { startDate: nextStartDate, dueDate: nextDate };
    }
    if (task.scheduleRepeat === 'monthly') {
      let nextDueDate = add(nextStartDate, { months: task.scheduleRepeatInterval ?? 1 });

      if (task.scheduleRepeatDayOfPeriod) {
        nextDueDate = add(startOfMonth(nextDueDate), { days: task.scheduleRepeatDayOfPeriod - 1 });
      }
      // if the task has task.scheduleRepeatDayOfPeriod set, adjust the time to those days of week.

      return { startDate: nextStartDate, dueDate: nextDueDate };
    }
    if (task.scheduleRepeat === 'yearly') {
      const sd = zonedTimeToUtc(nextStartDate, options.timeZone);
      let nextDueDate = add(sd, { years: task.scheduleRepeatInterval ?? 1 });

      // if the task has task.scheduleRepeatDayOfWeek set, adjust the time to those days of week.
      if (task.scheduleRepeatDayOfPeriod) {
        nextDueDate = add(startOfMonth(nextDueDate), { days: task.scheduleRepeatDayOfPeriod - 1 });
      }
      return { startDate: nextStartDate, dueDate: nextDueDate };
    }
  }
  return undefined;
}

export function appendTaskGroup(
  task: Task,
  timeZone: string,
): Task & { isOverdue: boolean; taskGroupName: string; taskGroupOrder: number } {
  const taskState = getTaskState(task, timeZone);
  const taskGroupName = getTaskStatus(taskState);
  const taskGroupOrder = taskState.overdue ? 0 : taskState.today ? 1 : taskState.thisWeek ? 2 : 3;
  return { ...task, taskGroupName, taskGroupOrder, isOverdue: taskState.overdue } as Task & {
    isOverdue: boolean;
    taskGroupName: string;
    taskGroupOrder: number;
  };
}
