// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Placeholder, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import { useFocusField } from '../../common/hooks/useFocusField';
import PenService from '../../common/services/penService';
import { IconDelete } from '../../common/utilities';
import Logger from '../../logger/logger';
import { Pen } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component's input properties.
 */
interface Props {
  // Additional class names to pass to the component.
  className?: string;

  // Identifier of the item we want to edit
  editId?: number;

  // Callback when modal is closed.
  onClose: () => void;
}

/**
 * Modal to add/edit an animal pen.
 */
const PenModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const history = useHistory();
  const penService = useInjection<PenService>(TYPES.penService);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);

  const [capacity, setCapacity] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>();

  const query = useQuery<Pen | undefined>(
    [QueryKey.Pens, props.editId],
    () => penService.get(props.editId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  useEffect(() => {
    if (query.data) {
      if (query.data.capacity) {
        setCapacity(String(query.data.capacity));
      }
      if (query.data.description) {
        setDescription(query.data.description);
      }
      setName(query.data.name);
    }
  }, [query.data]);

  const mutation = useMutation(
    () => {
      const data: Partial<Pen> = {
        name: name,
        description: description,
        capacity: capacity ? Number(capacity) : undefined,
      };

      if (props.editId) {
        return penService.patch(props.editId, data);
      } else {
        return penService.post(data);
      }
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries(QueryKey.Pens);
      },
    },
  );

  const mutationDelete = useMutation<null>(
    () => {
      if (props.editId) {
        return penService.delete(props.editId);
      }
      return new Promise(() => null);
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries(QueryKey.Pens);
      },
    },
  );

  useFocusField({ validated });

  const queryClient = useQueryClient();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      logger.error(`Pen could not be saved.`, err);
      setErrorMessage(t(`Pen could not be saved.`));
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Form.Label>
          Name <Required />
        </Form.Label>
        <Form.Control
          name={'name'}
          onChange={(e): void => setName(e.target.value)}
          placeholder={'Example: A'}
          required
          type={'text'}
          value={name}
        />
        <Form.Text className={'text-muted'}>{t('A short name to describe this pen.')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="form-group mb-3" controlId="formDescription">
        <Form.Label>
          {t('Description')} <Required />
        </Form.Label>
        <Form.Control
          as={'textarea'}
          name={'description'}
          onChange={(e): void => setDescription(e.target.value)}
          placeholder={t('Example: Fresh Cows')}
          required
          type={'text'}
          value={description}
        />
        <Form.Text className={'text-muted'}>{t('A brief description of this pen.')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>

      <FormGroup className="form-group mb-3" controlId="formCapacity">
        <Form.Label>{t('Capacity')}</Form.Label>
        <Form.Control
          min={0}
          name={'capacity'}
          onChange={(e): void => setCapacity(e.target.value)}
          placeholder={'Example: 50'}
          type={'number'}
          value={capacity}
        />
        <Form.Text className={'text-muted'}>{t('penModal|penCapacityText')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>

      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete onClick={(): void => setDeleteConfirmModalVisible(true)} />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={props.editId ? t('Edit Pen') : t('Add Pen')}
      visible={true}
    >
      {query.isError && (
        <AlertError message={`Something unexpected happened while retrieving pen.`} />
      )}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          <PlaceholderButton xs={2} />
        </div>
      )}
      <ConfirmModal
        busy={mutationDelete.isLoading}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okIcon={IconDelete}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('Pen') })}
        okOnClick={async (): Promise<void> => {
          try {
            await mutationDelete.mutateAsync();
            setDeleteConfirmModalVisible(false);
            history.push('/pens');
          } catch (err) {
            logger.error('This item failed to be deleted', err, mutationDelete.data);
            setDeleteConfirmModalErrorMessage(`${t('common|itemCannotDeleteError')} ${err}`);
          }
        }}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('Pen') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: t('Pen') })}
      </ConfirmModal>
    </Modal>
  );
};

export default PenModal;
