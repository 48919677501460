// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import FormPlaceholder from '../../common/components/FormPlaceholder';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import TerminationCode from '../../common/entities/terminationCode';
import { useFocusField } from '../../common/hooks/useFocusField';
import { api } from '../../common/utilities';
import Logger from '../../logger/logger';
import { AnimalStatus, ApiResourceV1, HttpMethod, QueryKey } from '../../shared/enums';

function stringToAnimalStatus(input: string): AnimalStatus | undefined {
  return Object.values(AnimalStatus).find((v) => v === input);
}

/**
 * Component's input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal to add/edit a terminationCode.
 */
const TerminationCodeModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [status, setStatus] = useState<AnimalStatus | undefined>();

  const query = useQuery<TerminationCode | undefined>(
    [QueryKey.TerminationCode, props.editId],
    () =>
      api<TerminationCode>(
        HttpMethod.Get,
        `${ApiResourceV1.TerminationCode}/${props.editId ?? -1}`,
      ),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );
  useEffect(() => {
    if (query.data) {
      const data = query.data;
      if (!data) {
        return;
      }
      if (data.description) {
        setDescription(data.description);
      }

      setName(data.name);
      setStatus(data.status);
    }
  }, [query.data]);

  const mutation = useMutation(
    () => {
      const payload = {
        name: name,
        description: description,
        status: status,
      };

      if (props.editId) {
        return api<TerminationCode>(
          HttpMethod.Patch,
          `${ApiResourceV1.TerminationCode}/${props.editId}`,
          {
            body: payload,
          },
        );
      } else {
        return api<TerminationCode>(HttpMethod.Post, ApiResourceV1.TerminationCode, {
          body: payload,
        });
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKey.TerminationCode),
    },
  );

  const deleteItem = async (): Promise<void> => {
    if (!props.editId) {
      return;
    }
    setIsDeleting(true);
    try {
      await api('DELETE', `${ApiResourceV1.TerminationCode}/${props.editId}`);
      await queryClient.invalidateQueries(QueryKey.TerminationCode);
      setDeleteConfirmModalVisible(false);
      setIsDeleting(false);
      props.onClose();
    } catch (err) {
      logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
      setIsDeleting(false);
    }
  };

  useFocusField({ validated });

  const queryClient = useQueryClient();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      const terminationCode = await mutation.mutateAsync();

      props.onClose();
      if (terminationCode) {
        history.push(`/lists/termination-codes/active`);
      }
    } catch (err) {
      logger.error(`TerminationCode could not be saved.`, err);
      setErrorMessage(
        t(`{{typeLabel}} could not be saved.`, {
          typeLabel: t('entity|terminationCode'),
        }),
      );
    }
  };

  const placeholder = <FormPlaceholder showDeleteButton={!!props.editId} />;
  const animalStatusValues = Object.values(AnimalStatus);

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Form.Label>
          {t('Name')} <Required />
        </Form.Label>
        <Form.Control
          name={'name'}
          onChange={(e): void => setName(e.target.value)}
          placeholder={t('terminationCodeModal|terminationCodeNamePlaceholder')}
          required
          type={'text'}
          value={name}
        />
        <Form.Text className={'text-muted'}>
          {t('terminationCodeModal|terminationCodeNameInstructions')}
        </Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formDescription">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'description'}
          onChange={(e): void => setDescription(e.target.value)}
          placeholder={t('terminationCodeModal|terminationCodeDescriptionPlaceholder')}
          rows={4}
          value={description}
        />
        <Form.Text className={'text-muted'}>
          {t('terminationCodeModal|terminationCodeDescriptionInstructions')}
        </Form.Text>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formStatus">
        <Form.Label>{t('Status')}</Form.Label>
        <Form.Select
          onChange={(ev): void => {
            setStatus(stringToAnimalStatus(ev.target.value));
          }}
          value={status}
        >
          <option>Unset</option>
          {animalStatusValues.map((s) => (
            <option key={`status_${s}`} value={s}>
              {t(`animalTable|statusLabels|${s}`)}
            </option>
          ))}
        </Form.Select>
        <Form.Text className={'text-muted'}>
          {t('terminationCodeModal|terminationCodeStatusInstructions')}
        </Form.Text>
      </FormGroup>

      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete
            busy={mutation.isLoading || isDeleting}
            disabled={isDeleting || mutation.isLoading || query.isLoading}
            onClick={(): void => setDeleteConfirmModalVisible(true)}
          />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`${props.editId ? t('Modify') : t('common|add')} ${t('entity|terminationCode')}`}
      visible={true}
    >
      {query.isError && (
        <AlertErrorForModal
          message={t(`common|unexpectedRetrievalError`, {
            value: t('terminationCode.'),
          })}
        />
      )}
      {query.isFetching ? placeholder : form}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('entity|terminationCode') })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('entity|terminationCode') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', {
          value: t('entity|terminationCode'),
        })}
      </ConfirmModal>
    </Modal>
  );
};

export default TerminationCodeModal;
