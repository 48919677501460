// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button, { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import FormPlaceholder from '../../common/components/FormPlaceholder';
import ImageUploaded from '../../common/components/ImageUploaded';
import ImageUploading from '../../common/components/ImageUploading';
import Modal from '../../common/components/Modal';
import NoImageUploaded from '../../common/components/NoImageUploaded';
import ProductClassSelect from '../../common/components/ProductClassSelect';
import ProductTypeSelect from '../../common/components/ProductTypeSelect';
import Required from '../../common/components/Required';
import Product from '../../common/entities/product';
import { useFileService } from '../../common/hooks/useFileService';
import { useFocusField } from '../../common/hooks/useFocusField';
import { ImageUrl } from '../../common/interfaces';
import ProductService, { PayloadPost } from '../../common/services/productService';
import { IconCreate, IconDelete } from '../../common/utilities';
import { ImageSize, resizeImage } from '../../common/utilities/imageResize';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared/enums';

/**
 * Component's input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal to add/edit a product.
 */
const ProductModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const history = useHistory();
  const productService = useInjection<ProductService>(TYPES.productService);
  const fileService = useFileService();

  const [showAddProductClassModal, setShowAddProductClassModal] = useState<boolean>(false);
  const [showAddProductTypeModal, setShowAddProductTypeModal] = useState<boolean>(false);
  const [showUploadImageForm, setShowUploadImageForm] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [busyFileUploading, setBusyFileUploading] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [imageUrls, setImageUrls] = useState<ImageUrl[]>([]);
  const [instructions, setInstructions] = useState<string>('');
  const [productClassId, setProductClassId] = useState<string>('');
  const [productTypeId, setProductTypeId] = useState<string>('');
  const [websiteUrl, setWebsiteUrl] = useState<string>('');

  const query = useQuery<Product | undefined>(
    [QueryKey.Products, props.editId],
    () => productService.get(props.editId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );
  useEffect(() => {
    if (query.data) {
      const data = query.data;
      if (!data) {
        return;
      }
      if (data.description) {
        setDescription(data.description);
      }
      if (data.instructions) {
        setInstructions(data.instructions);
      }

      if (data.imageUrls && !data.imageUrlsSigned) {
        setImageUrls(data.imageUrls.map((url) => ({ key: url })));
      }
      if (data.imageUrlsSigned) {
        setImageUrls(data.imageUrlsSigned);
      }
      if (data.websiteUrl) {
        setWebsiteUrl(data.websiteUrl);
      }

      setName(data.name);

      setProductClassId(String(data.productClassId));
      setProductTypeId(String(data.productTypeId));
    }
  }, [query.data]);

  const mutation = useMutation(
    () => {
      const payload: PayloadPost = {
        name: name,
        description: description,
        imageUrls: imageUrls.map((image) => image.key),
        instructions: instructions,
        productClassId: Number(productClassId),
        productTypeId: Number(productTypeId),
        websiteUrl: websiteUrl,
      };

      if (props.editId) {
        return productService.patch(props.editId, payload);
      } else {
        return productService.post(payload);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKey.Products),
    },
  );
  const removeImage = (index: number): void => {
    const updatedImageUrls = [...imageUrls];
    delete updatedImageUrls[index];
    setImageUrls(Object.values(updatedImageUrls));
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = ((event.target as HTMLInputElement).files as FileList)[0];
    setBusyFileUploading(true);
    await uploadFile(await resizeImage(file, ImageSize.Small));
    setBusyFileUploading(false);
  };

  const uploadFile = async (file: File): Promise<void> => {
    const fileResult = await fileService.upload(file, undefined, 0);
    if (fileResult.signedUrl) {
      const newImageUrls = [...imageUrls];
      newImageUrls.push({ key: fileResult.key, signedUrl: fileResult.signedUrl });
      setImageUrls(newImageUrls);
      setShowUploadImageForm(false);
    }
  };

  const deleteItem = async (): Promise<void> => {
    if (!props.editId) {
      return;
    }
    setIsDeleting(true);
    try {
      await productService.delete(props.editId);
      await queryClient.invalidateQueries(QueryKey.Products);
      setDeleteConfirmModalVisible(false);
      setIsDeleting(false);
      props.onClose();
    } catch (err) {
      logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage('This item could not be deleted.');
      setIsDeleting(false);
    }
  };

  useFocusField({ validated });

  const queryClient = useQueryClient();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      const product = await mutation.mutateAsync();

      props.onClose();
      if (product) {
        history.push(`/products/${product.id}`);
      }
    } catch (err) {
      logger.error(`Product could not be saved.`, err);
      setErrorMessage(
        t(`{{typeLabel}} could not be saved.`, {
          typeLabel: t('Product'),
        }),
      );
    }
  };

  const placeholder = <FormPlaceholder showDeleteButton={!!props.editId} />;

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Form.Label>
          {t('Name')} <Required />
        </Form.Label>
        <Form.Control
          name={'name'}
          onChange={(e): void => setName(e.target.value)}
          placeholder={t('productModal|productNamePlaceholder')}
          required
          type={'text'}
          value={name}
        />
        <Form.Text className={'text-muted'}>{t('productModal|productNameInstructions')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formDescription">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'description'}
          onChange={(e): void => setDescription(e.target.value)}
          placeholder={t('What is this product? What does it do?')}
          rows={4}
          value={description}
        />
        <Form.Text className={'text-muted'}>{t('productModal|productNotesInstructions')}</Form.Text>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formInstructions">
        <Form.Label>{t('Instructions')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'instructions'}
          onChange={(e): void => setInstructions(e.target.value)}
          placeholder={t('productModal|productInstructionsPlaceholder')}
          rows={4}
          value={instructions}
        />
        <Form.Text className={'text-muted'}>{t('productModal|productUseInstructions')}</Form.Text>
      </FormGroup>

      <Form.Group className="form-group mb-3" controlId="formImages">
        <Form.Label>{t('Images')}</Form.Label>
        <div className={'d-flex flex-wrap justify-content-start mb-3 gap-2'}>
          {imageUrls.map((imageUrl, index) => (
            <div key={index}>
              {imageUrl.signedUrl && (
                <div className={'mt-3 text-center'}>
                  {!busyFileUploading && !imageUrls[index].signedUrl && <NoImageUploaded />}
                  {!busyFileUploading && imageUrls[index].signedUrl && (
                    <ImageUploaded
                      alt={t('Product image')}
                      src={imageUrls[index].signedUrl ?? ''}
                    />
                  )}

                  <Button
                    className={'mt-1'}
                    icon={IconDelete}
                    onClick={(): void => {
                      removeImage(index);
                    }}
                    size={'sm'}
                    type={'button'}
                    variant={'outline-secondary'}
                  >
                    {t('Remove')}
                  </Button>
                </div>
              )}
            </div>
          ))}
          {!showUploadImageForm && (
            <div
              className={'border rounded mt-3 d-flex justify-content-center align-items-center'}
              style={{ width: '150px', height: '150px' }}
            >
              <Button
                className={''}
                icon={IconCreate}
                onClick={(): void => setShowUploadImageForm(true)}
                size={'sm'}
                style={{ height: '2rem' }}
                variant={'outline-secondary'}
              >
                {t('productModal|addImageButtonLabel')}
              </Button>
            </div>
          )}
        </div>
        {showUploadImageForm && (
          <div className={'mb-3'}>
            <Form.Control name={'imageUrl'} onChange={handleFileUpload} type="file" />
            <Form.Text className="text-muted">{t('productModal|uploadProductImageText')}</Form.Text>
          </div>
        )}
        {busyFileUploading && <ImageUploading />}
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formProductClass">
        <Form.Label>
          {t('Product Class')} <Required />
        </Form.Label>
        <ProductClassSelect
          firstItemDefault={true}
          onChange={(value): void => setProductClassId(value)}
          onCloseAddModal={(): void => setShowAddProductClassModal(false)}
          required
          showAddModal={showAddProductClassModal}
          value={productClassId}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a product class from the dropdown or ')}</span>
          <Button
            onClick={(): void => setShowAddProductClassModal(true)}
            size={'sm'}
            variant={'link'}
          >
            {t('add product class')}
          </Button>
        </Form.Text>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formProductType">
        <Form.Label>
          {t('Product Type')} <Required />
        </Form.Label>
        <ProductTypeSelect
          firstItemDefault={true}
          onChange={(value): void => setProductTypeId(value)}
          onCloseAddModal={(): void => setShowAddProductTypeModal(false)}
          required
          showAddModal={showAddProductTypeModal}
          value={productTypeId}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a product type from the dropdown')}</span>
          <Button
            onClick={(): void => setShowAddProductTypeModal(true)}
            size={'sm'}
            variant={'link'}
          >
            {t('add product type')}
          </Button>
        </Form.Text>
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formUrl">
        <Form.Label>{t('Website URL')}</Form.Label>
        <Form.Control
          name={'websiteUrl'}
          onChange={(e): void => setWebsiteUrl(e.target.value)}
          placeholder={'https://www.example.com'}
          value={websiteUrl}
        />
        <Form.Text className={'text-muted'}>
          {t('productModal|productInformationWebsiteInstructions')}
        </Form.Text>
      </Form.Group>

      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete
            busy={mutation.isLoading || isDeleting}
            disabled={isDeleting || mutation.isLoading || query.isLoading}
            onClick={(): void => setDeleteConfirmModalVisible(true)}
          />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`${props.editId ? t('Modify') : t('common|add')} Product`}
      visible={true}
    >
      {query.isError && (
        <AlertErrorForModal
          message={t(`common|unexpectedRetrievalError`, {
            value: t('product.'),
          })}
        />
      )}
      {query.isFetching ? placeholder : form}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('product') })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('product') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: t('product') })}
      </ConfirmModal>
    </Modal>
  );
};

export default ProductModal;
