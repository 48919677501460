// Copyright © 2024 CATTLEytics Inc.

import { Formik } from 'formik';
import { ChangeEvent, PropsWithChildren, ReactElement, useState } from 'react';
import { Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaMagic } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';

import Button from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import { TaskCategorySelect } from '../../common/components/TaskCategorySelect';
import { useFileService } from '../../common/hooks/useFileService';
import { useFocusField } from '../../common/hooks/useFocusField';
import { useAuth } from '../../common/store/useAuth';
import { api } from '../../common/utilities';
import { ImageSize, resizeImage } from '../../common/utilities/imageResize';
import { useFeatureFlags } from '../../settings/hooks/useFeatureFlags';
import {
  ApiResourceV1,
  HttpMethod,
  ImageUrl,
  isNullOrWhitespace,
  QueryKey,
  TaskPriority,
  TaskTemplate,
} from '../../shared';
import { TaskPriorityPicker } from './TaskPriority';
import { UploadFileField } from './UploadFileField';
import { UrlPreview } from './UrlPreview';

function asImageUrls(input: string[]): ImageUrl[] {
  return input.map((url) => ({ key: url } as ImageUrl));
}

function asStringUrls(input: ImageUrl[]): string[] {
  return input.map((item) => (item.key as string) ?? '');
}

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * Callback on successful save. Note: onClose is NOT called when onSave is set
   * @param task
   */
  onSave?: (task: TaskTemplate) => void;

  /**
   * Pen ID to associate with this task
   */
  penId?: number;

  /**
   * ID of task to edit in this modal.
   */
  taskTemplate?: TaskTemplate;
}

/**
 * Note modal component for creating/editing notes.
 */
export const ProtocolModal = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const fileService = useFileService();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const featureFlags = useFeatureFlags();

  const [showAddTaskCategoryModal, setShowAddTaskCategoryModal] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean>(false);
  const [busyFileUploading, setBusyFileUploading] = useState<boolean>(false);
  const [showUploadImageForm, setShowUploadImageForm] = useState<boolean>(false);
  const [magicLoading, setMagicLoading] = useState<boolean>(false);

  const taskTemplateSchema = yup.object().shape({
    title: yup
      .string()
      .required(t('common|fieldRequiredFeedback'))
      .min(5, t('taskTemplateModal|titleLengthError')),
    description: yup.string().required(t('common|fieldRequiredFeedback')),
    instructions: yup.string().required(t('common|fieldRequiredFeedback')),
    priority: yup.string().required(t('common|fieldRequiredFeedback')),
    taskCategoryId: yup.number().min(0).required(t('common|fieldRequiredFeedback')),
    imageUrls: yup.array().optional().of(yup.string()),
    websiteUrl: yup.string().nullable().trim(),
    websiteAudioUrl: yup.string().nullable().trim(),
    websiteTestUrl: yup.string().nullable().trim(),
  });

  useFocusField({ validated });

  const { mutateAsync: saveTemplate, isLoading: isLoadingTemplate } = useMutation<
    TaskTemplate,
    unknown,
    Partial<TaskTemplate>
  >(
    (data: Partial<TaskTemplate>) => {
      const payload: Partial<TaskTemplate> = data;

      if (props.taskTemplate?.id) {
        return api(HttpMethod.Patch, `${ApiResourceV1.TaskTemplates}/${props.taskTemplate?.id}`, {
          body: payload,
        });
      } else {
        return api(HttpMethod.Post, `${ApiResourceV1.TaskTemplates}`, { body: payload });
      }
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.TaskTemplates);
      },
    },
  );

  const onFormSubmit = async (values: any): Promise<void> => {
    // mark the form as having its validity checked
    setValidated(true);

    try {
      const data: Partial<TaskTemplate> = {
        ...values,
      };
      const task = await saveTemplate(data);
      if (task) {
        props.onSave?.(task);
      }
      props.onClose();
    } catch (err) {
      console.error('Task could not be saved.', err);
    }
  };

  const removeImage = (values: any, handleChange: any, index: number): void => {
    const updatedImageUrls = [...values.imageUrls];
    delete updatedImageUrls[index];
    handleChange({ target: { value: Object.values(updatedImageUrls), name: 'imageUrls' } });
  };

  const handleFileUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    values: any,
    handleChange: any,
  ): Promise<void> => {
    const file = ((event.target as HTMLInputElement).files as FileList)[0];
    setBusyFileUploading(true);
    await uploadFile(await resizeImage(file, ImageSize.Large), values, handleChange);
    setBusyFileUploading(false);
  };

  const uploadFile = async (file: File, values: any, handleChange: any): Promise<void> => {
    const fileResult = await fileService.upload(file, undefined, auth.siteId);
    if (fileResult.signedUrl) {
      // const newImageUrls = [...values.imageUrls];
      // newImageUrls.push({ key: fileResult.key, signedUrl: fileResult.signedUrl });
      // handleChange({ target: { value: newImageUrls, name: 'imageUrls' } });
      // setShowUploadImageForm(false);
      const newImageUrls = values.imageUrls ? [...values.imageUrls] : [];
      const newSignedUrls = values.imageUrlsSigned ? [...values.imageUrlsSigned] : [];

      newImageUrls.push(fileResult.key);
      newSignedUrls.push({ key: fileResult.key, signedUrl: fileResult.signedUrl });

      handleChange({ target: { value: newImageUrls, name: 'imageUrls' } });

      handleChange({ target: { value: newSignedUrls, name: 'imageUrlsSigned' } });
      setShowUploadImageForm(false);
    }
  };

  const magicInstructions = async (
    values: Partial<TaskTemplate>,
    handleChange: any,
  ): Promise<void> => {
    setMagicLoading(true);
    const result = await api<{ analysis: string }, { description?: string; title?: string }>(
      HttpMethod.Post,
      '/v1/tasks/actions/aiInstructions',
      {
        body: { title: values.title, description: values.description },
      },
    ).catch((_reason: any) => {
      setMagicLoading(true);
    });

    setMagicLoading(false);

    handleChange({ target: { value: result?.analysis ?? '', name: 'instructions' } });
  };

  return (
    <Modal
      onClose={props.onClose}
      size={'xl'}
      title={`${props.taskTemplate?.id ? 'Edit' : 'Create'} ${t('taskTemplateModal|title')}`}
      visible={true}
    >
      <Formik
        initialValues={{
          title: props.taskTemplate?.title ?? '',
          description: props.taskTemplate?.description ?? '',
          instructions: props.taskTemplate?.instructions ?? '',
          taskCategoryId: props.taskTemplate?.taskCategoryId ?? -1,
          priority: props.taskTemplate?.priority ?? TaskPriority.Medium,
          imageUrls:
            // (props.taskTemplate?.imageUrls && !props.taskTemplate?.imageUrlsSigned
            //   ? props.taskTemplate?.imageUrls.map((url) => ({ key: url }))
            //   : props.taskTemplate?.imageUrlsSigned) ?? [],
            props.taskTemplate?.imageUrls && !props.taskTemplate?.imageUrlsSigned
              ? props.taskTemplate?.imageUrls
              : asStringUrls(props.taskTemplate?.imageUrlsSigned ?? []) ?? ([] as string[]),
          websiteUrl: props.taskTemplate?.websiteUrl ?? '',
          websiteAudioUrl: props.taskTemplate?.websiteAudioUrl ?? '',
          websiteTestUrl: props.taskTemplate?.websiteTestUrl ?? '',
        }}
        key={`${props.taskTemplate?.id}`}
        onSubmit={onFormSubmit}
        validationSchema={taskTemplateSchema}
      >
        {({ handleSubmit, handleChange, values, errors, touched }): ReactElement<any, any> => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <FormGroup className="form-group mb-3" controlId="formTitle">
                  <Form.Label>
                    {t('Title')} <Required />
                  </Form.Label>
                  <Form.Control
                    autoFocus
                    isInvalid={touched.title && !!errors.title}
                    isValid={touched.title && !errors.title}
                    name="title"
                    onChange={handleChange}
                    required
                    value={values.title}
                  />
                  <Form.Text className={'text-muted'}>
                    {t('taskTemplateModal|taskDescriptionInstructions')}
                  </Form.Text>
                  <Form.Control.Feedback type={'invalid'}>{errors.title}</Form.Control.Feedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="form-group mb-3" controlId="formTitle">
                  <Form.Label>
                    {t('Description')} <Required />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={touched.description && !!errors.description}
                    isValid={touched.description && !errors.description}
                    name="description"
                    onChange={handleChange}
                    required
                    rows={3}
                    value={values.description}
                  />
                  <Form.Text className={'text-muted'}>
                    {t('taskTemplateModal|taskDescriptionInstructions')}
                  </Form.Text>
                  <Form.Control.Feedback type={'invalid'}>
                    {errors.description}
                  </Form.Control.Feedback>
                </FormGroup>

                <FormGroup className="form-group mb-3" controlId="formTitle">
                  <Form.Label>
                    {t('Instructions')} <Required />{' '}
                    {featureFlags['Tasks/Magic'] === 'True' && (
                      <Button
                        disabled={
                          isNullOrWhitespace(values.description) && isNullOrWhitespace(values.title)
                        }
                        icon={FaMagic}
                        onClick={async (): Promise<void> =>
                          await magicInstructions(values, handleChange)
                        }
                        size="sm"
                      >
                        {t('Magic')} {magicLoading && <Spinner size="sm" />}
                      </Button>
                    )}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={touched.instructions && !!errors.instructions}
                    isValid={touched.instructions && !errors.instructions}
                    name="instructions"
                    onChange={handleChange}
                    required
                    rows={6}
                    value={values.instructions}
                  />
                  <Form.Text className={'text-muted'}>
                    {t('taskTemplateModal|taskInstructionsInstructions')}
                  </Form.Text>
                  <Form.Control.Feedback type={'invalid'}>
                    {errors.instructions}
                  </Form.Control.Feedback>
                </FormGroup>
                <Form.Group className="form-group mb-3" controlId="formPriority">
                  <Form.Label>{t('taskTemplateModal|priorityFieldLabel')}</Form.Label>

                  <TaskPriorityPicker
                    errors={errors.priority}
                    isInvalid={touched.priority && !!errors.priority}
                    isValid={touched.priority && !errors.priority}
                    onPriorityChanged={(priority: TaskPriority): void => {
                      handleChange({ target: { name: 'priority', value: priority } });
                    }}
                    priority={values.priority}
                  />
                  {/* <Form.Control.Feedback type="valid">Good</Form.Control.Feedback>
                  <Form.Control.Feedback type={'invalid'}>{errors.priority}</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group mb-3" controlId="formTaskCategory">
                  <Form.Label>{t('taskTemplateModal|categoryFieldLabel')}</Form.Label>
                  <TaskCategorySelect
                    isInvalid={touched.taskCategoryId && !!errors.taskCategoryId}
                    isValid={touched.taskCategoryId && !errors.taskCategoryId}
                    name={'taskCategoryId'}
                    onChange={handleChange}
                    onCloseAddModal={(): void => setShowAddTaskCategoryModal(false)}
                    showAddModal={showAddTaskCategoryModal}
                    value={values.taskCategoryId}
                  />

                  <Form.Text className="text-muted">
                    <span>{t('taskTemplateModal|categoryFieldHint')}</span>
                    <Button
                      onClick={(): void => setShowAddTaskCategoryModal(true)}
                      size={'sm'}
                      variant={'link'}
                    >
                      {t('taskTemplateModal|categoryFieldAddButton')}
                    </Button>
                  </Form.Text>
                </Form.Group>

                <FormGroup className="form-group mb-3" controlId="formWebsite">
                  <Form.Label>{t('taskTemplateModal|taskWebsiteAudioUrlLabel')}</Form.Label>
                  <Form.Control
                    isInvalid={touched.websiteAudioUrl && !!errors.websiteAudioUrl}
                    isValid={touched.websiteAudioUrl && !errors.websiteAudioUrl}
                    name="websiteAudioUrl"
                    onChange={handleChange}
                    value={values.websiteAudioUrl}
                  />
                  <Form.Text className={'text-muted'}>
                    {t('taskTemplateModal|taskWebsiteAudioUrlInstructions')}
                  </Form.Text>
                  <Form.Control.Feedback type={'invalid'}>
                    {errors.websiteAudioUrl}
                  </Form.Control.Feedback>
                  <UrlPreview uri={values.websiteAudioUrl} />
                </FormGroup>

                <FormGroup className="form-group mb-3" controlId="formWebsite">
                  <Form.Label>{t('taskTemplateModal|taskWebsiteTestUrlLabel')}</Form.Label>
                  <Form.Control
                    isInvalid={touched.websiteTestUrl && !!errors.websiteTestUrl}
                    isValid={touched.websiteTestUrl && !errors.websiteTestUrl}
                    name="websiteTestUrl"
                    onChange={handleChange}
                    value={values.websiteTestUrl}
                  />
                  <Form.Text className={'text-muted'}>
                    {t('taskTemplateModal|taskWebsiteTestUrlInstructions')}
                  </Form.Text>
                  <Form.Control.Feedback type={'invalid'}>
                    {errors.websiteTestUrl}
                  </Form.Control.Feedback>
                  <UrlPreview uri={values.websiteTestUrl} />
                </FormGroup>

                <FormGroup className="form-group mb-3" controlId="formWebsite">
                  <Form.Label>{t('taskTemplateModal|taskWebsiteUrlLabel')}</Form.Label>
                  <Form.Control
                    isInvalid={touched.websiteUrl && !!errors.websiteUrl}
                    isValid={touched.websiteUrl && !errors.websiteUrl}
                    name={'websiteUrl'}
                    onChange={handleChange}
                    value={values.websiteUrl}
                  />
                  <Form.Text className={'text-muted'}>
                    {t('taskTemplateModal|taskWebsiteUrlInstructions')}
                  </Form.Text>
                  <Form.Control.Feedback type={'invalid'}>
                    {errors.websiteUrl}
                  </Form.Control.Feedback>

                  <UrlPreview uri={values.websiteUrl} />
                </FormGroup>
                <Form.Group className="form-group mb-1" controlId="formImages">
                  <Form.Label>{t('taskTemplateModal|imageHeaderLabel')}</Form.Label>

                  <UploadFileField
                    addLabel={t('taskTemplateModal|addImageButtonLabel')}
                    busy={busyFileUploading}
                    handleFileUpload={(ev): Promise<void> =>
                      handleFileUpload(ev, values, handleChange)
                    }
                    imageLabel={t('taskTemplateModal|imageLabel')}
                    imageUrls={asImageUrls(values.imageUrls ?? [])}
                    onShow={(): void => setShowUploadImageForm(true)}
                    removeImage={(index): void => removeImage(values, handleChange, index)}
                    removeLabel={t('taskTemplateModal|remove')}
                    show={showUploadImageForm}
                    uploadLabel={t('taskTemplateModal|uploadTaskImageText')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="modal-footer modal-footer-in-form">
              <ButtonModalCancel disabled={isLoadingTemplate} onClick={props.onClose} />
              <ButtonModalSave busy={isLoadingTemplate} disabled={isLoadingTemplate} />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
