// Copyright © 2025 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

export function isBoolean(value: string): boolean {
  return value.toLowerCase() === 'true' || value.toLowerCase() === 'false';
}

export function isTruthy(value: string): boolean {
  if (value === undefined || value === null) {
    return false;
  }
  return value.toLowerCase() === 'true';
}
