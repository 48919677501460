// Copyright © 2023 CATTLEytics Inc.

import { useState } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import { useAuth } from '../../common/store/useAuth';
import { IconInfo } from '../../common/utilities';
import UserModal from './UserModal';
import UsersTable from './UsersTable';

interface RouteParams {
  /**
   * Tab key to make active on render.
   */
  tabKey: string;
}

/**
 * Routable component to list and manage users.
 */
const UsersPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();

  const auth = useAuth();

  const [usersModalVisible, setUsersModalVisible] = useState<boolean>(false);

  const filters: Record<string, string> = {};
  filters[tabKey] = '1';
  filters.order = '-userDateTime';

  return (
    <Page
      buttons={<ButtonCreate label={'New User'} onClick={(): void => setUsersModalVisible(true)} />}
      title={t('Users')}
    >
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="users-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/users/${k}`);
          }
        }}
      >
        {auth.isSuperAdmin && (
          <Tab eventKey="all-sites" title="All sites">
            <Alert variant={'info'}>
              <IconInfo className={'me-2'} />
              You are a super administrator and can see all users across all sites.
            </Alert>
            <UsersTable filters={{ allSites: '1', order: '-userDateTime' }} />
          </Tab>
        )}
        {auth.isSuperAdmin && (
          <Tab eventKey="all" title="This site">
            <UsersTable filters={{ order: '-userDateTime' }} />
          </Tab>
        )}
        {!auth.isSuperAdmin && (
          <Tab eventKey="all" title="All">
            <UsersTable filters={{ order: '-userDateTime' }} />
          </Tab>
        )}
        <Tab eventKey="technicians" title="Technicians">
          <UsersTable filters={filters} />
        </Tab>
      </Tabs>
      {usersModalVisible && <UserModal onClose={(): void => setUsersModalVisible(false)} />}
    </Page>
  );
};

export default UsersPage;
