// Copyright © 2023 CATTLEytics Inc.

import React, { useCallback, useEffect, useState } from 'react';
import { ButtonGroup, Stack, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../common/components/Button';
import ButtonCreate from '../common/components/ButtonCreate';
import Page from '../common/components/Page';
import { useAuth } from '../common/store/useAuth';
import { api, IconSuperAdmin } from '../common/utilities';
import { isSiteAdminOrAbove, isSuperAdmin } from '../common/utilities';
import { ApiResourceV1, HttpMethod, isNullOrWhitespace } from '../shared';
import { JobModal } from './JobModal';
import JobsTable from './JobsTable';

interface RouteParams {
  // Tab key to make active on render.
  tabKey: string;
}

interface Props {
  className?: string;
}

/**
 * Routable component to perform some admin actions
 */
const AdminPage = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const auth = useAuth();
  const queryClient = useQueryClient();

  const [filters, setFilters] = useState<Record<string, string> | undefined>();

  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();
  const [jobModalVisible, setJobModalVisible] = useState<boolean>(false);

  useEffect(() => {
    // this site - job's matching the current session's site id
    // global - job's with no siteId
    // all sites - job's with a siteId

    const siteId = auth.siteId ? auth.siteId : 'null';
    if (isSiteAdminOrAbove(auth) && (isNullOrWhitespace(tabKey) || tabKey === 'this-site')) {
      setFilters({ siteId: siteId.toString() });
    } else if (isSuperAdmin(auth) && tabKey === 'global') {
      setFilters({ hideSiteJobs: '1' });
    } else {
      setFilters({ hideGlobalJobs: '1' });
    }
  }, [auth, tabKey]);

  const queueStatus = useQuery(
    'queueState',
    async () => {
      return api<{ isRunning: boolean }>(HttpMethod.Get, `${ApiResourceV1.Jobs}/queueStatus`);
    },
    { refetchInterval: 5_000 },
  );

  const toggleQueueMutation = useMutation(
    () => {
      return api(HttpMethod.Get, `${ApiResourceV1.Jobs}/toggleQueue`);
    },
    {
      onSuccess: async () => {
        // Invalidate to trigger re-fetch
        await queryClient.invalidateQueries('queueState');
      },
    },
  );

  const onQueueStart = useCallback(async () => {
    await toggleQueueMutation.mutateAsync();
  }, [toggleQueueMutation]);

  const onQueueStop = useCallback(async () => {
    await toggleQueueMutation.mutateAsync();
  }, [toggleQueueMutation]);

  return (
    <Page
      buttons={
        <Stack direction="horizontal">
          <ButtonGroup className="me-2" size="lg">
            <Button
              disabled={queueStatus.data?.isRunning}
              onClick={(): void => {
                onQueueStart();
              }}
              variant="primary"
            >
              {queueStatus.data?.isRunning ? 'Running...' : 'Start'}
            </Button>
            <Button
              disabled={queueStatus.data?.isRunning === false}
              onClick={(): void => {
                onQueueStop();
              }}
              variant="outline-primary"
            >
              Stop
            </Button>
          </ButtonGroup>
          <ButtonCreate
            label={t('adminPage|createButtonLabel')}
            onClick={(): void => setJobModalVisible(true)}
          />
        </Stack>
      }
      className={props.className}
      title={t('adminPage|title')}
    >
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="events-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/system/${k}`);
          }
        }}
      >
        {isSiteAdminOrAbove(auth) && (
          <Tab eventKey="this-site" title={t('adminPage|thisSiteTab')} />
        )}
        {isSuperAdmin(auth) && (
          <Tab
            eventKey="global"
            title={
              <>
                <IconSuperAdmin />
                {t('adminPage|globalTab')}
              </>
            }
          />
        )}
        {isSuperAdmin(auth) && (
          <Tab
            eventKey="all-sites"
            title={
              <>
                <IconSuperAdmin />
                {t('adminPage|allSitesTab')}
              </>
            }
          />
        )}
      </Tabs>
      <JobsTable filters={filters} />
      {jobModalVisible ? <JobModal onClose={(): void => setJobModalVisible(false)} /> : null}
    </Page>
  );
};

export default AdminPage;
