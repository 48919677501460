// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormGroup, Placeholder, PlaceholderButton, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button, { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import DeleteConfirmModal from '../../common/components/DeleteConfirmModal';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import SiteSelect from '../../common/components/SiteSelect';
import { useDeleteConfirmModal } from '../../common/hooks';
import { useFocusField } from '../../common/hooks/useFocusField';
import UserService from '../../common/services/userService';
import { IconDelete } from '../../common/utilities';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared';
import { User } from '../../shared';

/**
 * Component's input properties.
 */
interface Props {
  // Additional class names to pass to the component.
  className?: string;

  // Callback when modal is closed.
  onClose: () => void;

  /**
   * The user identifier of the user we will assign to a site
   */
  userId: number;
}

/**
 * Modal to add/edit an animal user.
 */
const UserAttachToModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const userService = useInjection<UserService>(TYPES.userService);
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);
  const [siteId, setSiteId] = useState<number>();
  const [user, setUser] = useState<User>();
  const {
    deleteConfirmModalOpen,
    deleteConfirmModalErrorMessage,
    setDeleteConfirmModalErrorMessage,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
  } = useDeleteConfirmModal();

  const query = useQuery<User | undefined>(
    [QueryKey.Users, props.userId],
    () => userService.get(props.userId ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.userId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const onDeleteClick = useCallback(
    (siteId): void => {
      setSiteId(siteId);
      openDeleteConfirmModal();
    },
    [openDeleteConfirmModal, setSiteId],
  );

  // Invalidate to trigger re-fetch
  const invalidateQuery = useCallback(
    async () => queryClient.invalidateQueries(QueryKey.Users),
    [queryClient],
  );

  useEffect(() => {
    if (query.data) {
      setUser(query.data);
    }
  }, [query.data]);

  const mutation = useMutation(
    () => {
      if (!siteId) {
        throw Error('no data to save');
      }

      return userService.attachToSite(props.userId, { siteId: siteId });
    },
    {
      onSuccess: invalidateQuery,
    },
  );

  const deleteSiteMutation = useMutation(
    () => {
      if (!siteId) {
        throw Error('no data to delete');
      }
      return userService.deleteSite(props.userId, siteId);
    },
    { onSuccess: invalidateQuery },
  );

  const deleteItem = useCallback(async () => {
    try {
      await deleteSiteMutation.mutateAsync();
      closeDeleteConfirmModal();
    } catch (err) {
      logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage(t('common|itemCannotDeleteError'));
    }
  }, [deleteSiteMutation, logger, t, closeDeleteConfirmModal, setDeleteConfirmModalErrorMessage]);

  useFocusField({ validated });

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      logger.error(`Site could not be attached to user.`, err, user, siteId);
      setErrorMessage(t(`Site could not be attached to user.`));
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formSite">
        <Form.Label>
          {t('Choose a site')} <Required />
        </Form.Label>
        <SiteSelect
          onChange={(e): void => setSiteId(Number(e.target.value))}
          //required={true}
          value={String(siteId)}
        />
        <Form.Text className={'text-muted'}>The site to assign to this user.</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      {user && (
        <div>
          <strong>
            {user.firstName} {user.lastName} is currently assigned to:
          </strong>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {user.sites &&
                user.sites.map((site) => (
                  <tr>
                    <td className="align-middle">{site.id}</td>
                    <td className="align-middle">{site.name}</td>
                    <td className="text-end align-middle">
                      <Button
                        className="w-auto-sm"
                        disabled={
                          deleteConfirmModalOpen || deleteSiteMutation.isLoading || query.isLoading
                        }
                        icon={IconDelete}
                        label={t('Remove')}
                        onClick={(): void => onDeleteClick(site.id)}
                        variant={ButtonVariant.Danger}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}

      <AlertErrorForModal message={errorMessage} />

      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={t('Attach site to user')}
      visible={true}
    >
      {query.isError && <AlertError message={t('userAttachToSiteModal|userRetrievalError')} />}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          <PlaceholderButton xs={2} />
        </div>
      )}
      <DeleteConfirmModal
        busy={deleteSiteMutation.isLoading}
        cancelOnClick={(): void => closeDeleteConfirmModal()}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t('Yes, remove this site')}
        okOnClick={deleteItem}
        title={t('Remove this site from user')}
        value={t('site')}
        visible={deleteConfirmModalOpen}
      >
        {t('Are you sure you want to remove this site for this user?')}
      </DeleteConfirmModal>
    </Modal>
  );
};

export default UserAttachToModal;
