// Copyright © 2025 CATTLEytics Inc.

import React from 'react';
import { Spinner, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import Button from '../../common/components/Button';
import Page from '../../common/components/Page';
import { useAuth } from '../../common/store/useAuth';
import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, ReportEmbedded } from '../../shared';

interface Props {
  className?: string;
}

interface RouteParams {
  /**
   * The URL encoded query parameter we will use to generate a custom report
   */
  reportGroup: string;
  reportName: string;
}

/**
 * Routable component to display embedded reports.
 */
export const ReportsEmbeddedPage = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const { reportGroup, reportName } = useParams<RouteParams>();
  const queryClient = useQueryClient();
  const auth = useAuth();

  // instead of hardcoding, query the reports/embedded controller

  const query = useQuery(
    [QueryKey.Reports, 'embedded', reportGroup, reportName],
    () =>
      api<ReportEmbedded>(HttpMethod.Get, `${ApiResourceV1.ReportsEmbedded}`, {
        params: {
          reportGroup,
          reportName,
        },
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  const retryQuery = (): void => {
    queryClient.invalidateQueries([QueryKey.Reports, 'embedded']);
  };

  // send theme to hex report:
  const bsTheme = document.documentElement.dataset.bsTheme;

  return (
    <Page
      breadcrumbTitle={query.data?.name ?? reportName}
      breadcrumbs={[
        { label: 'Reports', to: '/' },
        { label: reportGroup, to: '/' },
      ]}
      className={props.className}
      title={query.data?.name ?? reportName}
    >
      {query.isLoading && <Spinner />}
      {!query.isLoading && query.data?.type === undefined && (
        <Stack>
          {t('reportsEmbeddedPage|noResults')}
          <Stack direction="horizontal">
            <Button
              label={t('reportsEmbeddedPage|retry')}
              onClick={(): void => {
                retryQuery();
              }}
            ></Button>
          </Stack>
        </Stack>
      )}
      {query.data?.type === 'hex' && (
        <>
          <link href="https://static.hex.site/embed/embedStyles.css" rel="stylesheet" />
          <div className="hex-embed w-auto vh-100">
            <iframe
              className="w-auto border-0 h-100"
              height={400}
              {...query.data?.iframeProps}
              src={`${query.data?.url}&theme=${bsTheme}&_site=${auth.siteId}`}
              title="report"
            />
          </div>
        </>
      )}
    </Page>
  );
};
