// Copyright © 2023 CATTLEytics Inc.

import convert, { Unit } from 'convert-units';
import { Formik } from 'formik';
import { useInjection } from 'inversify-react';
import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Placeholder,
  PlaceholderButton,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import AnimalColorSelect from '../../common/components/AnimalColorSelect';
import AnimalReferenceTypeSelect from '../../common/components/AnimalReferenceTypeSelect';
import BreedSelect from '../../common/components/BreedSelect';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import GenderSelect from '../../common/components/GenderSelect';
import ImageUploaded from '../../common/components/ImageUploaded';
import Modal from '../../common/components/Modal';
import NoImageUploaded from '../../common/components/NoImageUploaded';
import PenSelect from '../../common/components/PenSelect';
import RandomName from '../../common/components/RandomName';
import Required from '../../common/components/Required';
import Spinner from '../../common/components/Spinner';
import { useNextPrimaryTag } from '../../common/hooks';
import { useFileService } from '../../common/hooks/useFileService';
import { useFocusField } from '../../common/hooks/useFocusField';
import SettingsContext from '../../common/store/settings-context';
import { api, placeholderExample } from '../../common/utilities';
import { ImageSize, resizeImage } from '../../common/utilities/imageResize';
import Logger from '../../logger/logger';
import { Animal, ApiResourceV1, QueryKey } from '../../shared';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * The primary id of an animal we want to edit.
   */
  editId?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

interface Payload extends PayloadAnimal, PayloadEvents {
  animalReferences: PayloadAnimalReference[]; // reference
}

interface PayloadEvents {
  [key: string]: any | undefined;
  // animal
  dim: number;
  // event
  hipHeight: number;
  id?: string; // event
  lactationNumber: number; // event
  reproStatus: string; // event
  temperature: number; // event
  weight: number; // event
}

interface PayloadAnimal {
  [key: string]: any | undefined;
  // animal
  animalColorId: number;
  animalReferences: PayloadAnimalReference[];
  // reference
  birthDate: string;
  // animal
  breedId: number;
  // animal
  currentPenId: number; // animal
  genderId: number;
  id?: string;
  imageUrl: string; // animal
  imageUrlSigned: string; // animal
  lot: string; // animal
}

interface PayloadAnimalReference {
  [key: string]: any | undefined;
  animalReferenceTypeId: string;
  id?: string;
  reference: string;
}

/**
 * Animal modal component for adding animal on arrival.
 */
const AnimalModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const fileService = useFileService();

  const history = useHistory();

  const [showAddAnimalColorModal, setShowAddAnimalColorModal] = useState<boolean>(false);
  const [showAddAnimalReferenceTypeModal, setShowAddAnimalReferenceTypeModal] =
    useState<boolean>(false);
  const [showAddBreedModal, setShowAddBreedModal] = useState<boolean>(false);
  const [showAddGenderModal, setShowAddGenderModal] = useState<boolean>(false);
  const [showAddPenModal, setShowAddPenModal] = useState<boolean>(false);

  const [busyFileUploading, setBusyFileUploading] = useState<boolean>(false);

  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageUrlSigned, setImageUrlSigned] = useState<string>('');

  const [hipHeight, setHipHeight] = useState<string>('');
  const [temperature, setTemperature] = useState<string>('');
  const [weight, setWeight] = useState<string>('');

  const [animalReferencesData, setAnimalReferencesData] = useState<PayloadAnimalReference[]>([]);

  const settings = useContext(SettingsContext);

  const [units, setUnits] = useState<Record<string, string>>({
    temperatureUnit: settings?.temperatureUnit,
    weightUnit: settings?.weightUnit,
    hipHeightUnit: settings?.heightUnit,
  });

  // const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { nextPrimaryTag } = useNextPrimaryTag({
    queryEnabled: !props.editId,
  });
  const [initialValues, setInitialValues] = useState({
    name: '',
    primaryTag: '',
    lot: null,
    breedId: null,
    genderId: undefined,
    animalColorId: null,
    currentPenId: undefined,
    temperature: null,
    hipHeight: null,
    weight: null,
    lactationNumber: null,
    dim: null,
    reproStatus: null,
    birthDate: null,
  });

  useEffect(() => {
    if (!props.editId) {
      setInitialValues((prevValues) => ({ ...prevValues, primaryTag: nextPrimaryTag }));
    }
  }, [props.editId, nextPrimaryTag]);

  const animalSchema = yup.object().shape({
    primaryTag: yup
      .string()
      .required(t('common|fieldRequiredFeedback'))
      .test('Unique Primary Tag', t('animalModal|primaryTagExistsError'), (value) => {
        return new Promise((resolve, reject) => {
          // https://stackoverflow.com/questions/60839218/how-to-do-i-do-server-side-validation-using-formik-and-yup

          // if editId is populated, only check server if `value` is different than initialValue
          if (props.editId && value === initialValues.primaryTag) {
            // primaryTag has not been edited
            return resolve(true);
          }

          api<{ unique: boolean }, unknown>(
            'GET',
            `${ApiResourceV1.AnimalReferences}/unique-primary-tag/${value}`,
          ).then(
            (x) => {
              return resolve(x.unique === true);
            },
            () => reject(false),
          );
        });
      }),
    name: yup.string().nullable(),
    lot: yup.string().nullable(),
    genderId: yup.number().required(t('common|fieldRequiredFeedback')),
    breedId: yup.number().nullable(),
    animalColorId: yup.number().nullable(),
    currentPenId: yup.number().required(t('common|fieldRequiredFeedback')),
    temperature: yup
      .number()
      .label(t('animalModal|temperatureLabel'))
      .moreThan(0)
      .lessThan(1000)
      .nullable(),
    hipHeight: yup
      .number()
      .label(t('animalModal|hipHeightLabel'))
      .nullable()
      .moreThan(0)
      .lessThan(1000),
    weight: yup.number().label(t('animalModal|weightLabel')).moreThan(0).lessThan(1000).nullable(),
    lactationNumber: yup
      .number()
      .label(t('animalModal|lactationNumberLabel'))
      .moreThan(-1)
      .lessThan(1000)
      .nullable(),
    dim: yup
      .number()
      .label(t('animalModal|daysInMilkLabel'))
      .moreThan(0)
      .lessThan(10000)
      .nullable(),
    reproStatus: yup.string().nullable(),
    birthDate: yup.date().nullable(),
  });

  const query = useQuery(
    [QueryKey.Animals, props.editId],
    () => api<Animal>('GET', `${ApiResourceV1.Animals}/${props.editId ?? -1}`),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      onSuccess: (data) => {
        // populate animal data state with animal data from API
        if (data) {
          Object.keys(data).forEach((key) => (data[key] = data[key] ?? ''));
          if (data.imageUrl) {
            setImageUrl(data.imageUrl);
          }
          if (data.imageUrlSigned) {
            setImageUrlSigned(data.imageUrlSigned);
          }
          setInitialValues(data as any);
        }
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const mutation = useMutation<Animal, unknown, Payload>(
    async (data) => {
      // if (data.temperature && units.temperatureUnit !== 'C') {
      //   data.temperature = convert(parseFloat(data.temperature)).from('F').to('C').toFixed(2);
      // }

      if (props.editId) {
        return api('PATCH', `${ApiResourceV1.Animals}/${props.editId ?? -1}`, {
          body: data,
        });
      } else {
        try {
          return await api<Animal, unknown>('POST', ApiResourceV1.Animals, {
            body: data,
          });
        } catch (err) {
          // it should load the err stuff into the form's error.
          console.debug(`error happened`, err);
          throw err;
        }
      }
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Animals);
        await queryClient.invalidateQueries(QueryKey.AnimalReferences);
      },
    },
  );

  // when the form is validated scroll to the invalid elements
  useFocusField({ validated: true });

  const queryClient = useQueryClient();

  const onReferenceChange = (index: number, name: string, value: string): void => {
    const refs = [...animalReferencesData];
    refs[index][name] = value;
    setAnimalReferencesData(Object.values(refs));
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = ((event.target as HTMLInputElement).files as FileList)[0];
    setBusyFileUploading(true);
    await uploadFile(await resizeImage(file, ImageSize.Small));
    setBusyFileUploading(false);
  };

  const uploadFile = async (file: File): Promise<void> => {
    const fileResult = await fileService.upload(file);
    if (fileResult.signedUrl) {
      setImageUrl(fileResult.key);
      setImageUrlSigned(fileResult.signedUrl);
    }
  };

  const onFormSubmit = async (values: any): Promise<void> => {
    // clear previous errors
    setErrorMessage('');

    try {
      const refs = [...animalReferencesData];
      const data: Partial<Payload> = {
        ...values,
        imageUrl,
        animalReferences: refs,
      };
      const animal = await mutation.mutateAsync(data as Payload);

      props.onClose();
      if (animal) {
        history.push(`/animals/${animal.id}`);
      }
    } catch (err: any) {
      logger.error('Animal could not be saved.', err);
      setErrorMessage(
        `${t('animalModal|animalSaveError')} ${t(err.message.replace('Error: ', ''))}`,
      );
    }
  };

  const onUnitChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const newUnit = { ...units };
    const fromUnit = units[event.target.name];
    const toUnit = event.target.value;
    newUnit[event.target.name] = toUnit;
    setUnits(newUnit);

    const dataKey = event.target.name.replace('Unit', '');

    switch (dataKey) {
      case 'hipHeight':
        setHipHeight(
          convert(parseFloat(hipHeight))
            .from(fromUnit as Unit)
            .to(toUnit as Unit)
            .toFixed(2),
        );
        break;
      case 'temperature':
        setTemperature(
          convert(parseFloat(temperature))
            .from(fromUnit as Unit)
            .to(toUnit as Unit)
            .toFixed(2),
        );
        break;
      case 'weight':
        setWeight(
          convert(parseFloat(weight))
            .from(fromUnit as Unit)
            .to(toUnit as Unit)
            .toFixed(2),
        );
        break;
    }
  };

  const addIdentifier = (): void => {
    const newAnimalReferencesData = [...animalReferencesData];
    newAnimalReferencesData.push({
      animalReferenceTypeId: '',
      reference: '',
    });
    setAnimalReferencesData(newAnimalReferencesData);
  };

  const removeIdentifier = (indexToRemove: number): void => {
    const arData = [...animalReferencesData];
    if (props.editId) {
      throw new Error('Cannot remove persisted references');
    }
    delete arData[indexToRemove];
    setAnimalReferencesData(Object.values(arData));
  };

  const placeholder = (
    <Form>
      <Placeholder animation={'glow'}>
        <Placeholder xs={6} />
        <Placeholder className={'mb-5'} size={'lg'} xs={12} />
        <Placeholder xs={6} />
        <Placeholder className={'mb-5'} size={'lg'} xs={12} />
        <Placeholder xs={6} />
        <Placeholder className={'mb-5'} size={'lg'} xs={12} />
        <Placeholder xs={6} />
        <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      </Placeholder>
      <div className="modal-footer modal-footer-in-form">
        <PlaceholderButton variant="secondary" xs={2} />
        <PlaceholderButton xs={2} />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'xl'}
      title={`${props.editId ? 'Edit' : 'Add'} Animal`}
      visible={true}
    >
      {query.isLoading ? (
        placeholder
      ) : (
        <Formik
          initialValues={initialValues}
          key={`${props.editId} + ${initialValues.primaryTag}`}
          onSubmit={onFormSubmit}
          validationSchema={animalSchema}
        >
          {({ handleSubmit, handleChange, values, errors, touched }): ReactElement<any, any> => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="form-group mb-3 primaryTag" controlId="primaryTag">
                    <Form.Label>
                      {t('animalModal|primaryTagLabel')} <Required />
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.primaryTag && touched.primaryTag}
                      maxLength={50}
                      name={'primaryTag'}
                      onChange={handleChange}
                      placeholder={t('Example: {{value}}', { value: 123 })}
                      type="text"
                      value={values.primaryTag}
                    />
                    <Form.Control.Feedback type={'invalid'}>
                      {errors.primaryTag}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      {t('animalModal|animalPrimaryTagInstructions')}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <RandomName
                    className={'mb-3'}
                    gender={values.genderId ?? ''}
                    name={'name'}
                    onChange={handleChange}
                    onRandomName={(name): void => {
                      handleChange({ target: { name: 'name', value: name } });
                    }}
                    value={values.name}
                  >
                    <Form.Control.Feedback type={'invalid'}>{errors.name}</Form.Control.Feedback>
                  </RandomName>
                </Col>
              </Row>
              {animalReferencesData.map((ref, index) => (
                <Row key={index}>
                  <Col md={4}>
                    <Form.Group className="form-group mb-3" controlId="formAnimalReference">
                      <Form.Label>
                        {t('Reference')} {index + 1} <Required />
                      </Form.Label>
                      <Form.Control
                        maxLength={50}
                        onChange={(e): void =>
                          onReferenceChange(index, 'reference', e.target.value)
                        }
                        placeholder={t('Example: {{value}}', { value: 123 })}
                        required
                        type="text"
                        value={ref.reference}
                      />
                      <Form.Control.Feedback type={'invalid'}>
                        {t('common|fieldRequiredFeedback')}
                      </Form.Control.Feedback>
                      <Form.Text className="text-muted">
                        {t('animalModal|animalTagInstructions')}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="form-group mb-3" controlId="formAnimalReference">
                      <Form.Label>
                        {t('Type')} <Required />
                      </Form.Label>
                      <AnimalReferenceTypeSelect
                        hideNameTag={true}
                        name={'animalReferenceTypeId'}
                        onChange={(e): void =>
                          onReferenceChange(index, 'animalReferenceTypeId', e.target.value)
                        }
                        onCloseAddModal={(): void => setShowAddAnimalReferenceTypeModal(false)}
                        required
                        showAddModal={showAddAnimalReferenceTypeModal}
                        value={ref.animalReferenceTypeId}
                      />
                      <Form.Text className="text-muted">
                        <span>{t('Choose a reference type from the dropdown or')}</span>
                        <Button
                          onClick={(): void => setShowAddAnimalReferenceTypeModal(true)}
                          size={'sm'}
                          variant={'link'}
                        >
                          {t('add reference')}
                        </Button>
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col className={'d-flex align-items-center'} md={4}>
                    <Button
                      disabled={!!props.editId}
                      onClick={(): void => removeIdentifier(index)}
                      size={'sm'}
                      variant="outline-secondary"
                    >
                      {t('Remove Reference')}
                    </Button>
                  </Col>
                </Row>
              ))}

              {!props.editId && (
                <InputGroup className="mb-3">
                  <Button onClick={addIdentifier} size={'sm'} variant={'outline-secondary'}>
                    {t('animalModal|addIdentifierButtonLabel')}
                  </Button>
                </InputGroup>
              )}

              <Row>
                <Col md={8}>
                  <Form.Group className="form-group mb-3" controlId="formPhoto">
                    <Form.Label>{t('Photo')}</Form.Label>
                    <Form.Control name={'imageUrl'} onChange={handleFileUpload} type="file" />
                    <Form.Text className="text-muted">
                      {t('animalModal|uploadAnimalPhotoText')}
                    </Form.Text>
                    {imageUrlSigned ? (
                      <p className={'mt-3'}>
                        <Button
                          onClick={(): void => {
                            setImageUrl('');
                            setImageUrlSigned('');
                          }}
                          size={'sm'}
                          variant={'outline-secondary'}
                        >
                          {t('Remove Image')}
                        </Button>
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  {!busyFileUploading && imageUrlSigned ? (
                    <ImageUploaded alt={t('Animal image')} src={imageUrlSigned} />
                  ) : null}
                  {!busyFileUploading && !imageUrlSigned ? <NoImageUploaded /> : null}
                  {busyFileUploading ? (
                    <div
                      className={
                        'text-muted border rounded d-flex justify-content-center align-items-center'
                      }
                      style={{ width: '150px', height: '150px' }}
                    >
                      <Spinner />
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Form.Group className="form-group mb-3" controlId="formLot">
                <Form.Label>{t('Lot')}</Form.Label>
                <Form.Control
                  isInvalid={!!errors.lot && touched.lot}
                  maxLength={20}
                  name={'lot'}
                  onChange={handleChange}
                  placeholder={t('Example: {{value}}', { value: 100 })}
                  type="text"
                  value={values.lot ?? undefined}
                />
                <Form.Control.Feedback type={'invalid'}>{errors.lot}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  {t('animalModal|animalLotIdInstructions')}
                </Form.Text>
              </Form.Group>

              <Row>
                <Col md={6} sm={12}>
                  <Form.Group className="form-group mb-3" controlId="formGender">
                    <Form.Label>
                      {t('Gender')} <Required />
                    </Form.Label>
                    <GenderSelect
                      isInvalid={!!errors.genderId && touched.genderId}
                      name={'genderId'}
                      onChange={handleChange}
                      onCloseAddModal={(): void => setShowAddGenderModal(false)}
                      showAddModal={showAddGenderModal}
                      value={values.genderId}
                    />
                    <Form.Control.Feedback type={'invalid'}>
                      {errors.genderId}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      <span>{t('Choose a gender from the dropdown or')} </span>
                      <Button
                        onClick={(): void => setShowAddGenderModal(true)}
                        size={'sm'}
                        variant={'link'}
                      >
                        {t('add gender')}
                      </Button>
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group className="form-group mb-3" controlId="formPen">
                    <Form.Label>
                      {t('Pen')} <Required />
                    </Form.Label>
                    <PenSelect
                      isInvalid={!!errors.currentPenId && touched.currentPenId}
                      name={'currentPenId'}
                      onChangeDom={handleChange}
                      onCloseAddModal={(): void => setShowAddPenModal(false)}
                      showAddModal={showAddPenModal}
                      value={values.currentPenId}
                    >
                      <Form.Control.Feedback type={'invalid'}>
                        {errors.currentPenId}
                      </Form.Control.Feedback>
                    </PenSelect>

                    <Form.Text className="text-muted">
                      <span>{t('Choose a pen from the dropdown or')} </span>
                      <Button
                        onClick={(): void => setShowAddPenModal(true)}
                        size={'sm'}
                        variant={'link'}
                      >
                        {t('add pen')}
                      </Button>
                    </Form.Text>
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Form.Group className="form-group mb-3" controlId="formDateOfBirth">
                    <Form.Label>{t('Date of birth')}</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.birthDate && touched.birthDate}
                      name={'birthDate'}
                      onChange={handleChange}
                      placeholder={t('animalModal|animalBirthDatePlaceholder')}
                      type="date"
                      value={values.birthDate ?? undefined}
                    />
                    <Form.Text className="text-muted">
                      {t('animalModal|animalBirthDatePlaceholder')}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group className="form-group mb-3" controlId="formBreed">
                    <Form.Label>{t('Breed')}</Form.Label>
                    <BreedSelect
                      onChange={handleChange}
                      onCloseAddModal={(): void => setShowAddBreedModal(false)}
                      showAddModal={showAddBreedModal}
                      value={values.breedId ?? ''}
                    />
                    <Form.Control.Feedback type={'invalid'}>{errors.breedId}</Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      <span>{t('Choose a breed from the dropdown or')}</span>
                      <Button
                        onClick={(): void => setShowAddBreedModal(true)}
                        size={'sm'}
                        variant={'link'}
                      >
                        {t('add breed')}
                      </Button>
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group className="form-group b-3" controlId="formColour">
                    <Form.Label>{t('Colour')}</Form.Label>
                    <AnimalColorSelect
                      onChange={handleChange}
                      onCloseAddModal={(): void => setShowAddAnimalColorModal(false)}
                      showAddModal={showAddAnimalColorModal}
                      value={values.animalColorId ?? ''}
                    />
                    <Form.Text className="text-muted">
                      Select colour or{' '}
                      <Button
                        onClick={(): void => setShowAddAnimalColorModal(true)}
                        size={'sm'}
                        variant={'link'}
                      >
                        {t('add colour')}
                      </Button>
                    </Form.Text>
                  </Form.Group>
                </Col>
                {!props.editId && (
                  <>
                    <Col md={6} sm={12}>
                      <Form.Group className={'form-group mb-3'}>
                        <Form.Label>Weight</Form.Label>
                        <InputGroup className={''}>
                          <FormControl
                            aria-label="Weight"
                            isInvalid={!!errors.weight && touched.weight}
                            maxLength={4}
                            name={'weight'}
                            onChange={handleChange}
                            placeholder={placeholderExample(t, 568, 'lb', units.weightUnit)}
                            style={{ width: '60%' }}
                            type={'number'}
                            value={values.weight ?? undefined}
                          />
                          <Form.Select
                            aria-label="Weight Unit"
                            className={'no-validate'}
                            name={'weightUnit'}
                            onChange={onUnitChange}
                            value={units.weightUnit}
                          >
                            <option value="lb">lbs</option>
                            <option value="kg">kg</option>
                          </Form.Select>
                        </InputGroup>
                        <Form.Control.Feedback type={'invalid'}>
                          {errors.weight}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('animalModal|animalWeightInstructions')}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group className={'form-group mb-3'}>
                        <Form.Label>{t('Temperature')}</Form.Label>
                        <InputGroup>
                          <FormControl
                            aria-label="Temperature"
                            isInvalid={!!errors.temperature && touched.temperature}
                            max={120}
                            min={0}
                            name={'temperature'}
                            onChange={handleChange}
                            placeholder={placeholderExample(t, 37.8, 'C', units.temperatureUnit)}
                            step={0.1}
                            style={{ width: '60%' }}
                            type={'number'}
                            value={values.temperature ?? undefined}
                          />
                          <Form.Select
                            aria-label="Temperature unit"
                            className={'no-validate'}
                            name={'temperatureUnit'}
                            onChange={onUnitChange}
                            value={units.temperatureUnit}
                          >
                            <option value="C">&#8451;</option>
                            <option value="F">&#8457;</option>
                          </Form.Select>
                        </InputGroup>
                        <Form.Control.Feedback type={'invalid'}>
                          {errors.temperature}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('animalModal|animalTemperatureInstructions')}{' '}
                          {t('animalModal|animalTemperatureRequirements')}
                        </Form.Text>
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                      <Form.Group className={'form-group mb-3'}>
                        <Form.Label>{t('Hip height')}</Form.Label>
                        <InputGroup>
                          <FormControl
                            aria-label="Hip height"
                            isInvalid={!!errors.hipHeight && touched.hipHeight}
                            maxLength={4}
                            name={'hipHeight'}
                            onChange={handleChange}
                            placeholder={placeholderExample(t, 45, 'in', units.hipHeightUnit)}
                            style={{ width: '60%' }}
                            type={'number'}
                            value={values.hipHeight ?? undefined}
                          />
                          <Form.Select
                            aria-label="Hip height"
                            className={'no-validate'}
                            name={'hipHeightUnit'}
                            onChange={onUnitChange}
                            value={units.hipHeightUnit}
                          >
                            <option value="in">{t('in')}</option>
                            <option value="ft">{t('ft')}</option>
                            <option value="cm">{t('cm')}</option>
                            <option value="m">{t('m')}</option>
                          </Form.Select>
                        </InputGroup>
                        <Form.Control.Feedback type={'invalid'}>
                          {errors.hipHeight}F
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('animalModal|animalHipHeightInstructions')}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    {/*<Col md={6} sm={12}>*/}
                    {/*  <Form.Group className="form-group mb-3" controlId="formReproductionStatus">*/}
                    {/*    <Form.Label>{t('Reproduction status')}</Form.Label>*/}
                    {/*    <ReproStatusSelect*/}
                    {/*      onChange={(e): void => setReproStatus(e.target.value)}*/}
                    {/*      onCloseAddModal={(): void => setShowAddReproStatusModal(false)}*/}
                    {/*      showAddModal={showAddReproStatusModal}*/}
                    {/*      value={reproStatus}*/}
                    {/*    />*/}
                    {/*    <Form.Text className="text-muted">*/}
                    {/*      <span>{t('Choose a reproduction status from the dropdown or ')}</span>*/}
                    {/*      <Button*/}
                    {/*        onClick={(): void => setShowAddReproStatusModal(true)}*/}
                    {/*        size={'sm'}*/}
                    {/*        variant={'link'}*/}
                    {/*      >*/}
                    {/*        {t('add status')}*/}
                    {/*      </Button>*/}
                    {/*    </Form.Text>*/}
                    {/*  </Form.Group>*/}
                    {/*</Col>*/}

                    <Col md={6} sm={12}>
                      <Form.Group className="form-group mb-3" controlId="formLactationNumber">
                        <Form.Label>{t('Lactation number')}</Form.Label>
                        <Form.Control
                          isInvalid={!!errors.lactationNumber && touched.lactationNumber}
                          maxLength={3}
                          name={'lactationNumber'}
                          onChange={handleChange}
                          placeholder={t('Example: {{value}}', { value: 6 })}
                          type="number"
                          value={values.lactationNumber ?? undefined}
                        />
                        <Form.Control.Feedback type={'invalid'}>
                          {errors.lactationNumber}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('animalModal|animalLactationNumberInstructions')}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group className="form-group mb-3" controlId="formDim">
                        <Form.Label>{t('Days in milk (DIM)')}</Form.Label>
                        <Form.Control
                          isInvalid={!!errors.dim && touched.dim}
                          maxLength={4}
                          name={'dim'}
                          onChange={handleChange}
                          placeholder={t('Example: {{value}}', { value: 6 })}
                          type="number"
                          value={values.dim ?? undefined}
                        />
                        <Form.Control.Feedback type={'invalid'}>{errors.dim}</Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('animalModal|animalDaysInMilkInstructions')}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
              <AlertErrorForModal message={errorMessage} />
              <div className="modal-footer modal-footer-in-form">
                <ButtonModalCancel
                  disabled={mutation.isLoading || query.isLoading}
                  onClick={props.onClose}
                />
                <ButtonModalSave
                  busy={mutation.isLoading}
                  disabled={mutation.isLoading || query.isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default AnimalModal;
