// Copyright © 2025 CATTLEytics Inc.

import React, { useEffect, useState } from 'react';
import { Form, FormGroup, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AlertError from '../../common/components/AlertError';
import Button from '../../common/components/Button';
import ButtonSave from '../../common/components/ButtonSave';
import PlaceholderForm from '../../common/components/PlaceholderForm';
import Toast from '../../common/components/Toast';
import { useFocusField } from '../../common/hooks/useFocusField';
import { api } from '../../common/utilities';
import { ReportEmbedded } from '../../shared';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared/enums';

interface ReportControllerData {
  reports: ReportEmbedded[];
}

/**
 * Reports Embedded tab.
 */
export const ReportsEmbeddedTab = (): JSX.Element => {
  const { t } = useTranslation();

  const [toastVisible, setToastVisible] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [records, setRecords] = useState<ReportEmbedded[]>([]);

  const queryClient = useQueryClient();

  const query = useQuery(
    [QueryKey.Settings, 'reports-embedded'],
    () => api<ReportControllerData>(HttpMethod.Get, `${ApiResourceV1.Settings}/reports-embedded`),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (!query.isLoading) {
      setRecords(query.data?.reports ?? []);
    }
  }, [query.data, query.isLoading]);

  const mutation = useMutation(
    () => {
      return api(HttpMethod.Patch, `${ApiResourceV1.Settings}/reports-embedded`, {
        body: {
          embedded: JSON.stringify(
            records.map((po) => {
              return { ...po, iframeProps: tryParseJSON(po.iframeProps) };
            }),
          ),
        },
      });
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Settings);
      },
    },
  );

  useFocusField({ validated });

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // fix any iframeProps (parseJSON)
    try {
      setRecords((prev) => {
        return prev.map((po) => {
          return { ...po, iframeProps: tryParseJSON(po.iframeProps) };
        });
      });
    } catch {
      return;
    }

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      setToastVisible(true);
    } catch (err) {
      console.error('Settings could not be saved.', err);
      setErrorMessage(t('animalsTab|settingsNotSavedError'));
    }
  };

  const placeholder = <PlaceholderForm fields={3} />;

  const onChange = (index: number, key: string, value: string): void => {
    setRecords((prevData) => {
      const nextData = prevData ?? [];

      return nextData.map((n, i) => {
        if (i === index) {
          return { ...n, [key]: value };
        }
        return n;
      });
    });
  };

  const addNewRecord = (): void => {
    setRecords((prev) => {
      return [...prev, {}];
    });
  };
  const deleteRecord = (_item: ReportEmbedded, index: number): void => {
    setRecords((prev) => {
      return prev.filter((v, i) => {
        return i !== index;
      });
    });
  };

  return (
    <Form noValidate onSubmit={onFormSubmit} validated={validated}>
      {query.isLoading ? (
        placeholder
      ) : (
        <>
          {records.map((item, index) => {
            return (
              <div
                className="m-2 border border-primary rounded p-3"
                key={`report-embedded-${index}`}
              >
                <FormGroup className="mb-3">
                  <Form.Label>Key-Group:</Form.Label>

                  <Form.Control
                    name={`reportGroup-${index}`}
                    onChange={(ev): void => {
                      onChange(index, 'reportGroup', ev.target.value);
                    }}
                    type="text"
                    value={item.reportGroup}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Label>Key-Name:</Form.Label>

                  <Form.Control
                    name={`reportName-${index}`}
                    onChange={(ev): void => {
                      onChange(index, 'reportName', ev.target.value);
                    }}
                    type="text"
                    value={item.reportName}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Label>Name:</Form.Label>

                  <Form.Control
                    name={`name-${index}`}
                    onChange={(ev): void => {
                      onChange(index, 'name', ev.target.value);
                    }}
                    type="text"
                    value={item.name}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Label>URL:</Form.Label>

                  <Form.Control
                    name={`url-${index}`}
                    onChange={(ev): void => {
                      onChange(index, 'url', ev.target.value);
                    }}
                    type="text"
                    value={item.url}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Label>Type:</Form.Label>

                  <Form.Control
                    name={`type-${index}`}
                    onChange={(ev): void => {
                      onChange(index, 'type', ev.target.value);
                    }}
                    type="text"
                    value={item.type}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Label>iframe props:</Form.Label>

                  <Form.Control
                    name={`iframeProps-${index}`}
                    onChange={(ev): void => {
                      onChange(index, 'iframeProps', ev.target.value);
                    }}
                    type="text"
                    value={
                      typeof item.iframeProps === 'string'
                        ? item.iframeProps
                        : JSON.stringify(item.iframeProps)
                    }
                  />
                </FormGroup>
                <Button
                  label="Delete"
                  onClick={(): void => {
                    deleteRecord(item, index);
                  }}
                ></Button>
              </div>
            );
          })}
        </>
      )}

      <Button
        label="Add"
        onClick={(): void => {
          addNewRecord();
        }}
      />

      {errorMessage ? <AlertError message={errorMessage} /> : null}

      <p className={'d-flex justify-content-center'}>
        {query.isFetching ? (
          <PlaceholderButton xs={2} />
        ) : (
          <ButtonSave busy={mutation.isLoading} disabled={mutation.isLoading} />
        )}
      </p>
      <Toast onClose={(): void => setToastVisible(false)} show={toastVisible}>
        {t('dataImportTab|settingSavedToast')}
      </Toast>
    </Form>
  );
};

function tryParseJSON<T>(input: string | any): T | undefined {
  if (typeof input !== 'string') {
    return input;
  }
  try {
    return JSON.parse(input);
  } catch {
    return undefined;
  }
}
