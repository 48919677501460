// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useState } from 'react';
import { Col, Form, PlaceholderButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import Button from '../../common/components/Button';
import ButtonSave from '../../common/components/ButtonSave';
import ImageUploaded from '../../common/components/ImageUploaded';
import ImageUploading from '../../common/components/ImageUploading';
import NoImageUploaded from '../../common/components/NoImageUploaded';
import PlaceholderForm from '../../common/components/PlaceholderForm';
import Required from '../../common/components/Required';
import Toast from '../../common/components/Toast';
import { useFileService } from '../../common/hooks/useFileService';
import { useFocusField } from '../../common/hooks/useFocusField';
import SettingService from '../../common/services/settingService';
import { ImageSize, resizeImage } from '../../common/utilities/imageResize';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared/enums';

/**
 * General settings tab.
 */
const GeneralTab = (): JSX.Element => {
  const { t } = useTranslation();

  const fileService = useFileService();
  const settingService = useInjection<SettingService>(TYPES.settingService);
  const logger = useInjection<Logger>(TYPES.logger);
  const [data, setData] = useState<Record<string, string>>({
    siteName: '',
    siteLogoKey: '',
    siteIconKey: '',
  });

  const [busyFileUploading, setBusyFileUploading] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const queryClient = useQueryClient();

  const query = useQuery([QueryKey.Settings, 'general'], () => settingService.get('general'), {
    onSuccess: (data) => setData(data as Record<string, string>),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: true,
  });

  const mutation = useMutation(
    () => {
      return settingService.patch('general', data);
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Settings);
        await queryClient.invalidateQueries(QueryKey.Sites);
      },
    },
  );

  useFocusField({ validated });

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      setToastVisible(true);
    } catch (err) {
      logger.error('Settings could not be saved.', err, data);
      setErrorMessage(t('generalTab|settingsNotSavedError'));
    }
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = ((event.target as HTMLInputElement).files as FileList)[0];
    await uploadFile(event.target.name, file);
  };

  const uploadFile = async (fieldName: string, file: File): Promise<void> => {
    setBusyFileUploading(true);

    const fileResult = await fileService.upload(await resizeImage(file, ImageSize.Medium));
    if (fileResult.signedUrl) {
      setData((prevState) => ({
        ...prevState,
        [`${fieldName}Key`]: fileResult.key as string,
        [`${fieldName}UrlSigned`]: fileResult.signedUrl as string,
      }));
    }
    setBusyFileUploading(false);
  };

  const placeholder = <PlaceholderForm fields={3} />;

  const fields = (
    <>
      <Form.Group className="form-group mb-3" controlId="organizationName">
        <Form.Label>
          {t('Organization Name')} <Required />
        </Form.Label>
        <Form.Control
          name={'siteName'}
          onChange={onInputChange}
          placeholder={t('Example: {{value}}', { value: `Larry's Dairy` })}
          required
          type="text"
          value={data.siteName}
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
        <Form.Text className={'text-muted'}>{t('generalTab|organizationNameText')}</Form.Text>
      </Form.Group>
      <Row>
        <Col md={8}>
          <Form.Group className="form-group mb-3" controlId="organizationLogo">
            <Form.Label>{t('Organization Banner')}</Form.Label>
            <Form.Control name={'siteLogo'} onChange={handleFileUpload} type="file" />
            <Form.Text className={'text-muted'}>
              {t('A horizontal image, banner or logo to represent your organization.')}
            </Form.Text>
            {data.siteLogoUrlSigned && (
              <p>
                <Button
                  onClick={(): void =>
                    setData((prevState) => ({
                      ...prevState,
                      siteLogoKey: '',
                      siteLogoUrlSigned: '',
                    }))
                  }
                  size={'sm'}
                  variant={'outline-secondary'}
                >
                  {t('Remove Image')}
                </Button>
              </p>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          {!busyFileUploading && data.siteLogoUrlSigned && (
            <ImageUploaded alt={t('Organization logo')} src={data.siteLogoUrlSigned} />
          )}
          {!busyFileUploading && !data.siteLogoUrlSigned && <NoImageUploaded />}
          {busyFileUploading && <ImageUploading />}
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form.Group className="form-group mb-3" controlId="siteIcon">
            <Form.Label>{t('Organization Icon')}</Form.Label>
            <Form.Control name={'siteIcon'} onChange={handleFileUpload} type="file" />
            <Form.Text className={'text-muted'}>
              {t('generalTab|organizationImageInstructions')}
            </Form.Text>
            {data.siteIconUrlSigned && (
              <p>
                <Button
                  onClick={(): void =>
                    setData((prevState) => ({
                      ...prevState,
                      siteIconKey: '',
                      siteIconUrlSigned: '',
                    }))
                  }
                  size={'sm'}
                  variant={'outline-secondary'}
                >
                  {t('Remove Image')}
                </Button>
              </p>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          {!busyFileUploading && data.siteIconUrlSigned && (
            <ImageUploaded alt={t('Organization icon')} src={data.siteIconUrlSigned} />
          )}
          {!busyFileUploading && !data.siteIconUrlSigned && <NoImageUploaded />}
          {busyFileUploading && <ImageUploading />}
        </Col>
      </Row>
    </>
  );

  return (
    <Form noValidate onSubmit={onFormSubmit} validated={validated}>
      {query.isFetching ? placeholder : fields}

      {errorMessage ? <AlertError message={errorMessage} /> : null}

      <p className={'d-flex justify-content-center'}>
        {query.isFetching ? (
          <PlaceholderButton xs={2} />
        ) : (
          <ButtonSave busy={mutation.isLoading} disabled={mutation.isLoading} />
        )}
      </p>
      <Toast onClose={(): void => setToastVisible(false)} show={toastVisible}>
        {t('Settings saved')}
      </Toast>
    </Form>
  );
};

export default GeneralTab;
