// Copyright © 2024 CATTLEytics Inc.

import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Page from '../common/components/Page';
import { useAuth } from '../common/store/useAuth';
import { IconSuperAdmin } from '../common/utilities';
import { isSuperAdmin } from '../common/utilities';
import SyncTable from './SyncTable';

interface RouteParams {
  // Tab key to make active on render.
  tabKey: string;
}

interface Props {
  className?: string;
}

/**
 * Routable component to perform some admin actions
 */
const SyncPage = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const auth = useAuth();

  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();

  return (
    <Page className={props.className} title={t('syncPage|title')}>
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="events-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/system/sync/${k}`);
          }
        }}
      >
        {isSuperAdmin(auth) && (
          <Tab
            eventKey="all-sites"
            title={
              <>
                <IconSuperAdmin />
                {t('syncPage|allSitesTab')}
              </>
            }
          />
        )}
      </Tabs>
      <SyncTable />
    </Page>
  );
};

export default SyncPage;
