// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../common/store/useAuth';
import { isSuperAdmin } from '../../common/utilities';
import { ApiResourceV1, QueryKey } from '../../shared';
import ManageListPage from './ManageListPage';

interface RouteParams {
  action: string;
}

/**
 * Routable component to manage list of animal references types.
 */
const ListsAnimalReferenceTypePage = (): JSX.Element => {
  const { action } = useParams<RouteParams>();
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <ManageListPage
      apiResource={ApiResourceV1.AnimalReferenceTypes}
      pageTitle={t('entity|animalReferenceType', { count: 2 })}
      queryKey={QueryKey.AnimalReferenceTypes}
      restricted={!isSuperAdmin(auth)}
      showAddModal={!!action}
      typeLabel={'reference'}
      typeName={'animalReferenceType'}
    />
  );
};

export default ListsAnimalReferenceTypePage;
