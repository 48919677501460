// Copyright © 2023 CATTLEytics Inc.

import { addDays, addHours, endOfDay, parse } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Formik } from 'formik';
import { ChangeEvent, PropsWithChildren, ReactElement, useState } from 'react';
import {
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { Option } from 'react-bootstrap-typeahead/types/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { FaMagic } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import { AnimalMentionsTextArea } from '../../common/components/mentions';
import { AnimalMentionsProtocolsInput } from '../../common/components/mentions/TemplateMentionsInput';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import { TaskCategorySelect } from '../../common/components/TaskCategorySelect';
import TaskStatusSelect from '../../common/components/TaskStatusSelect';
import { TimeSelectEODValue } from '../../common/components/TimeSelect';
import { useFileService } from '../../common/hooks/useFileService';
import { useFocusField } from '../../common/hooks/useFocusField';
import { ImageUrl } from '../../common/interfaces';
import { useAuth } from '../../common/store/useAuth';
import { useSettingsContext } from '../../common/store/useSettingsContext';
import { api, IconCopyToClipboard, IconView } from '../../common/utilities';
import { ImageSize, resizeImage } from '../../common/utilities/imageResize';
import { useFeatureFlags } from '../../settings/hooks/useFeatureFlags';
import {
  Animal,
  ApiResourceV1,
  endOfSiteDay,
  HttpMethod,
  isNullOrWhitespace,
  nowSiteTz,
  QueryKey,
  Task,
  TaskPriority,
  TaskStatus,
} from '../../shared';
import { TaskMode } from '../../shared/enums/taskMode';
import { TaskRepeat } from '../../shared/enums/taskRepeat';
import UserAutocomplete from '../../users/components/UserAutocomplete';
import { FormControlDate } from './FormControlDate';
import { TaskPriorityPicker } from './TaskPriority';
import { TaskSchedule } from './TaskSchedule';
import { UploadFileField } from './UploadFileField';
import { UrlPreview } from './UrlPreview';
import { computeCurrentTaskDate, getTaskStateByDueDate } from './utilities';

// const getFormattedDate = (date?: string): string =>
//   date ? format(new Date(date), dateFormat) : '';

// const getFormattedTime = (date?: string): string =>
//   date ? format(new Date(date), 'p') : '6:00 AM';

function asImageUrls(input: string[]): ImageUrl[] {
  return input.map((url) => ({ key: url } as ImageUrl));
}

function asStringUrls(input: ImageUrl[]): string[] {
  return input.map((item) => (item.key as string) ?? '');
}

function isNullOrUndefined(input: unknown): boolean {
  return (
    input === undefined ||
    input === null ||
    (typeof input === 'string' && isNullOrWhitespace(input))
  );
}

function fromFormattedTimeToUTC(date: string | Date, time: string, timeZone: string): Date {
  try {
    const validDateStr =
      typeof date === 'string' ? date : formatInTimeZone(date, timeZone, 'yyyy-MM-dd');
    const parsed = parse(
      `${validDateStr} ${time.padStart(8, '0')}`,
      'yyyy-MM-dd hh:mm aa',
      new Date(),
      {},
    );

    return zonedTimeToUtc(parsed, timeZone);
  } catch (err) {
    console.debug(err);
  }
  return new Date();
}

function asTimeValue(scheduleEndDate: string | Date | undefined, timeZone: string): any {
  if (scheduleEndDate === undefined || scheduleEndDate === null) {
    return '06:00 AM';
  }
  return formatInTimeZone(scheduleEndDate, timeZone, 'hh:mm aa');
}

// function fromStringUrls(input: ImageUrl[]): string[] {
//   return input.map((item) => (item.signedUrl as string) ?? '');
// }

/**
 * Component input properties.
 */
interface Props {
  /**
   * Animal Object from animal card.
   */
  animal?: Animal;

  /**
   * Animal ID to associate this task with
   */
  animalId?: number;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * Callback on successful save. Note: onClose is NOT called when onSave is set
   * @param task
   */
  onSave?: (task: Task) => void;

  /**
   * Pen ID to associate with this task
   */
  penId?: number;

  /**
   * ID of task to edit in this modal.
   */
  task?: Task;
}

/**
 * Task modal component for creating/editing tasks.
 */
export const TaskModal = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const fileService = useFileService();
  const auth = useAuth();
  const settings = useSettingsContext();
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const [showAddTaskCategoryModal, setShowAddTaskCategoryModal] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [magicLoading, setMagicLoading] = useState<boolean>(false);

  // If task is opened through animal card, preset the description with a mention of given animal

  const taskSchema = yup.object().shape({
    description: yup
      .string()
      .test('len', t('taskModal|titleLengthError'), (val) => (val?.length ?? -1) >= 4)
      .required(t('common|fieldRequiredFeedback')),
    instructions: yup.string().optional(),
    priority: yup.string().required(t('common|fieldRequiredFeedback')),
    taskCategoryId: yup.number().min(0).required(t('common|fieldRequiredFeedback')),
    imageUrls: yup.array<yup.AnyObject, ImageUrl>().optional().of(yup.string()),
    websiteUrl: yup.string().trim().optional(),
    notes: yup.string().trim().optional(),

    taskSchedule: yup.object().optional(),
    startDate: yup
      .date()
      .test('require-when-repeating', 'required', (v, context) => {
        // when scheduleMode is repeat, startDate is required
        if (context.parent.scheduleMode === TaskMode.Repeat) {
          return v !== undefined;
        }
        return true;
      })
      .optional(),
    endDate: yup.date().optional(),
    startTimeValue: yup.string().optional(),
    endTimeValue: yup.string(),

    dueDate: yup.date().optional(),

    status: yup.string().required(t('common|fieldRequiredFeedback')),
    assignedUser: yup.object().nullable(),
    assignedUserId: yup.number().nullable(),

    scheduleMode: yup.string().optional().oneOf(['once', 'repeat']),
    scheduleRepeat: yup.string().optional(),
    scheduleRepeatInterval: yup.number().min(1).optional(),
    scheduleRepeatDayOfWeek: yup.string().optional(),
    scheduleRepeatDayOfPeriod: yup.number().nullable().min(1).max(31),
    scheduleReset: yup.boolean().optional(),
    scheduleEndDate: yup.date().optional().nullable(),
    toleranceDays: yup.number().nullable(),
  });

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [busyFileUploading, setBusyFileUploading] = useState<boolean>(false);
  const [showUploadImageForm, setShowUploadImageForm] = useState<boolean>(false);

  useFocusField({ validated });

  const taskMutation = useMutation<Task, unknown, Partial<Task>>(
    (data: Partial<Task>) => {
      const payload: Partial<Task> = data;
      // convert date/times to settings.timeZone
      if (payload.scheduleRepeat) {
        // // populate dueDate with correct dueDate
        // const dates = computeNextTaskDate(payload, { timeZone: settings.timeZone });
        // payload.dueDate = dates?.dueDate;
      }
      payload.dueDate = payload.dueDate
        ? zonedTimeToUtc(payload.dueDate, settings.timeZone).toISOString()
        : undefined;
      payload.startDate = payload.startDate
        ? zonedTimeToUtc(payload.startDate, settings.timeZone).toISOString()
        : undefined;
      payload.scheduleEndDate = payload.scheduleEndDate
        ? zonedTimeToUtc(payload.scheduleEndDate, settings.timeZone).toISOString()
        : undefined;

      if (props.task?.id) {
        return api(HttpMethod.Patch, `${ApiResourceV1.Tasks}/${props.task?.id}`, { body: payload });
      } else {
        return api(HttpMethod.Post, `${ApiResourceV1.Tasks}`, { body: payload });
      }
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Tasks);
      },
    },
  );

  const queryClient = useQueryClient();

  const onFormSubmit = async (values: Partial<Task>): Promise<void> => {
    // mark the form as having its validity checked - formik checked
    setValidated(true);

    if (typeof values.scheduleEndDate === 'string' && isNullOrWhitespace(values.dueDate)) {
      values.dueDate = null;
    }

    // format dates as ISO
    if (typeof values.scheduleEndDate === 'string' && isNullOrWhitespace(values.scheduleEndDate)) {
      values.scheduleEndDate = undefined;
    }
    if (typeof values.startDate === 'string' && isNullOrWhitespace(values.startDate)) {
      values.startDate = undefined;
    }

    // update the template id from the list of tables.
    if (values.template) {
      values.templateId = values.template?.id;
    }

    if (values.scheduleMode === TaskMode.Repeat) {
      if (values.scheduleEndDate !== null && values.scheduleEndDate !== undefined) {
        // add endDateTime to scheduleEndDate
        values.scheduleEndDate = utcToZonedTime(
          fromFormattedTimeToUTC(
            values.scheduleEndDate ?? '',
            values.endTimeValue ?? '',
            settings.timeZone,
          ),
          settings.timeZone,
        );
      }
    }

    setErrorMessage('');
    try {
      // TODO: handle dueDate?
      const task = await taskMutation.mutateAsync(values);
      if (task) {
        props.onSave?.(task);
      }
      props.onClose();
    } catch (err) {
      console.error('Task could not be saved.', err);
      setErrorMessage(t('taskModal|taskNotSavedError'));
    }
  };

  const removeImage = (values: any, handleChange: any, index: number): void => {
    const updatedImageUrls = [...values.imageUrls];
    delete updatedImageUrls[index];
    // setImageUrls(Object.values(updatedImageUrls));
    handleChange({ target: { value: Object.values(updatedImageUrls), name: 'imageUrls' } });
  };

  const handleFileUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    values: Partial<Task>,
    handleChange: any,
  ): Promise<void> => {
    const file = ((event.target as HTMLInputElement).files as FileList)[0];
    setBusyFileUploading(true);
    await uploadFile(await resizeImage(file, ImageSize.Large), values, handleChange);
    setBusyFileUploading(false);
  };

  const uploadFile = async (
    file: File,
    values: Partial<Task>,
    handleChange: any,
  ): Promise<void> => {
    const fileResult = await fileService.upload(file, undefined, auth.siteId);
    if (fileResult.signedUrl) {
      const newImageUrls = values.imageUrls ? [...values.imageUrls] : [];
      const newSignedUrls = values.imageUrlsSigned ? [...values.imageUrlsSigned] : [];

      newImageUrls.push(fileResult.key);
      newSignedUrls.push({ key: fileResult.key, signedUrl: fileResult.signedUrl });

      handleChange({ target: { value: newImageUrls, name: 'imageUrls' } });

      handleChange({ target: { value: newSignedUrls, name: 'imageUrlsSigned' } });
      setShowUploadImageForm(false);
    }
  };

  const magicInstructions = async (values: Partial<Task>, handleChange: any): Promise<void> => {
    setMagicLoading(true);
    const result = await api<{ analysis: string }, { description?: string; title?: string }>(
      HttpMethod.Post,
      '/v1/tasks/actions/aiInstructions',
      {
        body: { title: values.description, description: values.notes },
      },
    ).catch((_reason: any) => {
      // console.debug(`ai:error`, { reason, result });
      setMagicLoading(true);
    });
    setMagicLoading(false);

    // console.debug(`ai:result`, result);
    // alert(result);
    handleChange({ target: { value: result?.analysis ?? '', name: 'instructions' } });
  };

  function convertFromEndOfDay(time: Option): Option {
    return time === t('timeSelect|endOfDay') ? TimeSelectEODValue : time;
  }

  return (
    <Modal
      extraModalProps={{ dialogClassName: 'w-100 vw-100' }}
      onClose={props.onClose}
      size={'xl'}
      title={`${props.task?.id ? 'Edit' : 'Create'} Task`}
      visible={true}
    >
      <Formik
        initialValues={{
          description: props.task?.description ?? '',
          instructions: props.task?.instructions ?? '',
          taskCategoryId: props.task?.taskCategoryId ?? -1,
          priority: props.task?.priority ?? TaskPriority.Medium,
          template: props.task?.template,
          imageUrls:
            props.task?.imageUrls && !props.task?.imageUrlsSigned
              ? props.task?.imageUrls
              : asStringUrls(props.task?.imageUrlsSigned ?? []) ?? ([] as string[]),
          websiteUrl: props.task?.websiteUrl ?? '',
          websiteAudioUrl: props.task?.websiteAudioUrl ?? '',
          websiteTestUrl: props.task?.websiteTestUrl ?? '',
          scheduleMode: props.task?.scheduleMode ?? TaskMode.Once,
          startDate: !isNullOrUndefined(props.task?.startDate)
            ? formatInTimeZone(props.task?.startDate ?? '', settings.timeZone, 'yyyy-MM-dd')
            : '', // utc time, should be zonedTime
          notes: props.task?.notes ?? '',
          dueDate: !isNullOrUndefined(props.task?.dueDate)
            ? formatInTimeZone(props.task?.dueDate ?? '', settings.timeZone, 'yyyy-MM-dd')
            : formatInTimeZone(
                endOfSiteDay(new Date(), settings.timeZone),
                settings.timeZone,
                'yyyy-MM-dd',
              ),
          status: props.task?.status ?? TaskStatus.Pending,
          assignedUser: props.task?.assignedUser,
          assignedUserId: props.task?.assignedUserId,
          endTimeValue: asTimeValue(props.task?.scheduleEndDate, settings.timeZone) ?? '6:00 AM',
          scheduleRepeat: props.task?.scheduleRepeat ?? TaskRepeat.Weekly,
          scheduleRepeatInterval: props.task?.scheduleRepeatInterval ?? 1,
          scheduleRepeatDayOfWeek: props.task?.scheduleRepeatDayOfWeek ?? '',
          scheduleRepeatDayOfPeriod: props.task?.scheduleRepeatDayOfPeriod,
          scheduleReset: props.task?.scheduleReset ?? false,
          scheduleEndDate: !isNullOrUndefined(props.task?.scheduleEndDate)
            ? formatInTimeZone(props.task?.scheduleEndDate ?? '', settings.timeZone, 'yyyy-MM-dd')
            : '',
          startTimeValue: !isNullOrUndefined(props.task?.startDate)
            ? formatInTimeZone(props.task?.startDate ?? '', settings.timeZone, 'hh:mm aa')
            : '6:00 AM',
          toleranceDays: props.task?.toleranceDays,
        }}
        key={`${props.task?.id}`}
        onSubmit={onFormSubmit}
        validationSchema={taskSchema}
      >
        {({ handleSubmit, handleChange, values, errors, touched }): ReactElement<any, any> => {
          const selectedTaskState = getTaskStateByDueDate(values.dueDate ?? '', settings.timeZone);
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <FormGroup className="form-group mb-3" controlId="formTitle">
                    <Form.Label>
                      {t('Title')} <Required />
                    </Form.Label>
                    <AnimalMentionsProtocolsInput
                      onChange={(ev): void => {
                        handleChange({ target: { name: 'description', value: ev.target.value } });
                      }}
                      onTemplateClick={(template): void => {
                        if (!template) {
                          handleChange({ target: { name: 'template', value: template ?? '' } });
                          return;
                        }
                        handleChange({ target: { name: 'template', value: template } });
                        handleChange({ target: { name: 'description', value: template?.title } });
                        handleChange({
                          target: { name: 'instructions', value: template?.instructions },
                        });
                        handleChange({
                          target: { name: 'websiteUrl', value: template?.websiteUrl ?? '' },
                        });
                        handleChange({
                          target: { name: 'websiteAudioUrl', value: template?.websiteAudioUrl },
                        });
                        handleChange({
                          target: { name: 'websiteTestUrl', value: template?.websiteTestUrl },
                        });
                        handleChange({
                          target: { name: 'notes', value: template?.description },
                        });
                        handleChange({
                          target: { name: 'taskCategoryId', value: template?.taskCategoryId },
                        });
                        handleChange({ target: { name: 'priority', value: template?.priority } });
                        handleChange({
                          target: {
                            name: 'imageUrls',
                            value: template?.imageUrls,
                          },
                        });
                      }}
                      singleLine
                      template={values.template}
                      value={values.description}
                    />
                    <Form.Text className={'text-muted'}>
                      {t('taskModal|taskTitleInstructions')}
                    </Form.Text>
                    <Form.Control.Feedback type={'invalid'}>
                      {errors.description}
                    </Form.Control.Feedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="form-group mb-3" controlId="formDescription">
                    <Form.Label>{t('Description')}</Form.Label>
                    <AnimalMentionsTextArea
                      name="notes"
                      onChange={(ev): void => {
                        handleChange({
                          target: { name: 'notes', value: ev.target.value },
                        });
                      }}
                      required
                      rows={4}
                      value={values.notes}
                    />
                    <Form.Text className={'text-muted'}>
                      {t('taskModal|taskNotesInstructions')}
                    </Form.Text>
                    <Form.Control.Feedback type={'invalid'}>{errors.notes}</Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="form-group mb-3" controlId="formInstructions">
                    <Form.Label>
                      {t('Instructions')}{' '}
                      {featureFlags['Tasks/Magic'] === 'True' && (
                        <Button
                          disabled={
                            isNullOrWhitespace(values.description) &&
                            isNullOrWhitespace(values.title)
                          }
                          icon={FaMagic}
                          onClick={async (): Promise<void> =>
                            await magicInstructions(values, handleChange)
                          }
                          size="sm"
                        >
                          {t('Magic')} {magicLoading && <Spinner size="sm" />}
                        </Button>
                      )}
                    </Form.Label>
                    <Form.Control
                      as={'textarea'}
                      isInvalid={touched.instructions && !!errors.instructions}
                      isValid={touched.instructions && !errors.instructions}
                      name="instructions"
                      onChange={handleChange}
                      rows={18}
                      value={values.instructions}
                    />
                    <Form.Text className={'text-muted'}>
                      {t('taskModal|taskDescriptionInstructions')}
                    </Form.Text>
                    <Form.Control.Feedback type={'invalid'}>
                      {errors.instructions}
                    </Form.Control.Feedback>
                  </FormGroup>

                  {/* <FormGroup className="form-group mb-3" controlId="formWebsite">
                    <Form.Label>{t('taskModal|taskWebsiteAudioUrlLabel')}</Form.Label>
                    <Form.Control
                      name="websiteAudioUrl"
                      onChange={handleChange}
                      value={values.websiteAudioUrl}
                    />
                    <Form.Text className={'text-muted'}>
                      {t('taskModal|taskWebsiteAudioUrlInstructions')}
                    </Form.Text>
                    <Form.Control.Feedback type={'invalid'}>
                      {errors.websiteAudioUrl}
                    </Form.Control.Feedback>
                    <UrlPreview uri={values.websiteAudioUrl} />
                  </FormGroup>

                  <FormGroup className="form-group mb-3" controlId="formWebsite">
                    <Form.Label>{t('taskModal|taskWebsiteTestUrlLabel')}</Form.Label>
                    <Form.Control
                      name="websiteTestUrl"
                      onChange={handleChange}
                      value={values.websiteTestUrl}
                    />
                    <Form.Text className={'text-muted'}>
                      {t('taskModal|taskWebsiteTestUrlInstructions')}
                    </Form.Text>
                    <Form.Control.Feedback type={'invalid'}>
                      {errors.websiteTestUrl}
                    </Form.Control.Feedback>
                    <UrlPreview uri={values.websiteTestUrl} />
                  </FormGroup> */}

                  <FormGroup className="form-group mb-3" controlId="formWebsite">
                    <Form.Label>{t('taskModal|taskWebsiteUrlLabel')}</Form.Label>
                    <Form.Control
                      name="websiteUrl"
                      onChange={handleChange}
                      value={values.websiteUrl}
                    />
                    <Form.Text className={'text-muted'}>
                      {t('taskModal|taskWebsiteUrlInstructions')}
                    </Form.Text>
                    <Form.Control.Feedback type={'invalid'}>
                      {errors.websiteUrl}
                    </Form.Control.Feedback>
                    <UrlPreview uri={values.websiteUrl} />
                  </FormGroup>

                  <Form.Group className="form-group mb-3" controlId="formTaskCategory">
                    <Form.Label>{t('taskModal|categoryFieldLabel')}</Form.Label>

                    <TaskCategorySelect
                      isInvalid={touched.taskCategoryId && !!errors.taskCategoryId}
                      isValid={touched.taskCategoryId && !errors.taskCategoryId}
                      name={'taskCategoryId'}
                      onChange={(ev): void => {
                        handleChange({
                          target: { name: 'taskCategoryId', value: ev.target.value },
                        });
                      }}
                      onCloseAddModal={(): void => setShowAddTaskCategoryModal(false)}
                      showAddModal={showAddTaskCategoryModal}
                      value={values.taskCategoryId}
                    />

                    <Form.Text className="text-muted">
                      <span>{t('taskModal|categoryFieldHint')}</span>
                      <Button
                        onClick={(): void => setShowAddTaskCategoryModal(true)}
                        size={'sm'}
                        variant={'link'}
                      >
                        {t('taskModal|categoryFieldAddButton')}
                      </Button>
                    </Form.Text>
                    <Form.Text> {errors.taskCategoryId}</Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-group mb-3" controlId="formStatus">
                    <Form.Group className="form-group mb-3" controlId="formUser">
                      <Form.Label>
                        {t(
                          values.status === TaskStatus.Completed ? 'Completed By' : 'Assigned User',
                        )}
                      </Form.Label>
                      <UserAutocomplete
                        id={'user'}
                        multiple={false}
                        name={'assignedUser'}
                        onCloseAddModal={(): void => setShowAddUserModal(false)}
                        onSelect={(selected): void => {
                          // since multiple is not set we can assume we are going to either get undefined or Animal
                          if (!selected || Array.isArray(selected)) {
                            // selected is undefined (can't actually not be an array because of multiple prop)

                            handleChange({ target: { name: 'assignedUser', value: selected } });
                            handleChange({ target: { name: 'assignedUserId', value: undefined } });
                            return;
                          }
                          handleChange({ target: { name: 'assignedUser', value: selected } });
                          handleChange({ target: { name: 'assignedUserId', value: selected.id } });
                        }}
                        required={false}
                        selected={values.assignedUser}
                        showAddModal={showAddUserModal}
                        validated={validated}
                      />
                      <Form.Text className="text-muted">
                        <span>
                          {t('Choose a {{value}} from the dropdown or', { value: 'user' })}{' '}
                        </span>
                        <Button
                          onClick={(): void => setShowAddUserModal(true)}
                          size={'sm'}
                          variant={'link'}
                        >
                          {t('add user')}
                        </Button>
                      </Form.Text>
                    </Form.Group>

                    <Form.Label>
                      {t('Status')} <Required />
                    </Form.Label>
                    <TaskStatusSelect
                      aria-label={t('Status')}
                      isInvalid={touched.status && !!errors.status}
                      isValid={touched.status && !errors.status}
                      onChange={(ev): void => {
                        handleChange({ target: { name: 'status', value: ev.currentTarget.value } });
                      }}
                      onCloseAddModal={(): undefined => undefined}
                      required={true}
                      showAddModal={false}
                      value={values.status ?? ''}
                    />
                    <Form.Text className={'text-muted'}>
                      {t('The current status of this task')}
                    </Form.Text>
                  </Form.Group>

                  <Card>
                    <CardBody>
                      <FormControlDate
                        date={
                          values.startDate
                            ? formatInTimeZone(values.startDate, settings.timeZone, 'yyyy-MM-dd')
                            : ''
                        }
                        errors={errors.startDate}
                        instruction={t('The date on which this task should be started')}
                        isInvalid={touched.startDate && !!errors.startDate}
                        isValid={touched.startDate && !errors.startDate}
                        label={t('Start Date')}
                        setDate={(value): void => {
                          // convert the date to utc, expect value to be in settings.timezone.
                          // const dateUtc = zonedTimeToUtc(value, settings.timeZone);
                          handleChange({ target: { name: 'startDate', value: value } });

                          if (values.scheduleMode === TaskMode.Repeat) {
                            const dates = computeCurrentTaskDate(
                              { ...values, startDate: value } as any,
                              {
                                timeZone: settings.timeZone,
                              },
                            );
                            if (dates?.dueDate && !isNaN(dates?.dueDate.getDate())) {
                              handleChange({
                                target: {
                                  name: 'dueDate',
                                  value: formatInTimeZone(
                                    dates?.dueDate,
                                    settings.timeZone,
                                    'yyyy-MM-dd',
                                  ),
                                },
                              });
                            }
                          }
                        }}
                        setTime={(value): void => {
                          handleChange({
                            target: { name: 'startTimeValue', value: convertFromEndOfDay(value) },
                          });
                        }}
                        time={values.startTimeValue}
                      />
                      <Form.Group className="form-group mb-3">
                        <TaskSchedule
                          errors={errors}
                          mode={values.scheduleMode ?? TaskMode.Once}
                          onChanged={(result): void => {
                            handleChange({
                              target: { name: 'scheduleRepeat', value: result.intervalPeriod },
                            });
                            handleChange({
                              target: { name: 'scheduleRepeatInterval', value: result.interval },
                            });
                            handleChange({
                              target: { name: 'scheduleReset', value: result.reset },
                            });
                            handleChange({
                              target: { name: 'scheduleRepeatDayOfWeek', value: result.dayOfWeek },
                            });
                            handleChange({
                              target: {
                                name: 'scheduleRepeatDayOfPeriod',
                                value: result.dayOfPeriod,
                              },
                            });

                            // if (values.scheduleMode === TaskMode.Repeat) {
                            //   const dates = computeNextTaskDate(values as Task, {
                            //     timeZone: settings.timeZone,
                            //   });
                            //   // payload.dueDate = dates?.dueDate;
                            //   handleChange({
                            //     target: {
                            //       name: 'dueDate',
                            //       value: dates?.dueDate,
                            //     },
                            //   });
                            // }

                            if (values.scheduleMode === TaskMode.Repeat) {
                              const dates = computeCurrentTaskDate(
                                {
                                  ...values,
                                  scheduleRepeat: result.intervalPeriod,
                                  scheduleRepeatInterval: result.interval,
                                  scheduleReset: result.reset,
                                  scheduleRepeatDayOfWeek: result.dayOfWeek,
                                  scheduleRepeatDayOfPeriod: result.dayOfPeriod,
                                } as any,
                                {
                                  timeZone: settings.timeZone,
                                },
                              );
                              if (dates?.dueDate) {
                                // payload.dueDate = dates?.dueDate;
                                handleChange({
                                  target: {
                                    name: 'dueDate',
                                    value: formatInTimeZone(
                                      dates?.dueDate,
                                      settings.timeZone,
                                      'yyyy-MM-dd',
                                    ),
                                  },
                                });
                              }
                            }
                          }}
                          onModeChanged={(mode): void => {
                            handleChange({ target: { name: 'scheduleMode', value: mode } });
                          }}
                          value={{
                            interval: values.scheduleRepeatInterval ?? 1,
                            intervalPeriod: values.scheduleRepeat ?? TaskRepeat.Yearly,
                            dayOfWeek: values.scheduleRepeatDayOfWeek ?? '',
                            dayOfPeriod: values.scheduleRepeatDayOfPeriod,
                            reset: values.scheduleReset ?? false,
                          }}
                        />
                      </Form.Group>
                      {values.scheduleMode === TaskMode.Repeat && (
                        <>
                          <Form.Group className="form-group mb-3" controlId="formStartDate">
                            <Form.Label>Next Iteration</Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={
                                values.dueDate
                                  ? formatInTimeZone(
                                      values.dueDate,
                                      settings.timeZone,
                                      'yyyy-MM-dd',
                                    )
                                  : ''
                              }
                            ></Form.Control>
                          </Form.Group>
                          <FormControlDate
                            date={
                              values.scheduleEndDate
                                ? formatInTimeZone(
                                    values.scheduleEndDate,
                                    settings.timeZone,
                                    'yyyy-MM-dd',
                                  )
                                : ''
                            }
                            instruction={t('The date on which this task should end')}
                            isInvalid={touched.scheduleEndDate && !!errors.scheduleEndDate}
                            isValid={touched.scheduleEndDate && !errors.scheduleEndDate}
                            label={t('End Date')}
                            setDate={(value): void => {
                              handleChange({ target: { name: 'scheduleEndDate', value } });
                            }}
                            setTime={(value): void => {
                              console.debug(`etv-`, value);
                              handleChange({
                                target: { name: 'endTimeValue', value: convertFromEndOfDay(value) },
                              });
                            }}
                            time={values.endTimeValue}
                          />
                        </>
                      )}

                      <Form.Group className="mt-2">
                        <InputGroup>
                          <InputGroup.Text>Raise priority when not completed after</InputGroup.Text>
                          <Form.Control
                            defaultValue={0}
                            // min={0}
                            onChange={(ev): void => {
                              handleChange({
                                target: { name: 'toleranceDays', value: parseInt(ev.target.value) },
                              });
                            }}
                            placeholder={`number`}
                            type="number"
                            value={values.toleranceDays}
                          />
                          <InputGroup.Text>days</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>

                      {values.scheduleMode === TaskMode.Once && (
                        <Form.Group className="form-group mb-3" controlId="formDueDate">
                          <Form.Label>{t('Due Date')}</Form.Label>
                          <ButtonGroup className="d-block">
                            <Button
                              onClick={(): void => {
                                handleChange({
                                  target: { name: 'priority', value: TaskPriority.Highest },
                                });
                                // in 1 hour
                                handleChange({
                                  target: {
                                    name: 'dueDate',
                                    value: addHours(new Date(), 1).toISOString(),
                                  },
                                });
                              }}
                              type="button"
                              variant={selectedTaskState.asap ? 'primary' : 'outline-primary'}
                            >
                              {t('taskModal|asapButtonLabel')}
                            </Button>
                            <Button
                              onClick={(): void => {
                                handleChange({
                                  target: { name: 'priority', value: TaskPriority.Medium },
                                });
                                // new Date is in the browser's tz, not site's tz.
                                const utcDate = endOfSiteDay(
                                  nowSiteTz(settings.timeZone),
                                  settings.timeZone,
                                );
                                handleChange({
                                  target: {
                                    name: 'dueDate',
                                    value: utcDate.toISOString(),
                                  },
                                });
                              }}
                              type="button"
                              variant={selectedTaskState.today ? 'primary' : 'outline-primary'}
                            >
                              {t('Today')}
                            </Button>
                            <Button
                              onClick={(): void => {
                                handleChange({
                                  target: { name: 'priority', value: TaskPriority.Medium },
                                });
                                const utcDate = addDays(
                                  endOfSiteDay(nowSiteTz(settings.timeZone), settings.timeZone),
                                  7,
                                );
                                handleChange({
                                  target: {
                                    name: 'dueDate',
                                    value: utcDate.toISOString(),
                                  },
                                });
                              }}
                              type="button"
                              variant={selectedTaskState.thisWeek ? 'primary' : 'outline-primary'}
                            >
                              {t('This Week')}
                            </Button>
                            <Button
                              onClick={(): void => {
                                handleChange({
                                  target: { name: 'priority', value: TaskPriority.Medium },
                                });
                                handleChange({
                                  target: {
                                    name: 'dueDate',
                                    value: '',
                                  },
                                });
                              }}
                              type="button"
                              variant={selectedTaskState.whenever ? 'primary' : 'outline-primary'}
                            >
                              {t('Whenever')}
                            </Button>
                          </ButtonGroup>
                          <div className="d-flex flex-row align-items-center">
                            <Form.Control
                              aria-label={t('Due Date')}
                              className="mt-2 flex-grow-1"
                              onChange={(event): void => {
                                handleChange({
                                  target: {
                                    name: 'dueDate',
                                    /** This is wrong, why GMT-0400? Should be settings.timeZone */
                                    value: endOfDay(
                                      new Date(`${event.target.value} GMT-0400`),
                                    ).toISOString(),
                                  },
                                });
                              }}
                              type={'date'}
                              value={
                                values.dueDate
                                  ? formatInTimeZone(
                                      values.dueDate,
                                      settings.timeZone,
                                      'yyyy-MM-dd',
                                    )
                                  : ''
                              }
                            />
                            {values.dueDate && (
                              <Form.Text className="text-muted ps-2 text-nowrap">
                                {formatInTimeZone(values.dueDate, settings.timeZone, 'hh:mm a')}
                              </Form.Text>
                            )}
                          </div>
                          <Form.Text className={'text-muted'}>
                            {t('The date on which this task should be completed')}
                            {/* {formatInTimeZone(
                              endOfSiteDay(new Date(), settings.timeZone), // local tz.
                              settings.timeZone,
                              'yyyy-MM-dd',
                            )} */}
                          </Form.Text>
                        </Form.Group>
                      )}
                    </CardBody>
                  </Card>

                  <Form.Group className="form-group mb-3 mt-3" controlId="formPriority">
                    <Form.Label>{t('taskTemplateModal|priorityFieldLabel')}</Form.Label>

                    <TaskPriorityPicker
                      className={'ms-2'}
                      errors={errors.priority}
                      isInvalid={touched.priority && !!errors.priority}
                      isValid={touched.priority && !errors.priority}
                      onPriorityChanged={(priority: TaskPriority): void => {
                        handleChange({ target: { name: 'priority', value: priority } });
                      }}
                      priority={values.priority ?? TaskPriority.Medium}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}></Col>
              </Row>

              <Form.Group className="form-group mb-3" controlId="formImages">
                <Form.Label>{t('taskModal|imageHeaderLabel')}</Form.Label>
                <UploadFileField
                  addLabel={t('taskModal|addImageButtonLabel')}
                  busy={busyFileUploading}
                  handleFileUpload={(ev): Promise<void> =>
                    handleFileUpload(ev, values, handleChange)
                  }
                  imageLabel={t('taskModal|imageLabel')}
                  imageUrls={asImageUrls(values.imageUrls ?? [])}
                  onShow={(): void => setShowUploadImageForm(true)}
                  removeImage={(index): void => removeImage(values, handleChange, index)}
                  removeLabel={t('taskModal|remove')}
                  show={showUploadImageForm}
                />
              </Form.Group>
              <AlertErrorForModal message={errorMessage} />
              <div className="modal-footer modal-footer-in-form">
                <ButtonModalCancel disabled={taskMutation.isLoading} onClick={props.onClose} />
                {/* <Button icon={IconCopyToClipboard} onClick={() => {}} variant="outline-primary">
                  Copy Link
                </Button> */}
                <CopyToClipboard
                  text={`${process.env.REACT_APP_FRONTEND_URI}/task/${props.task?.id}`}
                >
                  <Button
                    icon={IconCopyToClipboard}
                    label={`Copy Link`}
                    type={'button'}
                    variant="outline-primary"
                  />
                </CopyToClipboard>
                <Button
                  icon={IconView}
                  onClick={(): void => {
                    history.push(`/task/${props.task?.id}`);
                  }}
                  variant="outline-primary"
                >
                  View
                </Button>
                <ButtonModalSave
                  busy={taskMutation.isLoading}
                  className="ms-auto"
                  disabled={taskMutation.isLoading}
                />
                {/* {JSON.stringify(errors)} */}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
