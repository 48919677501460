// Copyright © 2024 CATTLEytics Inc.

import { useMemo } from 'react';

import { useAuth } from '../store/useAuth';
import { isSiteAdminOrAbove } from '../utilities';

export const useIsAuthUserSiteAdminOrAbove = (): boolean => {
  const auth = useAuth();

  return useMemo(() => isSiteAdminOrAbove(auth), [auth]);
};
