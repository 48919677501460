// Copyright © 2023 CATTLEytics Inc.

import { format, parseISO } from 'date-fns';
import { useInjection } from 'inversify-react';
import React, { ChangeEvent, useState } from 'react';
import { Alert, Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../../types';
import AnimalAutocomplete from '../../../animals/components/AnimalAutocomplete';
import AlertErrorForModal from '../../../common/components/AlertErrorForModal';
import BirthStatusSelect from '../../../common/components/BirthStatusSelect';
import BreedSelect from '../../../common/components/BreedSelect';
import Button from '../../../common/components/Button';
import ButtonModal from '../../../common/components/ButtonModal';
import ButtonModalCancel from '../../../common/components/ButtonModalCancel';
import GenderSelect from '../../../common/components/GenderSelect';
import ImageUploaded from '../../../common/components/ImageUploaded';
import NoImageUploaded from '../../../common/components/NoImageUploaded';
import PenSelect from '../../../common/components/PenSelect';
import RandomName from '../../../common/components/RandomName';
import Required from '../../../common/components/Required';
import AnimalEvent from '../../../common/entities/animalEvent';
import AnimalHealthCheck from '../../../common/entities/animalHealthCheck';
import {
  AnimalEventType as AnimalEventTypeEnum,
  AnimalReproductionStatus,
} from '../../../common/enums';
import { useNextPrimaryTag } from '../../../common/hooks';
import { useFileService } from '../../../common/hooks/useFileService';
import { useFocusField } from '../../../common/hooks/useFocusField';
import AnimalEventService from '../../../common/services/animalEventService';
import AnimalHealthCheckService from '../../../common/services/animalHealthCheckService';
import UserService from '../../../common/services/userService';
import { useAuth } from '../../../common/store/useAuth';
import { IconCreate, IconDelete, IconSave, IconWarning } from '../../../common/utilities';
import { ImageSize, resizeImage } from '../../../common/utilities/imageResize';
import { Animal, User } from '../../../shared';
import { QueryKey } from '../../../shared/enums';
import UserAutocomplete from '../../../users/components/UserAutocomplete';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Default animal to select.
   */
  animal?: Animal;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * ID of event to edit in this modal.
   */
  eventId?: number;

  /**
   * Callback when form is cancelled.
   */
  onCancel?: () => void;

  /**
   * Callback when form has been submitted successfully..
   */
  onSubmitSuccess?: () => void;
}

interface Payload {
  [key: string]: any | undefined;
  animalEventTypeId: number;
  animalIds: number[];
  calfBreedId: number;
  calvingAssistance: string;
  calvingDifficulty: string;
  calvingDueDate: string;
  eventDateTime: string;
}

interface PayloadCalf {
  birthStatusId: number;
  genderId: number;
  healthChecks: Record<number, boolean>;
  imageUrl: string;
  imageUrlSigned: string;
  name: string;
  penId: number;
  primaryTag: string;
  totalProtein: string;
}

/**
 * Form for moving animals between pens. For use in event modal component.
 */
const CalvingForm = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const eventService = useInjection<AnimalEventService>(TYPES.animalEventService);
  const healthCheckService = useInjection<AnimalHealthCheckService>(TYPES.animalHealthCheckService);
  const userService = useInjection<UserService>(TYPES.userService);
  const fileService = useFileService();

  const history = useHistory();

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // fields
  const [calvingDifficulty, setCalvingDifficulty] = useState<string>('easy');
  const [calvingAssistance, setCalvingAssistance] = useState<string>('none');
  const [calfBreedId, setCalfBreedId] = useState<string>('');
  const [calves, setCalves] = useState<PayloadCalf[]>([]);
  const [dueDate, setDueDate] = useState<string>('');
  const [dam, setDam] = useState<Animal | undefined>(props.animal);
  const [eventDateTime, setEventDateTime] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [notes, setNotes] = useState<string>('');
  const [user, setUser] = useState<User>();
  const [busyFileUploading, setBusyFileUploading] = useState<boolean>(false);
  const [_, setImageUrl] = useState<string>('');
  const [imageUrlSigned, setImageUrlSigned] = useState<string>('');

  const { nextPrimaryTag, incrementNextPrimaryTag } = useNextPrimaryTag();

  const [showAddBirthStatusModal, setShowAddBirthStatusModal] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showAddGenderModal, setShowAddGenderModal] = useState<boolean>(false);
  const [showAddPenModal, setShowAddPenModal] = useState<boolean>(false);

  const auth = useAuth();

  useQuery<User | undefined>(
    [QueryKey.Users, auth.userId],
    () => userService.get(auth.userId as number),
    {
      onSuccess: (user) => {
        if (user) {
          setUser(user);
        }
      },
    },
  );

  const healthCheckQuery = useQuery<AnimalHealthCheck[] | undefined>(
    [QueryKey.AnimalHealthChecks],
    () => healthCheckService.list({ type: 'calf' }),
  );

  const healthCheckData = healthCheckQuery.data ?? [];

  const mutation = useMutation<AnimalEvent[] | undefined, unknown, Payload>(
    (payload) => {
      // @TODO allow editing
      // if (props.eventId) {
      //return eventService.patch(payload.id, payload);
      //} else {
      return eventService.post(payload);
      //}
    },
    {
      onSuccess: async () => {
        // Invalidate and re-fetch
        await queryClient.invalidateQueries(QueryKey.AnimalEvents);
        await queryClient.invalidateQueries(QueryKey.Animals);
      },
    },
  );

  const addCalf = (): void => {
    const newCalves = [...calves];
    newCalves.push({
      name: '',
      genderId: 1,
      birthStatusId: 1,
      healthChecks: {},
      primaryTag: nextPrimaryTag,
      penId: 1,
      totalProtein: '',
      imageUrl: '',
      imageUrlSigned: '',
    });
    incrementNextPrimaryTag();
    setCalves(newCalves);
  };

  const removeCalf = (): void => {
    const newCalves = [...calves];
    newCalves.pop();
    setCalves(newCalves);
  };

  const handleFileUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ): Promise<void> => {
    const file = ((event.target as HTMLInputElement).files as FileList)[0];
    setBusyFileUploading(true);
    await uploadFile(await resizeImage(file, ImageSize.Small), index);
    setBusyFileUploading(false);
  };

  const uploadFile = async (file: File, index: number): Promise<void> => {
    const fileResult = await fileService.upload(file);
    if (fileResult.signedUrl) {
      const newCalves = [...calves];
      calves[index].imageUrl = fileResult.key;
      calves[index].imageUrlSigned = fileResult.signedUrl;
      setImageUrlSigned(fileResult.signedUrl);
      setImageUrl(fileResult.key);
      setCalves(newCalves);
    }
  };

  useFocusField({ validated });

  const queryClient = useQueryClient();

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (dam === undefined) {
      return;
    }

    if (!valid) {
      return;
    }

    if (calves.length === 0) {
      return;
    }

    const payload: Payload = {
      animalIds: [(dam as Animal).id],
      animalEventTypeId: AnimalEventTypeEnum.Calving,
      eventDateTime: parseISO(eventDateTime).toISOString(),
      calfBreedId: Number(calfBreedId),
      calvingDueDate: parseISO(dueDate).toISOString(),
      calvingDifficulty: calvingDifficulty,
      calvingAssistance: calvingAssistance,
      calves: calves,
      user: user ? user.id : undefined,
      notes: notes,
    };
    setErrorMessage('');
    try {
      const events = await mutation.mutateAsync(payload);
      if (props.onSubmitSuccess) {
        props.onSubmitSuccess();
      }
      if (events && events.length > 0) {
        const animalId = events[0].animal?.id;
        history.push(`/animals/${animalId}/calves`);
      }
    } catch (err) {
      setErrorMessage(t('common|eventSaveError'));
    }
  };

  const fields = (
    <>
      <Row>
        <Col md={4}>
          <Form.Group className="form-group mb-3" controlId="formEventDate">
            <Form.Label>
              {t('Dam')} <Required />
            </Form.Label>
            <AnimalAutocomplete
              id={'formDam'}
              multiple={false}
              onSelect={(selected): void => {
                if (!selected) {
                  return setDam(undefined);
                } else if (Array.isArray(selected)) {
                  return;
                }
                setDam(selected);
                if (selected?.calvingDate) {
                  setDueDate(selected.calvingDate);
                }
              }}
              required={true}
              selected={dam}
              validated={validated}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="form-group mb-3" controlId="formDueDate">
            <Form.Label>
              {t('Due Date')} <Required />
            </Form.Label>
            <Form.Control
              aria-label={t('Due Date')}
              max={new Date(2100, 1, 1).toISOString().split('T')[0]}
              onChange={(event): void => setDueDate(event.target.value)}
              required={true}
              type={'date'}
              value={dueDate}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="form-group mb-3" controlId="formCalvingDate">
            <Form.Label>
              {t('Calving Date')} <Required />
            </Form.Label>
            <Form.Control
              aria-label={t('Calving Date')}
              max={new Date(2100, 1, 1).toISOString().split('T')[0]}
              onChange={(event): void => setEventDateTime(event.target.value)}
              required={true}
              type={'date'}
              value={eventDateTime}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {dam && dam?.reproductionStatus !== AnimalReproductionStatus.Pregnant && (
        <Alert variant={'warning'}>
          <IconWarning /> {t('calvingForm|animalNotPregnantWarning')}
        </Alert>
      )}
      <Row>
        <Col md={4}>
          <Form.Group className="form-group mb-3" controlId="formCalfBreed">
            <Form.Label>
              {t('Calf Breed')} <Required />
            </Form.Label>
            <BreedSelect
              aria-label={t('Calf Breed')}
              name="calfBreed"
              onChange={(e): void => setCalfBreedId(e.target.value)}
              required
              value={calfBreedId}
            />
            <Form.Control.Feedback type="invalid">
              {t('calvingForm|selectBreedFeedback')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="form-group mb-3" controlId="formCalvingDifficulty">
            <Form.Label>
              {t('Calving Difficulty')} <Required />
            </Form.Label>

            <Form.Select
              aria-label={t('Calving Difficulty')}
              name={'calvingDifficulty'}
              onChange={(e): void => setCalvingDifficulty(e.target.value)}
              required
              value={calvingDifficulty}
            >
              <option value="unassisted">{t('Unassisted')}</option>
              <option value="hand-assisted">{t('Hand Assisted')}</option>
              <option value="block-and-tackle-assisted">{t('Block and Tackle Assisted')}</option>
              <option value="vet-assisted">{t('Vet Assisted')}</option>
              <option value="surgery">{t('Surgery')}</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="form-group mb-3" controlId="formCalvingAssistance">
            <Form.Label>
              {t('Calving Assistance')} <Required />
            </Form.Label>

            <Form.Select
              aria-label={t('Calving Assistance')}
              name={'calvingAssistance'}
              onChange={(e): void => setCalvingAssistance(e.target.value)}
              required
              value={calvingAssistance}
            >
              <option value="none">{t('None')}</option>
              <option value="vet">{t('Vet')}</option>
              <option value="producer">{t('Producer')}</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      {calves.map((calf, index) => (
        <div key={index}>
          <h2>{t('Calf #{{value}}', { value: index + 1 })}</h2>
          <Row>
            <Col md={8}>
              <RandomName
                className={'mb-3'}
                gender={String(calves[index].genderId)}
                name={'name'}
                onChange={(e): void => {
                  const newCalves = [...calves];
                  calves[index].name = e.target.value;
                  setCalves(newCalves);
                }}
                onRandomName={(name): void => {
                  const newCalves = [...calves];
                  calves[index].name = name;
                  setCalves(newCalves);
                }}
                value={calves[index].name}
              />
            </Col>
            <Col md={4}>
              <Form.Group className="form-group mb-3" controlId={`formCalfGender${index}`}>
                <Form.Label>
                  {t('Gender')} <Required />
                </Form.Label>
                <GenderSelect
                  name={'genderId'}
                  onChange={(e): void => {
                    const newCalves = [...calves];
                    calves[index].genderId = Number(e.target.value);
                    setCalves(newCalves);
                  }}
                  onCloseAddModal={(): void => setShowAddGenderModal(false)}
                  required
                  showAddModal={showAddGenderModal}
                  value={String(calves[index].genderId)}
                />
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group className="form-group mb-3" controlId="formPhoto">
                <Form.Label>{t('Photo')}</Form.Label>
                <Form.Control
                  name={'imageUrl'}
                  onChange={(e): void => {
                    handleFileUpload(e as ChangeEvent<HTMLInputElement>, index);
                  }}
                  type="file"
                />
                <Form.Text className="text-muted">
                  {t('calvingForm|uploadAnimalPhotoText')}
                </Form.Text>
                {imageUrlSigned ? (
                  <p className={'mt-3'}>
                    <Button
                      onClick={(): void => {
                        setImageUrl('');
                        setImageUrlSigned('');
                      }}
                      size={'sm'}
                      variant={'outline-secondary'}
                    >
                      {t('Remove Image')}
                    </Button>
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={4} style={{ marginBottom: 20 }}>
              {!busyFileUploading && imageUrlSigned ? (
                <ImageUploaded alt={t('Animal image')} src={imageUrlSigned} />
              ) : null}
              {!busyFileUploading && !imageUrlSigned ? <NoImageUploaded /> : null}
              {busyFileUploading ? (
                <div
                  className={
                    'text-muted border rounded d-flex justify-content-center align-items-center'
                  }
                  style={{ width: '150px', height: '150px' }}
                >
                  <Spinner />
                </div>
              ) : null}
            </Col>
            <Col md={4}>
              <Form.Group className="form-group mb-3" controlId={`formCalfStatus${index}`}>
                <Form.Label>
                  {t('Status')} <Required />
                </Form.Label>
                <BirthStatusSelect
                  firstItemDefault
                  onChange={(e): void => {
                    const newCalves = [...calves];
                    calves[index].birthStatusId = Number(e);
                    setCalves(newCalves);
                  }}
                  onCloseAddModal={(): void => setShowAddBirthStatusModal(false)}
                  showAddModal={showAddBirthStatusModal}
                  value={calves[index].birthStatusId}
                />
                {/*<Form.Select*/}
                {/*  aria-label={t('Status')}*/}
                {/*  name={`calfStatus${index}`}*/}
                {/*  onChange={(e): void => {*/}
                {/*    const newCalves = [...calves];*/}
                {/*    calves[index].birthStatusId = Number(e.target.value);*/}
                {/*    setCalves(newCalves);*/}
                {/*  }}*/}
                {/*  value={calves[index].birthStatusId}*/}
                {/*>*/}
                {/*  <option value="alive">{t('Alive')}</option>*/}
                {/*  <option value="dead">{t('Dead')}</option>*/}
                {/*  <option value="stillborn">{t('Stillborn')}</option>*/}
                {/*</Form.Select>*/}
                <Form.Text className="text-muted">
                  {t("What is the calf's birth status?")}
                </Form.Text>
                <Form.Text className="text-muted">
                  <span>{t('Choose a birth status from the dropdown or')} </span>
                  <Button
                    onClick={(): void => setShowAddBirthStatusModal(true)}
                    size={'sm'}
                    variant={'link'}
                  >
                    {t('add birth status')}
                  </Button>
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="form-group mb-3" controlId="formPen">
                <Form.Label>
                  {t('Pen')} <Required />
                </Form.Label>
                <PenSelect
                  firstItemDefault
                  name={`penId${index}`}
                  onChange={(penId): void => {
                    const newCalves = [...calves];
                    calves[index].penId = Number(penId);
                    setCalves(newCalves);
                  }}
                  onCloseAddModal={(): void => setShowAddPenModal(false)}
                  required={true}
                  showAddModal={showAddPenModal}
                  value={String(calves[index].penId)}
                />
                <Form.Text className="text-muted">
                  <span>{t('Choose a pen from the dropdown or')} </span>
                  <Button
                    onClick={(): void => setShowAddPenModal(true)}
                    size={'sm'}
                    variant={'link'}
                  >
                    {t('add pen')}
                  </Button>
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="form-group mb-3" controlId={`formCalfPrimaryTag${index}`}>
                <Form.Label>
                  {t('Primary Tag')} <Required />
                </Form.Label>
                <Form.Control
                  aria-label={t('Primary Tag')}
                  name={`calfPrimaryTag${index}`}
                  onChange={(e): void => {
                    const newCalves = [...calves];
                    calves[index].primaryTag = e.target.value;
                    setCalves(newCalves);
                  }}
                  required={true}
                  type={'string'}
                  value={calves[index].primaryTag}
                />
                <Form.Text className="text-muted">{t('calvingForm|calfPrimaryTag')}</Form.Text>
                <Form.Control.Feedback type={'invalid'}>
                  {t('common|fieldRequiredFeedback')}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="form-group mb-3" controlId={`formCalfTotalProtein${index}`}>
                <Form.Label>{t('Total Protein')}</Form.Label>
                <Form.Control
                  aria-label={t('Total Protein')}
                  name={`calfTotalProtein${index}`}
                  onChange={(e): void => {
                    const newCalves = [...calves];
                    calves[index].totalProtein = e.target.value;
                    setCalves(newCalves);
                  }}
                  type={'number'}
                  value={calves[index].totalProtein}
                />
                <Form.Text className="text-muted">
                  {t('calvingForm|amtOfProteinDescription')}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Form.Label>{t('Health Check')}</Form.Label>
          <Form.Group className="form-group mb-3" controlId={`formCalfHealthCheck${index}`}>
            {healthCheckData.map((healthCheck, indexHC) => (
              <Form.Check
                aria-label={healthCheck.name}
                checked={calves[index].healthChecks[healthCheck.id] ?? false}
                id={`formCalfHealthCheck-${index}-${healthCheck.id}`}
                inline
                key={healthCheck.id}
                label={healthCheck.name}
                name={`calfHealthCheck-${index}-${indexHC}`}
                onChange={(e): void => {
                  const newCalves = [...calves];
                  if (!calves[index].healthChecks[healthCheck.id]) {
                    newCalves[index].healthChecks[healthCheck.id] = false;
                  }
                  newCalves[index].healthChecks[healthCheck.id] = e.target.checked;
                  setCalves(newCalves);
                }}
              />
            ))}
          </Form.Group>
          <Form.Group className={'mb-3'}>
            <Button onClick={(): void => removeCalf()} size={'sm'} variant={'outline-primary'}>
              <IconDelete /> {t('Remove Calf')}
            </Button>
          </Form.Group>
        </div>
      ))}
      <Form.Group className={'mb-3'}>
        <Button onClick={(): void => addCalf()} size={'sm'} variant={'outline-primary'}>
          <IconCreate /> {t('calvingForm|addCalfButtonLabel')}
        </Button>
        {validated && calves.length === 0 && (
          <div className=" invalid-feedback" style={{ display: 'block' }}>
            {t('At least 1 calf is required')}
          </div>
        )}
      </Form.Group>
      <Form.Group className="form-group mb-3" controlId="formUser">
        <Form.Label>{t('User')}</Form.Label>
        <UserAutocomplete
          id={'formUser'}
          multiple={false}
          name={'userId'}
          onCloseAddModal={(): void => setShowAddUserModal(false)}
          onSelect={(selected): void => {
            // since multiple is not set we can assume we are going to either get undefined or Animal
            if (!selected || Array.isArray(selected)) {
              // selected is undefined (can't actually not be an array because of multiple prop)
              return setUser(undefined);
            }
            setUser(selected);
          }}
          required={false}
          selected={user}
          showAddModal={showAddUserModal}
          validated={validated}
        />
      </Form.Group>
      <FormGroup className="mb-3" controlId="formNotes">
        <Form.Label>{t('Notes')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'notes'}
          onChange={(event): void => setNotes(event.target.value)}
          rows={4}
          value={notes}
        />
        <Form.Text className={'text-muted'}>{t('common|eventDescriptionInstructions')}</Form.Text>
      </FormGroup>
    </>
  );

  return (
    <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
      {fields}
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel disabled={mutation.isLoading} onClick={props.onCancel} />
        <ButtonModal
          busy={mutation.isLoading}
          disabled={mutation.isLoading}
          icon={IconSave}
          label={'Save'}
          type="submit"
        />
      </div>
    </Form>
  );
};

export default CalvingForm;
