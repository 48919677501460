// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Page from '../../common/components/Page';
import { useAuth } from '../../common/store/useAuth';
import { IconSuperAdmin, isSuperAdmin } from '../../common/utilities';
import { AiTab } from './AiTab';
import AnimalsTab from './AnimalsTab';
import DataImportTab from './DataImportTab';
import { FeatureFlagsTab } from './FeatureFlagsTab';
import GeneralTab from './GeneralTab';
import LocalizationTab from './LocalizationTab';
import { ReportsEmbeddedTab } from './ReportEmbeddedTab';
import ScheduleTab from './ScheduleTab';

enum TabKeys {
  Ai = 'ai',
  Animals = 'animals',
  DataImport = 'dairy-comp',
  FeatureFlags = 'feature-flags',
  GeneralTab = 'general',
  Localization = 'localization',
  ReportsEmbedded = 'reports-embedded',
  Schedule = 'schedule',
}

interface RouteParams {
  tabKey: string;
}

/**
 * Routable component for settings.
 */
const SettingsPage = (): JSX.Element => {
  const { t } = useTranslation();

  const auth = useAuth();

  const { tabKey: key } = useParams<RouteParams>();

  const history = useHistory();

  return (
    <Page title={t('Settings')}>
      <Tabs
        activeKey={key}
        className="mb-3"
        id="nav-settings"
        onSelect={(k): void => {
          history.push(`/settings/${k}`);
        }}
      >
        <Tab eventKey={TabKeys.GeneralTab} title={t('General')}>
          <GeneralTab />
        </Tab>

        <Tab eventKey={TabKeys.Localization} title={t('Localization')}>
          <LocalizationTab />
        </Tab>

        <Tab eventKey={TabKeys.Animals} title={t('Animals')}>
          <AnimalsTab />
        </Tab>
        <Tab eventKey={TabKeys.Schedule} title={t('Scheduling')}>
          <ScheduleTab />
        </Tab>

        {isSuperAdmin(auth) && (
          <Tab
            eventKey={TabKeys.DataImport}
            title={
              <>
                <IconSuperAdmin />
                <span className="p-1">{t('Data Import')}</span>
              </>
            }
          >
            <DataImportTab />
          </Tab>
        )}
        {isSuperAdmin(auth) && (
          <Tab
            eventKey={TabKeys.Ai}
            title={
              <>
                <IconSuperAdmin />
                <span className="p-1">{t('AI')}</span>
              </>
            }
          >
            <AiTab />
          </Tab>
        )}
        {isSuperAdmin(auth) && (
          <Tab
            eventKey={TabKeys.FeatureFlags}
            title={
              <>
                <IconSuperAdmin />
                <span className="p-1">{t('Feature Flags')}</span>
              </>
            }
          >
            <FeatureFlagsTab />
          </Tab>
        )}
        {isSuperAdmin(auth) && (
          <Tab
            eventKey={TabKeys.ReportsEmbedded}
            title={
              <>
                <IconSuperAdmin />
                <span className="p-1">{t('Reports Embedded')}</span>
              </>
            }
          >
            <ReportsEmbeddedTab />
          </Tab>
        )}
      </Tabs>
    </Page>
  );
};

export default SettingsPage;
