// Copyright © 2024 CATTLEytics Inc.

import { useState } from 'react';
import { Badge, ButtonGroup, Dropdown, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import { Sort } from '../../common/enums';
import { useSettingsContext } from '../../common/store/useSettingsContext';
import { api, IconDelete, IconEdit, IconLink, IconPriorityHighest } from '../../common/utilities';
import {
  ApiResourceV1,
  HttpMethod,
  QueryKey,
  TaskCategory,
  TaskPriority,
  TaskTemplate,
} from '../../shared';
import { ProtocolModal } from './ProtocolModal';
import { TaskCategoryBadge } from './TaskCategoryBadge';
import { trimTo, WithCount } from './utilities';

export interface TaskTemplateTableFilters {
  categories?: TaskCategory[];
  search?: string;
}

export interface TaskTemplateTableProps {
  filterByCategoryId?: number;
  filters?: TaskTemplateTableFilters;
  initialLimit?: number;
  onlyCompleted?: boolean;

  showCompleted?: boolean;
}

function queryParams(options: {
  filterByCategoryId?: number;
  filters?: TaskTemplateTableFilters;
  limit: number;
  onlyCompleted?: boolean;
  showCompleted?: boolean;
  sortDirection: Sort;
  sortField: string;
  timeZone: string;
}): Record<string, string> {
  const items: Record<string, string> = {
    limit: String(options.limit),
    sortField: options.sortField,
    sortDirection: options.sortDirection,
  };
  if (options.filterByCategoryId) {
    items.categoryIds = String(options.filterByCategoryId);
  }

  if (options.onlyCompleted) {
    items.onlyCompleted = '1';
  }

  items.showCompleted = options.showCompleted ? '1' : '0';

  if (options.filters) {
    if (options.filters.categories && options.filters.categories.length > 0) {
      items.categoryIds = options.filters.categories.map((c) => String(c.id)).join(',');
    }
    if (options.filters.search) {
      // search the description
      items.search = options.filters.search;
    }
  }

  return items;
}

export function ProtocolTable(props: TaskTemplateTableProps): JSX.Element {
  // fill in with a table of tasks instead of a list.
  const settings = useSettingsContext();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<string>('title');
  const [sortDirection, setSortDirection] = useState<Sort>(Sort.Ascending);
  const [limit, setLimit] = useState<number>(props.initialLimit ?? 100);

  const taskTemplatesQuery = useQuery<WithCount<TaskTemplate>>(
    [
      QueryKey.TaskTemplates,
      limit,
      sortField,
      sortDirection,
      props.filterByCategoryId,
      props.showCompleted,
      props.onlyCompleted,
      props.filters,
    ],
    () =>
      api<WithCount<TaskTemplate>>(HttpMethod.Get, ApiResourceV1.TaskTemplates, {
        params: queryParams({
          limit,
          sortField,
          sortDirection,
          filterByCategoryId: props.filterByCategoryId,
          showCompleted: props.showCompleted,
          onlyCompleted: props.onlyCompleted,
          filters: props.filters,
          timeZone: settings.timeZone,
        }),
      }),
    {
      keepPreviousData: true,
    },
  );

  const [taskTemplateModalVisible, setTaskTemplateModalVisible] = useState<boolean>(false);
  const [taskDeleteModalVisible, setTaskDeleteModalVisible] = useState<boolean>(false);
  const [taskTemplateIdToDelete, setTaskTemplateIdToDelete] = useState<number>();
  const [taskModalTaskTemplate, setTaskModalTaskTemplate] = useState<TaskTemplate>();

  const deleteTemplateTask = useMutation(
    () => {
      if (taskTemplateIdToDelete) {
        return api(HttpMethod.Delete, `${ApiResourceV1.TaskTemplates}/${taskTemplateIdToDelete}`);
      } else {
        return new Promise<null>((resolve) => resolve(null));
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.Tasks);
      },
    },
  );

  const headers: DataTableHeader[] = [
    {
      name: 'link',
      label: 'URL',
      className: 'flex-grow-1',
      colClassName: 'align-content-baseline text-nowrap',
    },
    {
      name: 'title',
      label: 'Title',
      className: 'flex-grow-1',
      colClassName: 'align-content-baseline text-nowrap',
    },
    {
      name: 'description',
      label: 'Description',
      className: 'flex-grow-1',
      colClassName: 'align-content-baseline',
    },
    {
      name: 'instructions',
      label: 'Instructions',
      className: 'flex-grow-1',
      colClassName: 'align-content-baseline',
    },
    {
      name: 'taskCategoryId',
      label: 'Category',
      colClassName: 'align-content-baseline',
    },
    {
      name: 'actions',
      label: 'Actions',
      colClassName: '',
      sortable: false,
      clickable: false,
      includesButtons: true,
    },
  ] as DataTableHeader[];

  const showModal = (taskTemplate: TaskTemplate): void => {
    setTaskTemplateModalVisible(true);
    setTaskModalTaskTemplate(taskTemplate);
  };

  const showDeleteModal = (task: TaskTemplate): void => {
    setTaskDeleteModalVisible(true);
    setTaskTemplateIdToDelete(task.id);
  };

  const validData =
    taskTemplatesQuery.data && taskTemplatesQuery.data.items.length > 0
      ? taskTemplatesQuery.data.items.map((taskTemplate) => {
          return {
            link: (
              <a
                href={`/protocols/${taskTemplate.id}`}
                onClick={(ev): void => {
                  ev.stopPropagation();
                  // prevents the onRowClick from firing.
                }}
              >
                <IconLink className="me-2" />
                Link
              </a>
            ),
            id: String(taskTemplate.id),
            title: <strong>{taskTemplate.title}</strong>,
            instructions: trimTo(taskTemplate.instructions, 150),
            description: trimTo(taskTemplate.description ?? '', 150),
            taskCategoryId: (
              <>
                {taskTemplate.taskCategory && (
                  <TaskCategoryBadge category={taskTemplate.taskCategory} />
                )}
                {taskTemplate.animalId && (
                  <Badge bg={'primary'} className={'me-3'} pill>
                    {t('taskTable|animalBadge')}
                  </Badge>
                )}
                {taskTemplate.priority === TaskPriority.Highest && (
                  <IconPriorityHighest
                    className={'me-2'}
                    style={{
                      color: 'var(--bs-danger)',
                    }}
                  />
                )}
              </>
            ),

            actions: (
              <Stack className="flex-nowrap" direction="horizontal" gap={2}>
                <Dropdown as={ButtonGroup}>
                  <Button
                    icon={IconEdit}
                    label={t('Edit')}
                    onClick={(ev): void => {
                      showModal(taskTemplate);

                      ev.preventDefault();
                      ev.stopPropagation();
                    }}
                    size={'sm'}
                    variant={ButtonVariant.OutlinePrimary}
                  />
                  <Dropdown.Toggle size="sm" split variant="outline-primary" />
                  <Dropdown.Menu align="end" flip>
                    <Dropdown.Item
                      className={'flex-shrink-1'}
                      onClick={(ev): void => {
                        showDeleteModal(taskTemplate);
                        ev.preventDefault();
                        ev.stopPropagation();
                      }}
                    >
                      <IconDelete className="me-2" />
                      {t('Delete')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={(ev): void => {
                        showModal(taskTemplate);

                        ev.preventDefault();
                        ev.stopPropagation();
                      }}
                    >
                      <IconEdit className="me-2" />
                      {t('Edit')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(ev): void => {
                        // showModal(taskTemplate);
                        history.push(`/protocols/${taskTemplate.id}`);

                        ev.preventDefault();
                        ev.stopPropagation();
                      }}
                    >
                      <IconEdit className="me-2" />
                      {t('View')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Stack>
            ),
          } as DataTableRow;
        })
      : [];

  return (
    <>
      <DataTable
        data={validData}
        headers={headers}
        isLoading={taskTemplatesQuery.isLoading}
        limit={limit}
        onLimitChange={(limit): void => {
          setLimit(limit);
        }}
        onRowClick={(row): void => {
          const rowId = row.id;
          const item = taskTemplatesQuery.data?.items?.find(
            (taskTemplate) => String(taskTemplate.id) === String(rowId),
          );
          if (item) {
            showModal(item);
          }
        }}
        onSortDirectionChange={(direction): void => {
          setSortDirection(direction);
        }}
        onSortFieldChange={(field): void => {
          setSortField(field);
        }}
        sortDirection={sortDirection}
        sortField={sortField}
      />

      {taskTemplateModalVisible && (
        <ProtocolModal
          onClose={(): void => {
            setTaskTemplateModalVisible(false);
            setTaskModalTaskTemplate(undefined);
          }}
          taskTemplate={taskModalTaskTemplate}
        />
      )}
      {taskDeleteModalVisible && (
        <ConfirmModal
          busy={deleteTemplateTask.isLoading}
          cancelOnClick={(): void => setTaskDeleteModalVisible(false)}
          okOnClick={async (): Promise<void> => {
            await deleteTemplateTask.mutateAsync();
            setTaskDeleteModalVisible(false);
          }}
          title={'Delete task template'}
          visible={true}
        >
          {t('Are you sure you want to delete this template?')}
        </ConfirmModal>
      )}
    </>
  );
}
