// Copyright © 2025 CATTLEytics Inc.

import { groupBy } from 'lodash';

import { IconReport } from '../../common/utilities';
import { ReportEmbedded } from '../../shared';
import { isTruthy } from '../../shared/utilities/boolean';
import { useFeatureFlags } from './useFeatureFlags';
import { useSettings } from './useSettings';

export interface LinkDef {
  disabled?: boolean;
  eventKey: string;
  icon?: React.ReactNode;
  label: string;
  link: string;
  subItems?: LinkDef[];
}

interface UseReportsResult {
  enabled: boolean;
  reports: LinkDef;
}

export function useNavReports(): UseReportsResult {
  const ff = useFeatureFlags();
  const settings = useSettings<{ reports: ReportEmbedded[] }>({ key: 'reports-embedded' });

  const groupedItems = groupBy(settings.data?.reports, (item) => item.reportGroup);

  const asLinks = (name: string): LinkDef[] => {
    return (groupedItems[name] ?? []).map((row) => {
      return {
        eventKey: `${row.reportGroup}-${row.reportName}`,
        label: row.name,
        link: `/reports/embedded/${row.reportGroup}/${row.reportName}`,
      } as LinkDef;
    });
  };

  const reportItems: LinkDef[] = [
    {
      label: 'Past',
      eventKey: '',
      link: '/',
      disabled: true,
      subItems: asLinks('past'),
    },
    {
      label: 'Present',
      eventKey: '',
      link: '/',
      disabled: true,
      subItems: asLinks('present'),
    },
    {
      label: 'Future',
      eventKey: '',
      link: '/',
      disabled: true,
      subItems: asLinks('future'),
    },
    {
      label: 'Sandbox',
      eventKey: '',
      link: '/',
      disabled: true,
      subItems: asLinks('sandbox'),
    },
  ];

  return {
    enabled: isTruthy(ff['Reports/Embedded']),
    reports: {
      label: 'Reports',
      icon: <IconReport />,
      eventKey: 'reports',
      link: '/',
      subItems: reportItems,
    },
  };
}
