// Copyright © 2023 CATTLEytics Inc.
import './Schedules.scss';

import { useCallback, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import Button from '../common/components/Button';
import { useAuth } from '../common/store/useAuth';
import { isSiteAdminOrAbove } from '../common/utilities';
import { QueryKey, Shift, User, UserSchedule } from '../shared';
import { UserAutocomplete } from '../users/components/UserAutocomplete';
import { ShiftAutocomplete } from './components/ShiftAutocomplete';
import { ShiftScheduleButton } from './components/ShiftScheduleButton';
import { ManageSchedulesModal } from './ManageSchedulesModal';
import { ManageShiftsModal } from './ManageShiftsModal';
import { SchedulesCalendar } from './SchedulesCalendar';
import { ScheduleUserModal } from './ScheduleUserModal';

function Schedules(): JSX.Element {
  const { t } = useTranslation();
  const [showScheduleUser, setShowScheduleUser] = useState(false);
  const [showManageShifts, setShowManageShifts] = useState(false);
  const [showManageSchedules, setShowManageSchedules] = useState(false);
  const [selectedManageSchedules, setSelectedManageSchedules] = useState<UserSchedule[]>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const queryClient = useQueryClient();
  const auth = useAuth();

  const [users, setUsers] = useState<Partial<User>[] | undefined>(
    isSiteAdminOrAbove(auth) ? [] : undefined,
  );
  const [shifts, setShifts] = useState<Shift[] | undefined>([]);

  const refreshCalender = useCallback(
    () => queryClient.invalidateQueries(QueryKey.UserSchedules),
    [queryClient],
  );

  const onCloseScheduleModal = useCallback(() => {
    setShowScheduleUser(false);
    setSelectedDate(undefined);
  }, []);

  const onCloseManageShift = useCallback(() => {
    setShowManageShifts(false);
    queryClient.invalidateQueries(QueryKey.UserSchedules);
  }, [queryClient]);

  const onSelectedSchedule = useCallback(
    (date: Date) => {
      if (isSiteAdminOrAbove(auth)) {
        setSelectedDate(date);
        setShowScheduleUser(true);
      }
    },
    [auth, setSelectedDate, setShowScheduleUser],
  );

  const onManageSchedules = useCallback(
    (schedules: UserSchedule[]) => {
      if (isSiteAdminOrAbove(auth)) {
        setShowManageSchedules(true);
        setSelectedManageSchedules(schedules);
      } else {
        const userSchedule = schedules.find((s) => s.userId === auth.userId);
        if (userSchedule) {
          setShowManageSchedules(true);
          // limit to the logged in user's schedule.
          setSelectedManageSchedules([userSchedule]);
        }
      }
      // else show swap menu?
    },
    [auth, setShowManageSchedules, setSelectedManageSchedules],
  );

  const onManageSchedulesClose = useCallback(() => {
    refreshCalender();
    setShowManageSchedules(false);
    setSelectedManageSchedules(undefined);
  }, [setShowManageSchedules, setSelectedManageSchedules, refreshCalender]);

  const onUpdateSelectedSchedules = useCallback((data: UserSchedule[]) => {
    if (data && data.length > 0) {
      setSelectedManageSchedules((prev) => {
        const next = prev
          ? prev?.map((s) => {
              const updated = data.find((d) => d.id === s.id);
              return updated ? updated : s;
            })
          : [];
        return next;
      });
    }
  }, []);

  const onToggleMyCalendar = useCallback(() => {
    if (auth?.userId) {
      setUsers([{ id: auth.userId, ...auth } as Partial<User>]);
    }
  }, [auth]);

  const onToggleEveryoneCalendar = useCallback(() => {
    setUsers(undefined);
  }, []);

  return (
    <section className="mt-3">
      <div className="d-flex flex-lg-row flex-column justify-content-between gap-4">
        {isSiteAdminOrAbove(auth) && (
          <>
            <Stack className="w-100" direction="vertical" gap={0}>
              <div className="w-100 text-nowrap">{t('scheduleCalendar|selectUserLabel')}:</div>
              <div className="flex-shrink-1">
                <UserAutocomplete
                  id={'filterUsers'}
                  multiple
                  name={'userIds'}
                  onSelect={(selected): void => {
                    if (selected === undefined) {
                      // selected is undefined (can't actually not be an array because of multiple prop)
                      return setUsers(undefined);
                    }
                    setUsers(Array.isArray(selected) ? selected : [selected]);
                  }}
                  required={false}
                  selected={users}
                />
              </div>
            </Stack>
            <Stack className="w-100" direction="vertical" gap={0}>
              <div className="w-100 text-nowrap">{t('scheduleCalendar|selectShiftLabel')}:</div>
              <div className="flex-shrink-1">
                <ShiftAutocomplete
                  id={'filterShifts'}
                  multiple
                  name={'shiftIds'}
                  onSelect={(selected): void => {
                    // since multiple is not set we can assume we are going to either get undefined or User
                    if (!selected) {
                      // selected is undefined (can't actually not be an array because of multiple prop)
                      return setShifts(undefined);
                    }
                    setShifts(Array.isArray(selected) ? selected : [selected]);
                  }}
                  required={false}
                  selected={shifts}
                />
              </div>
            </Stack>
          </>
        )}
        {!isSiteAdminOrAbove(auth) && (
          <Stack direction="horizontal" gap={3}>
            <Button
              label="Everyone"
              onClick={onToggleEveryoneCalendar}
              variant={users === undefined ? 'primary' : 'outline-primary'}
            />
            <Button
              label="My Calendar"
              onClick={onToggleMyCalendar}
              variant={users !== undefined ? 'primary' : 'outline-primary'}
            />
          </Stack>
        )}
        <ShiftScheduleButton
          onManageShifts={(): void => setShowManageShifts(true)}
          onSchedule={(): void => setShowScheduleUser(true)}
        />
      </div>
      <SchedulesCalendar
        onCreateNewSchedule={(date): void => onSelectedSchedule(date)}
        onRefreshed={onUpdateSelectedSchedules}
        onSelectedSchedules={onManageSchedules}
        shiftIds={(shifts ?? []).map((s) => s.id)}
        userIds={(users ?? []).filter((u) => u.id !== undefined).map((u) => u.id) as number[]}
      />
      {showScheduleUser && (
        <ScheduleUserModal
          onClose={onCloseScheduleModal}
          onSuccess={refreshCalender}
          selectedDate={selectedDate}
        />
      )}
      {showManageShifts && (
        <ManageShiftsModal onClose={onCloseManageShift} onSuccess={refreshCalender} />
      )}

      {showManageSchedules && (
        <ManageSchedulesModal
          onClose={onManageSchedulesClose}
          schedules={selectedManageSchedules}
        />
      )}
    </section>
  );
}

export default Schedules;
