// Copyright © 2024 CATTLEytics Inc.

import { differenceInSeconds, isFuture, parseISO } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { Badge, Stack, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaCircle } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';

import Button from '../common/components/Button';
import DataTable, { DataTableHeader, DataTableRow } from '../common/components/DataTable';
import DateTime from '../common/components/DateTime';
import FormFieldWrapper from '../common/components/FormFieldWrapper';
import { useAuth } from '../common/store/useAuth';
import { useSettingsContext } from '../common/store/useSettingsContext';
import { api } from '../common/utilities';
import { useUserPermissions } from '../common/utilities/apis/user';
import { useSettingsScheduleContext } from '../settings/context/ScheduleContext';
import {
  ApiResourceV1,
  getShiftDateString,
  HttpMethod,
  QueryKey,
  UserSchedule,
  utcStartDateOfSchedule,
} from '../shared';
import { RequestSwapDirection } from '../shared/enums/requestSwapDirection';
import { RequestSwapStatus } from '../shared/enums/requestSwapStatus';
import { HeadingLabel } from './components/HeadingLabel';
import { UserSchedulePicker } from './components/UserSchedulePicker';

interface Props {
  schedules?: UserSchedule[];
}

export function ManageSchedulesUserForm({ schedules }: Props): JSX.Element {
  // hooks
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { featureFlags: siteFeatureFlags } = useSettingsScheduleContext();
  const auth = useAuth();
  const settings = useSettingsContext();
  const { userData, isLoadingUserData } = useUserPermissions();

  // state
  const [showSwapModal, setShowSwapModal] = useState(false);
  const shift = useMemo(() => schedules?.[0]?.shift, [schedules]);
  const dateStr = useMemo(
    () => shift && getShiftDateString(shift, schedules ?? [], settings.timeZone),
    [shift, schedules, settings.timeZone],
  );
  const isEditable = useMemo(
    () => (schedules ?? []).length > 0 && auth.userId === (schedules ?? [])[0].userId,
    [auth.userId, schedules],
  );
  const [swapDirection, setSwapDirection] = useState<RequestSwapDirection>(
    RequestSwapDirection.Swap,
  );

  // callbacks
  const { mutateAsync: swapRequestAsync } = useMutation<
    Partial<UserSchedule> | undefined,
    unknown,
    {
      id: number;
      requestDirection?: RequestSwapDirection;
      requestSwapId?: number;
      requestedSwapStatus?: RequestSwapStatus;
      requestedUserId?: number;
    }
  >(
    ({ id, requestDirection, requestSwapId, requestedSwapStatus, requestedUserId }) => {
      if (id) {
        return api(HttpMethod.Patch, `${ApiResourceV1.UserSchedules}/${id}`, {
          body: { requestSwapId, requestedSwapStatus, requestDirection, requestedUserId },
        });
      }
      return Promise.reject();
    },
    {
      onSuccess: async (_data) => {
        queryClient.invalidateQueries(QueryKey.UserSchedules);
      },
    },
  );

  const onRequestSwap = useCallback(() => {
    setShowSwapModal(true);
  }, []);

  const onRequestCancelSwap = useCallback(() => {
    // set the requestSwapId to null
    if (!schedules || schedules.length !== 1) {
      return;
    }
    swapRequestAsync({ requestedSwapStatus: RequestSwapStatus.Rejected, id: schedules?.[0].id });
    setShowSwapModal(false);
  }, [schedules, swapRequestAsync]);

  const onCreateOneWaySwapRequest = useCallback(
    (userId: number) => {
      // validate the requestSwapWithUserSchedule.userId is not the current user.
      if (userId === auth.userId) {
        // TODO: show a warning.
        return;
      }
      if (!schedules || schedules.length !== 1) {
        return;
      }

      swapRequestAsync({
        requestedSwapStatus: RequestSwapStatus.Pending,
        requestedUserId: userId,
        requestDirection: RequestSwapDirection.Take,
        id: schedules?.[0].id,
      });
      setShowSwapModal(false);
    },
    [auth.userId, schedules, swapRequestAsync],
  );

  const onCreateSwapRequest = useCallback(
    (requestSwapWithUserSchedule: UserSchedule) => {
      // create a swap request...
      if (!schedules || schedules.length !== 1) {
        return;
      }

      // validate the requestSwapWithUserSchedule.userId is not the current user.
      if (requestSwapWithUserSchedule.userId === auth.userId) {
        // TODO: show a warning.
        return;
      }

      swapRequestAsync({
        requestedSwapStatus: RequestSwapStatus.Pending,
        requestSwapId: requestSwapWithUserSchedule.id,
        requestDirection: swapDirection ?? RequestSwapDirection.Swap,
        requestedUserId: requestSwapWithUserSchedule.userId,
        id: schedules?.[0].id,
      });
      setShowSwapModal(false);
    },
    [schedules, auth.userId, swapRequestAsync, swapDirection],
  );

  if (!schedules || schedules.length !== 1 || isLoadingUserData) {
    return <></>;
  }
  // Note: isFuture and utcToZonedTime aren't compatible.
  // utcToZonedTime("2024-10-28T04:00:00.000Z" => "Mon Oct 28 2024 00:00:00 GMT-0600 (Mountain Daylight Time)"
  //  when it should be

  const activeSchedule = schedules[0];
  const allowedToRequestSwap =
    userData?.permissions.scheduleAllowSwapping &&
    activeSchedule.allowSwap &&
    isEditable &&
    (activeSchedule.requestedSwapStatus === null ||
      activeSchedule.requestedSwapStatus === undefined ||
      activeSchedule.requestedSwapStatus === RequestSwapStatus.Rejected ||
      activeSchedule.requestedSwapStatus === RequestSwapStatus.Approved) &&
    isFuture(parseISO(activeSchedule.dateTimeStart));

  const startOfSchedule = activeSchedule.shift
    ? utcStartDateOfSchedule(activeSchedule.shift, activeSchedule, settings.timeZone)
    : undefined;

  const utcStartOfSchedule = startOfSchedule !== undefined ? startOfSchedule : new Date(0);

  const allowedToCancelSwap =
    utcStartOfSchedule !== undefined &&
    isFuture(utcStartOfSchedule) &&
    siteFeatureFlags.allowSwapping &&
    activeSchedule.requestedSwapStatus === RequestSwapStatus.Pending;

  const expiredSwap =
    utcStartOfSchedule !== undefined &&
    !isFuture(utcStartOfSchedule) &&
    siteFeatureFlags.allowSwapping &&
    activeSchedule.requestedSwapStatus === RequestSwapStatus.Pending;

  const hasRequestSent =
    !showSwapModal &&
    siteFeatureFlags.allowSwapping &&
    activeSchedule.requestedSwapStatus !== undefined &&
    activeSchedule.requestedSwapStatus !== null;

  const swapInfo = activeSchedule.swap
    ? {
        name: `${activeSchedule.swap.user?.firstName} ${activeSchedule.swap.user?.lastName}`,
        shiftName: activeSchedule.swap?.shift?.name,
        date: activeSchedule.swap?.shift
          ? getShiftDateString(activeSchedule.swap?.shift, [activeSchedule.swap], settings.timeZone)
          : '',
      }
    : {
        name: `${activeSchedule.requestedUser?.firstName} ${activeSchedule.requestedUser?.lastName}`,
        shiftName: 'One way',
        date: '-',
      };

  const headers: DataTableHeader[] = [
    {
      name: 'status',
      label: t('manageSchedulesForm|swapStatusLabel'),
      className: 'text-nowrap',
      colClassName: 'text-nowrap',
    },
    {
      name: 'name',
      label: t('manageSchedulesForm|nameLabel'),
    },
    {
      name: 'shiftName',
      label: t('manageSchedulesForm|shiftNameLabel'),
    },
    {
      name: 'date',
      label: t('manageSchedulesForm|dateLabel'),
    },

    {
      name: 'dateAt',
      label: t('manageSchedulesForm|atLabel'),
    },
  ];

  const historyEntries = activeSchedule.history
    .sort((a, b) => differenceInSeconds(parseISO(b.at), parseISO(a.at)))
    // the first entry is the "activeSchedule" so we skip it
    .slice(1, 5);

  const data: DataTableRow[] = !activeSchedule
    ? []
    : [
        {
          status: (
            <SwapStatusBadge
              status={activeSchedule.requestedSwapStatus ?? RequestSwapStatus.Pending}
            />
          ),
          name: swapInfo.name,
          shiftName: swapInfo?.shiftName,
          date: swapInfo?.date,
          dateAt: <DateTime date={activeSchedule?.requestedSwapDate} />,
        },
        ...historyEntries.map((h) => ({
          status: <SwapStatusBadge status={h.status ?? RequestSwapStatus.Pending} />,
          name: h.userFullName,
          shiftName: h.shiftName.toString(),
          date: getShiftDateString(
            {
              startTimeHour: h.startTimeHour,
              startTimeMinutes: h.startTimeMinutes,
              endTimeHour: h.endTimeHour,
              endTimeMinutes: h.endTimeMinutes,
            },
            [{ date: h.date }],
            settings.timeZone,
          ),
          dateAt: <DateTime date={h.at} />,
        })),
      ];

  return (
    <>
      <Stack gap={2}>
        <Stack direction="horizontal" gap={2}>
          <HeadingLabel
            title={t('manageSchedulesForm|shiftNameLabel')}
            value={
              <>
                <FaCircle className={'me-1'} color={shift?.color} />
                {shift?.name}
              </>
            }
          />
          <HeadingLabel title={t('manageSchedulesUserForm|timeLabel')} value={<>{dateStr}</>} />
          <HeadingLabel
            title={t('manageSchedulesUserForm|userLabel')}
            value={
              <>
                {auth.firstName} {auth.lastName}
              </>
            }
          />
        </Stack>

        {allowedToRequestSwap && (
          <Button
            disabled={showSwapModal}
            label={t('manageSchedulesForm|requestSwapLabel')}
            onClick={(): void => onRequestSwap()}
          />
        )}
        {allowedToCancelSwap && (
          <Button
            disabled={showSwapModal}
            label={t('manageSchedulesForm|cancelSwapLabel')}
            onClick={(): void => onRequestCancelSwap()}
          />
        )}
        {expiredSwap && <Button disabled label={t('manageSchedulesForm|expiredSwapLabel')} />}
        {hasRequestSent && <DataTable data={data} headers={headers} hidePaginationControls />}
        {showSwapModal && (
          <>
            <FormFieldWrapper
              className="justify-content-between"
              label={t('manageSchedulesForm|directionLabel')}
            >
              <ToggleButtonGroup
                className="align-baseline"
                name="swapDirection"
                onChange={(ev): void => setSwapDirection(ev)}
                type="radio"
                value={swapDirection}
              >
                <ToggleButton id="a" value="swap" variant="outline-dark">
                  {t('manageSchedulesForm|direction|swapLabel')}
                </ToggleButton>
                <ToggleButton id="b" value="take" variant="outline-dark">
                  {t('manageSchedulesForm|direction|takeLabel')}
                </ToggleButton>
              </ToggleButtonGroup>
            </FormFieldWrapper>
            <UserSchedulePicker
              activeSchedule={activeSchedule}
              onOneWaySelected={(s): void => {
                onCreateOneWaySwapRequest(s);
              }}
              onUserScheduleSelected={(s): void => {
                onCreateSwapRequest(s);
              }}
              swapDirection={swapDirection}
            />
          </>
        )}
      </Stack>
    </>
  );
}

function swapStatusToColor(status: RequestSwapStatus): string {
  return status === 'pending' ? 'warning' : status === 'rejected' ? 'danger' : 'success';
}

function SwapStatusBadge({ status }: { status: RequestSwapStatus }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Badge bg={swapStatusToColor(status ?? RequestSwapStatus.Pending)} className="me-2">
      {t(`manageSchedulesUserForm|status|${status ?? ''}`)}
    </Badge>
  );
}
