// Copyright © 2023 CATTLEytics Inc.

import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Icons from 'react-icons/io5';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button from '../../common/components/Button';
import { ButtonVariant } from '../../common/components/Button';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import ConfirmModal from '../../common/components/ConfirmModal';
import FormPlaceholder from '../../common/components/FormPlaceholder';
import { IconPickerModal } from '../../common/components/IconPickerModal';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import { useFocusField } from '../../common/hooks/useFocusField';
import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, TaskCategory } from '../../shared';

/**
 * Component's input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Identifier of the item we want to edit
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal to add/edit a task category.
 */
export const TaskCategoryModal = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);

  const [iconPickerModalVisible, setIconPickerModalVisible] = useState<boolean>(false);

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [iconName, setIconName] = useState<string>('IoHome');
  const [description, setDescription] = useState<string>('');

  const query = useQuery<TaskCategory | undefined>(
    [QueryKey.TaskCategories, props.editId],
    () => api(HttpMethod.Get, `${ApiResourceV1.TaskCategories}/${props.editId}`),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );
  useEffect(() => {
    if (query.data) {
      const data = query.data;
      if (!data) {
        return;
      }
      if (data.description) {
        setDescription(data.description);
      }
      if (data.iconName) {
        setIconName(data.iconName);
      }

      setName(data.name);
    }
  }, [query.data]);

  const mutation = useMutation(
    () => {
      const payload: Partial<TaskCategory> = {
        description: description,
        iconName: iconName,
        name: name,
      };

      if (props.editId) {
        return api(HttpMethod.Patch, `${ApiResourceV1.TaskCategories}/${props.editId}`, {
          body: payload,
        });
      } else {
        return api(HttpMethod.Post, ApiResourceV1.TaskCategories, { body: payload });
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKey.TaskCategories),
    },
  );

  const deleteItem = async (): Promise<void> => {
    if (!props.editId) {
      return;
    }
    setIsDeleting(true);
    try {
      await api(HttpMethod.Delete, `${ApiResourceV1.TaskCategories}/${props.editId}`);
      await queryClient.invalidateQueries(QueryKey.TaskCategories);
      setDeleteConfirmModalVisible(false);
      setIsDeleting(false);
      props.onClose();
    } catch (err) {
      console.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage(
        t('error|deleteFailed', { value: t('entity|taskCategory') }),
      );
      setIsDeleting(false);
    }
  };

  useFocusField({ validated });

  const queryClient = useQueryClient();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      props.onClose();
    } catch (err) {
      console.error(`Task category could not be saved.`, err);
      setErrorMessage(
        t(`error|saveFailed`, {
          typeLabel: t('entity|taskCategory'),
        }),
      );
    }
  };

  const placeholder = <FormPlaceholder showDeleteButton={!!props.editId} />;

  const Icon = iconName ? Icons[iconName as keyof typeof Icons] : Icons['IoHome'];

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formName">
        <Form.Label>
          {t('taskCategoryModal|nameFieldLabel')} <Required />
        </Form.Label>
        <Form.Control
          name={'name'}
          onChange={(e): void => setName(e.target.value)}
          placeholder={t('taskCategoryModal|nameFieldPlaceholder')}
          required
          type={'text'}
          value={name}
        />
        <Form.Text className={'text-muted'}>{t('taskCategoryModal|nameFieldHint')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formDescription">
        <Form.Label>{t('taskCategoryModal|descriptionFieldLabel')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'description'}
          onChange={(e): void => setDescription(e.target.value)}
          placeholder={t('taskCategoryModal|descriptionFieldPlaceholder')}
          rows={4}
          value={description}
        />
        <Form.Text className={'text-muted'}>
          {t('taskCategoryModal|descriptionFieldHint')}
        </Form.Text>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formIconName">
        <Form.Label>
          {t('taskCategoryModal|iconNameFieldLabel')} <Required />
        </Form.Label>
        <div>
          <div
            className={'border border-1 rounded d-flex justify-content-center align-items-center'}
            style={{ height: '100px', width: '100px' }}
          >
            <Button type={'button'} variant={'link'}>
              <Icon onClick={(): void => setIconPickerModalVisible(true)} size={64} />
            </Button>
          </div>
        </div>
        <Form.Text className={'text-muted'}>{t('taskCategoryModal|iconNameFieldHint')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>
      <AlertErrorForModal message={errorMessage} />
      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        {props.editId && (
          <ButtonModalDelete
            busy={mutation.isLoading || isDeleting}
            disabled={isDeleting || mutation.isLoading || query.isLoading}
            onClick={(): void => setDeleteConfirmModalVisible(true)}
          />
        )}
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={props.editId ? t('taskCategoryModal|titleEdit') : t('taskCategoryModal|titleAdd')}
      visible={true}
    >
      {query.isError && (
        <AlertErrorForModal
          message={t(`common|unexpectedRetrievalError`, {
            value: t('product.'),
          })}
        />
      )}
      {query.isFetching ? placeholder : form}
      {iconPickerModalVisible && (
        <IconPickerModal
          onChange={(selected): void => setIconName(selected as string)}
          onClose={(): void => setIconPickerModalVisible(false)}
          value={iconName}
        />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('product') })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('product') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: t('product') })}
      </ConfirmModal>
    </Modal>
  );
};
