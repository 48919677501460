// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import Modal from '../../common/components/Modal';
import { useFocusField } from '../../common/hooks/useFocusField';
import UserService from '../../common/services/userService';
import { useAuth } from '../../common/store/useAuth';
import { QueryKey, User } from '../../shared';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * A modal for a user to change their profile information.
 */
const UserEditProfileModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const userService = useInjection<UserService>(TYPES.userService);

  const [t, i18n] = useTranslation();

  const auth = useAuth();

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [notificationEmail, setNotificationEmail] = useState<boolean>(true);
  const [language, setLanguage] = useState<string>('en-us');
  const [theme, setTheme] = useState<string>('light');
  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const query = useQuery<User | undefined>([QueryKey.Users, auth.userId], () =>
    userService.get(auth.userId as number),
  );

  useEffect(() => {
    if (query.data) {
      setFirstName(query.data.firstName);
      setLastName(query.data.lastName);
      setLanguage(query.data.language);
      setEmail(query.data.email);
      setPhone(query.data.phone ?? ''); // Set phone, handling undefined
      setNotificationEmail(query.data.notificationEmail);
      setTheme(query.data.theme);
    }
  }, [query.data]);

  const mutation = useMutation(
    () => {
      const payload: Record<string, string | boolean> = {
        firstName: firstName,
        lastName: lastName,
        language: language,
        email: email,
        phone: phone,
        notificationEmail: notificationEmail,
        theme: theme,
      };
      return userService.patch(auth.userId as number, payload);
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Users);
      },
    },
  );

  const queryClient = useQueryClient();

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      i18n.changeLanguage(language).then();
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      console.error('User could not be saved.', err);
      setErrorMessage(t('userEditProfileModal|userSaveError'));
    }
  };

  useFocusField({ validated });

  return (
    <Modal
      onClose={props.onClose}
      size={'lg'}
      title={t('userEditProfileModal|modalTitle')}
      visible={true}
    >
      <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
        <FormGroup className="form-group mb-3" controlId="formFirstName">
          <Form.Label>{t('userEditProfileModal|firstNameLabel')}</Form.Label>
          <Form.Control
            minLength={1}
            name={'firstName'}
            onChange={(e): void => setFirstName(e.target.value)}
            placeholder={t('userEditProfileModal|firstNamePlaceholder')}
            required={true}
            value={firstName}
          />
          <Form.Control.Feedback type={'invalid'}>
            {t('userEditProfileModal|fieldRequiredError')}
          </Form.Control.Feedback>
        </FormGroup>

        <FormGroup className="form-group mb-3" controlId="formLastName">
          <Form.Label>{t('userEditProfileModal|lastNameLabel')}</Form.Label>
          <Form.Control
            minLength={1}
            name={'lastName'}
            onChange={(e): void => setLastName(e.target.value)}
            placeholder={t('userEditProfileModal|lastNamePlaceholder')}
            required={true}
            value={lastName}
          />
          <Form.Control.Feedback type={'invalid'}>
            {t('userEditProfileModal|fieldRequiredError')}
          </Form.Control.Feedback>
        </FormGroup>

        <FormGroup className="form-group mb-3" controlId="formEmail">
          <Form.Label>{t('userEditProfileModal|emailLabel')}</Form.Label>
          <Form.Control
            minLength={1}
            name={'email'}
            onChange={(e): void => setEmail(e.target.value)}
            placeholder={t('userEditProfileModal|emailPlaceholder')}
            required={true}
            type={'email'}
            value={email}
          />
          <Form.Control.Feedback type={'invalid'}>
            {t('userEditProfileModal|fieldRequiredError')}
          </Form.Control.Feedback>
        </FormGroup>

        <FormGroup className="form-group mb-3" controlId="formPhone">
          <Form.Label>{t('userEditProfileModal|phoneLabel')}</Form.Label>
          <Form.Control
            minLength={10}
            name={'phone'}
            onChange={(e): void => setPhone(e.target.value)}
            placeholder={t('userEditProfileModal|phonePlaceholder')}
            required={false}
            type={'phone'}
            value={phone}
          />
          <Form.Control.Feedback type={'invalid'}>
            {t('userEditProfileModal|phoneFieldLengthError')}
          </Form.Control.Feedback>
        </FormGroup>

        <FormGroup className="form-group mb-3" controlId="formNotificationEmail">
          <Form.Label>{t('userEditProfileModal|emailNotificationLabel')}</Form.Label>
          <Form.Check
            checked={notificationEmail}
            label={t('userEditProfileModal|emailNotificationDescription')}
            name={'notificationEmail'}
            onChange={(e): void => setNotificationEmail(e.target.checked)}
            type={'checkbox'}
          />
        </FormGroup>

        <Form.Group
          aria-describedby="translationsHelpBlock"
          className="form-group mt-3 mb-1"
          controlId="formGroupTranslations"
        >
          <Form.Label> {t('userEditProfileModal|languageLabel')}</Form.Label>
          <Form.Select
            aria-label="language selection"
            name={'language'}
            onChange={(e): void => setLanguage(e.target.value)}
            value={language}
          >
            <option value="de">{'Deutsch'}</option>
            <option value="en-ca">{'English - Canada'}</option>
            <option value="en-us">{'English - US'}</option>
            <option value="es">{'Español'}</option>
            <option value="fi">{'Finnois'}</option>
            <option value="fr">{'Français'}</option>
            <option value="nl">{'Nederlands'}</option>
            <option value="pa">{'ਪੰਜਾਬੀ'}</option>
            <option value="pt">{'Portugais'}</option>
          </Form.Select>
          <Form.Text id="translationsHelpBlock" muted>
            {t('userEditProfileModal|selectLanguage')}
          </Form.Text>
        </Form.Group>

        <FormGroup className="form-group mb-3" controlId="formTheme">
          <Form.Label>{t('userEditProfileModal|themeLabel')}</Form.Label>
          <div>
            <Form.Check
              checked={theme === 'light'}
              id={'theme-light'}
              inline
              label={t('userEditProfileModal|themeLight')}
              name={'theme'}
              onChange={(e): void => setTheme(e.target.value)}
              type={'radio'}
              value={'light'}
            />
            <Form.Check
              checked={theme === 'dark'}
              id={'theme-dark'}
              inline
              label={t('userEditProfileModal|themeDark')}
              name={'theme'}
              onChange={(e): void => setTheme(e.target.value)}
              type={'radio'}
              value={'dark'}
            />
          </div>
          <Form.Control.Feedback type={'invalid'}>
            {t('userEditProfileModal|fieldRequiredError')}
          </Form.Control.Feedback>
        </FormGroup>

        <AlertErrorForModal message={errorMessage} />
        <div className="modal-footer modal-footer-in-form">
          <ButtonModalCancel
            disabled={mutation.isLoading || query.isLoading}
            onClick={props.onClose}
          />
          <ButtonModalSave
            busy={mutation.isLoading}
            disabled={mutation.isLoading || query.isLoading}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default UserEditProfileModal;
