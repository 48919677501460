// Copyright © 2023 CATTLEytics Inc.
import { useMemo } from 'react';

import { useAuth } from '../store/useAuth';
import { isSuperAdmin } from '../utilities';

export const useIsAuthUserSuperAdmin = (): boolean => {
  const auth = useAuth();

  return useMemo(() => isSuperAdmin(auth), [auth]);
};
