// Copyright © 2024 CATTLEytics Inc.

import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Button from '../../common/components/Button';
import Page from '../../common/components/Page';
import { api, IconLink } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey, TaskTemplate } from '../../shared';
import { ReadonlyField } from './ReadonlyField';
import { TaskCategoryBadge } from './TaskCategoryBadge';
import { TaskPriorityLabel } from './TaskPriority';
import { UrlPreview } from './UrlPreview';

interface RouteParams {
  /**
   * Tab key to make active on render.
   */
  protocolId: string;
}

export function TaskTemplateView(_props: any): JSX.Element {
  const { t } = useTranslation();
  const { protocolId } = useParams<RouteParams>();

  const taskTemplateQuery = useQuery([QueryKey.TaskTemplates, protocolId], () => {
    return api<TaskTemplate>(HttpMethod.Get, `${ApiResourceV1.TaskTemplates}/${protocolId}`);
  });

  if (taskTemplateQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <Page
      breadcrumbTitle={t('taskTemplateView|breadcrumbTitle', {
        title: taskTemplateQuery.data?.title,
      })}
      breadcrumbs={[
        { label: t('dairyLogsPage|title'), to: '/dairy-logs' },
        { label: t('entity|task_plural'), to: '/tasks/all' },
        { label: t('entity|taskTemplate_plural'), to: '/protocols' },
      ]}
      buttons={
        <>
          <CopyToClipboard text={window.location.href}>
            <Button icon={IconLink} variant="outline-primary">
              Copy link to Protocol
            </Button>
          </CopyToClipboard>
        </>
      }
      title={t('taskTemplateView|breadcrumbTitle', {
        title: taskTemplateQuery.data?.title,
      })}
    >
      <Form>
        <Row>
          <Col>
            <ReadonlyField label={t('Description')}>
              {taskTemplateQuery.data?.description}
            </ReadonlyField>
            <ReadonlyField label={t('Instructions')}>
              {taskTemplateQuery.data?.instructions}
            </ReadonlyField>
            <ReadonlyField label={t('Audio')}>
              {taskTemplateQuery.data?.websiteAudioUrl ? (
                <>
                  {/* <GoogleDriveIFrame url={taskTemplateQuery.data.websiteAudioUrl} /> */}
                  <UrlPreview uri={taskTemplateQuery.data.websiteAudioUrl} />
                </>
              ) : (
                <UrlPreview uri={taskTemplateQuery.data?.websiteAudioUrl} />
              )}
            </ReadonlyField>
            <ReadonlyField label={t('Test')}>
              <UrlPreview uri={taskTemplateQuery.data?.websiteTestUrl} />
            </ReadonlyField>
            <ReadonlyField label={t('Website')}>
              <UrlPreview uri={taskTemplateQuery.data?.websiteUrl} />
            </ReadonlyField>
            <ReadonlyField label={t('Category')}>
              {taskTemplateQuery.data?.taskCategory && (
                <TaskCategoryBadge category={taskTemplateQuery.data?.taskCategory} />
              )}
            </ReadonlyField>
            <ReadonlyField label={t('Priority')}>
              {taskTemplateQuery.data?.priority && (
                <TaskPriorityLabel priority={taskTemplateQuery.data?.priority} />
              )}
            </ReadonlyField>
          </Col>
        </Row>
      </Form>
    </Page>
  );
}
