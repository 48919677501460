// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Form, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import ButtonSave from '../../common/components/ButtonSave';
import PlaceholderForm from '../../common/components/PlaceholderForm';
import Required from '../../common/components/Required';
import Toast from '../../common/components/Toast';
import { useFocusField } from '../../common/hooks/useFocusField';
import { api } from '../../common/utilities';
import Logger from '../../logger/logger';
import { ApiResourceV1, QueryKey } from '../../shared/enums';

/**
 * Schedule settings tab.
 */
const ScheduleTab = (): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const [data, setData] = useState<Record<string, string>>({
    allowSwapping: 'false',
    requestApproval: 'false',
  });

  const [toastVisible, setToastVisible] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const queryClient = useQueryClient();

  const query = useQuery(
    [QueryKey.Settings, 'schedule'],
    () => api('GET', `${ApiResourceV1.Settings}/schedule`),
    {
      onSuccess: (data) => setData(data as Record<string, string>),
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  const mutation = useMutation(
    () => {
      return api('PATCH', `${ApiResourceV1.Settings}/schedule`, { body: data });
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Settings);
        await queryClient.invalidateQueries(QueryKey.Sites);
      },
    },
  );

  useFocusField({ validated });

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      setToastVisible(true);
    } catch (err) {
      logger.error('Settings could not be saved.', err, data);
      setErrorMessage(t('scheduleTab|settingsNotSavedError'));
    }
  };

  const placeholder = <PlaceholderForm fields={3} />;

  const fields = (
    <>
      <Form.Group className="form-group mb-3" controlId="allowSwapping">
        <Form.Label>
          {t('scheduleTab|allowSwapping')} <Required />
        </Form.Label>
        <Form.Switch
          checked={data.allowSwapping === 'true'}
          className={'me-3'}
          id={`form-active`}
          label={data.allowSwapping === 'true' ? 'Active' : 'Inactive'}
          onChange={(e): void =>
            setData((prevData) => ({ ...prevData, allowSwapping: String(e.target.checked) }))
          }
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
        <Form.Text className={'text-muted'}>{t('scheduleTab|allowSwappingText')}</Form.Text>
      </Form.Group>

      {/* <Form.Group className="form-group mb-3" controlId="requestApproval">
        <Form.Label>
          {t('scheduleTab|requestApproval')} <Required />
        </Form.Label>
        <Form.Switch
          checked={data.requestApproval === 'true'}
          className={'me-3'}
          id={`form-active`}
          label={data.requestApproval === 'true' ? 'Active' : 'Inactive'}
          onChange={(e): void =>
            setData((prevData) => ({ ...prevData, requestApproval: String(e.target.checked) }))
          }
        />
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
        <Form.Text className={'text-muted'}>{t('scheduleTab|requestApprovalText')}</Form.Text>
      </Form.Group> */}
    </>
  );

  return (
    <Form noValidate onSubmit={onFormSubmit} validated={validated}>
      {query.isFetching ? placeholder : fields}

      {errorMessage ? <AlertError message={errorMessage} /> : null}

      <p className={'d-flex justify-content-center'}>
        {query.isFetching ? (
          <PlaceholderButton xs={2} />
        ) : (
          <ButtonSave busy={mutation.isLoading} disabled={mutation.isLoading} />
        )}
      </p>
      <Toast onClose={(): void => setToastVisible(false)} show={toastVisible}>
        {t('Settings saved')}
      </Toast>
    </Form>
  );
};

export default ScheduleTab;
