// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Placeholder, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertError from '../../common/components/AlertError';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import RoleSelect from '../../common/components/RoleSelect';
import { useFocusField } from '../../common/hooks/useFocusField';
import RoleService from '../../common/services/roleService';
import UserService from '../../common/services/userService';
import { useAuth } from '../../common/store/useAuth';
import Logger from '../../logger/logger';
import { Role, RoleEnum, User } from '../../shared';
import { QueryKey } from '../../shared/enums';

/**
 * Component's input properties.
 */
interface Props {
  // Additional class names to pass to the component.
  className?: string;

  // Callback when modal is closed.
  onClose: () => void;

  /**
   * The user identifier of the user we will assign to a site
   */
  userId: number;
}

/**
 * Modal to add/edit an animal user.
 */
const UserAssignRoleModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const userService = useInjection<UserService>(TYPES.userService);
  const roleService = useInjection<RoleService>(TYPES.roleService);

  const auth = useAuth();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [validated, setValidated] = useState<boolean>(false);
  const [role, setRole] = useState<RoleEnum>(RoleEnum.User);
  const [existingRole, setExistingRole] = useState<Role>();
  const [user, setUser] = useState<User>();

  const query = useQuery<User | undefined>(
    [QueryKey.Users, props.userId],
    () => userService.get(props.userId),
    {
      keepPreviousData: true,
      enabled: !!props.userId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  useEffect(() => {
    if (query.data) {
      setUser(query.data);
      if (query.data.roles) {
        const roles = query.data.roles;
        const found = roles.find((role: Role) => role.siteId === auth.siteId);

        setExistingRole(found);
        if (found) {
          setRole(found.role);
        }
      }
    }
  }, [query.data, auth.siteId]);

  const mutation = useMutation<Role | undefined>(
    () => {
      if (existingRole) {
        return roleService.patch(existingRole.id, {
          role: role,
          userId: props.userId,
        });
      } else {
        return roleService.post({
          role: role,
          userId: props.userId,
        });
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.Users);
        await queryClient.invalidateQueries(QueryKey.Roles);
      },
    },
  );

  useFocusField({ validated });

  const queryClient = useQueryClient();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      logger.error(`Site could not be attached to user.`, err, user);
      setErrorMessage(t(`Site could not be attached to user.`));
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      <Placeholder xs={6} />
      <Placeholder className={'mb-5'} size={'lg'} xs={12} />
    </Placeholder>
  );

  const form = (
    <Form noValidate onSubmit={handleFormSubmit} validated={validated}>
      <FormGroup className="form-group mb-3" controlId="formRole">
        <Form.Label>
          {t('Choose a role')} <Required />
        </Form.Label>
        <RoleSelect onChange={(role): void => setRole(role)} value={role} />
        <Form.Text className={'text-muted'}>{t('userAssignRoleModal|roleSelectionText')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>

      <AlertErrorForModal message={errorMessage} />

      <div className="modal-footer modal-footer-in-form">
        <ButtonModalCancel
          disabled={mutation.isLoading || query.isLoading}
          onClick={props.onClose}
        />
        <ButtonModalSave
          busy={mutation.isLoading}
          disabled={mutation.isLoading || query.isLoading}
        />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'lg'}
      title={`Assign a role to ${user?.firstName ?? '...'}`}
      visible={true}
    >
      {query.isError && (
        <AlertError message={`Something unexpected happened while retrieving user.`} />
      )}
      {query.isFetching ? placeholder : form}
      {query.isFetching && (
        <div className="modal-footer modal-footer-in-form">
          <PlaceholderButton variant={'secondary'} xs={2} />
          <PlaceholderButton xs={2} />
        </div>
      )}
    </Modal>
  );
};

export default UserAssignRoleModal;
