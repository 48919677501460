// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import AlertError from '../../common/components/AlertError';
import Button, { ButtonVariant } from '../../common/components/Button';
import ConfirmModal from '../../common/components/ConfirmModal';
import DataTable, { DataTableHeader, DataTableRow } from '../../common/components/DataTable';
import TablePlaceholder from '../../common/components/TablePlaceholder';
import TerminationCode from '../../common/entities/terminationCode';
import { Sort } from '../../common/enums';
import { useAuth } from '../../common/store/useAuth';
import {
  api,
  IconDelete,
  IconEdit,
  IconView,
  isSuperAdmin,
  scrollToTop,
} from '../../common/utilities';
import { isNullOrWhitespace } from '../../shared';
import { ApiResourceV1, QueryKey } from '../../shared/enums';
import TerminationCodeModal from './TerminationCodeModal';

interface Props {
  /**
   * Number of events to show.
   */
  initialLimit?: number;

  /**
   * Number of rows to skip.
   */
  initialOffset?: number;

  /**
   * Table sort direction.
   */
  initialSortDirection?: Sort;

  /**
   * Table sort field.
   */
  initialSortField?: string;

  showOnlyHidden?: boolean;
}

/**
 * Table of terminationCodes.
 */
const TerminationCodeTable = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const auth = useAuth();
  const history = useHistory();

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);

  const [deleteConfirmModalErrorMessage, setDeleteConfirmModalErrorMessage] = useState<string>('');
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [limit, setLimit] = useState<number>(props.initialLimit ?? 25);
  const [offset, setOffset] = useState<number>(props.initialOffset ?? 0);
  const [sortField, setSortField] = useState<string>(props.initialSortField ?? 'name');
  const [sortDirection, setSortDirection] = useState<Sort>(
    props.initialSortDirection ?? Sort.Ascending,
  );

  const query = useQuery(
    [QueryKey.TerminationCode, 'list', limit, offset, sortField, sortDirection],
    () =>
      api<TerminationCode>('GET', ApiResourceV1.TerminationCode, {
        params: {
          limit: String(limit),
          offset: String(offset),
          sortField: sortField,
          sortDirection: String(sortDirection),
        },
      }),
    {
      keepPreviousData: true,
      onSuccess: scrollToTop,
    },
  );

  const queryClient = useQueryClient();

  const deleteItem = async (): Promise<void> => {
    if (!deleteId) {
      return;
    }
    setIsDeleting(true);
    try {
      await api('DELETE', `${ApiResourceV1.TerminationCode}/${deleteId}`);
      await queryClient.invalidateQueries(QueryKey.Products);
      setDeleteConfirmModalVisible(false);
    } catch (err) {
      // logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage(t('common|itemCannotDeleteError'));
    }
    setIsDeleting(false);
  };

  const showEditModal = (id: number): void => {
    setEditModalVisible(true);
    setEditId(id);
  };

  const showDeleteConfirmModal = (id: number): void => {
    setDeleteConfirmModalVisible(true);
    setDeleteId(id);
  };

  if (query.isLoading) {
    return <TablePlaceholder />;
  }

  if (query.isError) {
    return <AlertError message={t('terminationCodesTable|terminationCodeRetrievalError')} />;
  }

  const headers: DataTableHeader[] = [
    {
      name: 'name',
      label: t('terminationCodesTable|nameColumnLabel'),
    },
    {
      name: 'description',
      label: t('terminationCodesTable|descriptionColumnLabel'),
    },
    {
      name: 'status',
      label: t('terminationCodesTable|statusColumnLabel'),
      infoTooltip: t('terminationCodesTable|statusColumnDescription'),
      hideMobile: true,
    },
  ];

  if (isSuperAdmin(auth)) {
    headers.push({ name: 'actions', sortable: false, label: 'Actions', hideMobile: true });
  }

  const data: DataTableRow[] = !query.data
    ? []
    : query.data.map((row: TerminationCode) => {
        return {
          id: String(row.id),
          name: row.name,
          description: (
            <div className={'text-truncate text-truncate-clamp-2'}>{row.description}</div>
          ),
          status: isNullOrWhitespace(row.status as any)
            ? ''
            : t(`animalTable|statusLabels|${row.status}`),
          actions: (
            <>
              <div onClick={(e): void => e.stopPropagation()}>
                <Dropdown as={ButtonGroup}>
                  <Button
                    className={'text-nowrap'}
                    onClick={(): void => history.push(`/termination-codes/${row.id}`)}
                    size="sm"
                    variant="outline-primary"
                  >
                    <IconView className={'me-1'} /> {t('View')}
                  </Button>
                  <Dropdown.Toggle size="sm" split variant="outline-primary" />
                  <Dropdown.Menu align="end">
                    <Dropdown.Item
                      className={'text-nowrap'}
                      onClick={(): void => history.push(`/termination-codes/${row.id}`)}
                    >
                      <IconView className={'me-1'} /> {t('View')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={'text-nowrap'}
                      onClick={(): void => showEditModal(row.id)}
                    >
                      <IconEdit className={'me-1'} /> {t('Edit')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={'text-nowrap'}
                      onClick={(): void => showDeleteConfirmModal(row.id)}
                    >
                      <IconDelete className={'me-1'} />
                      {t('Delete')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          ),
        };
      });
  return (
    <>
      <DataTable
        data={data}
        headers={headers}
        isLoading={query.isLoading}
        isPreviousData={query.isPreviousData}
        limit={limit}
        messageNoData={t('terminationCodesTable|noTerminationCodeFoundMessage')}
        offset={offset}
        onLimitChange={(newLimit): void => setLimit(newLimit)}
        onOffsetChange={(newOffset): void => setOffset(newOffset)}
        onRowClick={(row): void => history.push(`/termination-codes/${row.id}`)}
        onSortDirectionChange={(newSortDirection): void => setSortDirection(newSortDirection)}
        onSortFieldChange={(newSortField): void => setSortField(newSortField)}
        sortDirection={sortDirection}
        sortField={sortField}
      />
      {editModalVisible && (
        <TerminationCodeModal editId={editId} onClose={(): void => setEditModalVisible(false)} />
      )}
      <ConfirmModal
        busy={isDeleting}
        cancelLabel={t('Cancel')}
        cancelOnClick={(): void => setDeleteConfirmModalVisible(false)}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t(`Yes, delete this {{value}}`, { value: t('terminationCode') })}
        okOnClick={deleteItem}
        okVariant={ButtonVariant.Danger}
        title={t(`Delete this {{value}}`, { value: t('terminationCode') })}
        visible={deleteConfirmModalVisible}
      >
        {t('Are you sure you want to delete this {{value}}?', { value: t('terminationCode') })}
      </ConfirmModal>
    </>
  );
};

export default TerminationCodeTable;
