// Copyright © 2025 CATTLEytics Inc.

import { useContext } from 'react';

import { Auth } from '../../shared';
import AuthContext from './auth-context';

export function useAuth(): Auth {
  return useContext(AuthContext);
}
