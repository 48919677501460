// Copyright © 2025 CATTLEytics Inc.

import { useEffect } from 'react';

export function useFocusField(props: { validated: boolean }): void {
  const { validated } = props;

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);
}
