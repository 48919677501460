// Copyright © 2024 CATTLEytics Inc.

import { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import { ProtocolModal } from './ProtocolModal';
import { ProtocolTableWithFilters } from './ProtocolTableWithFilters';

/**
 * Routable component to list template tasks.
 */
export const ProtocolPage = (): JSX.Element => {
  const { t } = useTranslation();

  const [taskModalVisible, setTaskModalVisible] = useState<boolean>(false);

  return (
    <Page
      breadcrumbs={[
        { label: t('dairyLogsPage|title'), to: '/dairy-logs' },
        { label: t('tasksPage|title'), to: '/tasks/all' },
      ]}
      buttons={
        <Stack className="flex-column flex-md-row" direction="horizontal" gap={2}>
          <ButtonCreate
            label={t('taskTemplatePage|createButton')}
            onClick={(): void => setTaskModalVisible(true)}
          />
        </Stack>
      }
      title={t('taskTemplatePage|title')}
    >
      <ProtocolTableWithFilters />

      {taskModalVisible && <ProtocolModal onClose={(): void => setTaskModalVisible(false)} />}
    </Page>
  );
};
