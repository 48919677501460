// Copyright © 2024 CATTLEytics Inc.

import { useQuery } from 'react-query';

import { useSettingsScheduleContext } from '../../../settings/context/ScheduleContext';
import { ApiResourceV1, HttpMethod, QueryKey, User } from '../../../shared';
import { useAuth } from '../../store/useAuth';
import { api } from '../api';
import { applyPermissionsFilter } from '../permissions';

export async function apiGetUser(id: number | undefined | null): Promise<User> {
  if (id === undefined || id === null) {
    return Promise.reject(undefined);
  }

  return api<User>(HttpMethod.Get, `${ApiResourceV1.Users}/${id}`);
}

export function useUserPermissions(): {
  isLoadingUserData: boolean;
  userData: User | undefined;
} {
  const auth = useAuth();
  const { featureFlags: siteFeatureFlags } = useSettingsScheduleContext();

  const { data: userData, isLoading: isLoadingUserData } = useQuery(
    [QueryKey.Users, auth.userId],
    () => apiGetUser(auth.userId),
    {
      onSuccess: (data) => {
        return {
          ...data,
          permissions: applyPermissionsFilter(
            {
              scheduleAllowSwapping: siteFeatureFlags.allowSwapping,
            },
            data.permissions,
          ),
        };
      },
    },
  );

  return {
    userData: userData
      ? {
          ...userData,
          permissions: applyPermissionsFilter(
            {
              scheduleAllowSwapping: siteFeatureFlags.allowSwapping,
            },
            userData?.permissions ?? {},
          ),
        }
      : undefined,
    isLoadingUserData,
  };
}
