// Copyright © 2024 CATTLEytics Inc.

import { useState } from 'react';
import { PlaceholderButton, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import TerminationCode from '../../common/entities/terminationCode';
import { useAuth } from '../../common/store/useAuth';
import { api, isSiteAdminOrAbove } from '../../common/utilities';
import { ApiResourceV1, QueryKey } from '../../shared';
import TerminationCodeModal from '../../termination-code/components/TerminationCodeModal';
import TerminationCodeTable from '../../termination-code/components/TerminationCodeTable';

interface RouteParams {
  action: string;

  /**
   * Tab key to make active on render.
   */
  tabKey: string;
}

/**
 * Routable component to manage list of TerminationCodes.
 */
const ListsTerminationCodesPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();

  const auth = useAuth();

  const [terminationCodeModalVisible, setTerminationCodeModalVisible] = useState<boolean>(false);

  // @TODO what is this for?
  const query = useQuery<TerminationCode[]>(
    [QueryKey.TerminationCode, 'list'],
    () => api('GET', ApiResourceV1.TerminationCode, {}),
    {
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
    },
  );

  return (
    <Page
      breadcrumbs={[{ label: 'Lists', to: '/lists' }]}
      buttons={
        query.isLoading ? (
          <PlaceholderButton style={{ width: '8rem' }} xs={2} />
        ) : (
          isSiteAdminOrAbove(auth) && (
            <ButtonCreate
              label={t('terminationCodePage|addTerminationCodeButtonLabel')}
              onClick={(): void => {
                setTerminationCodeModalVisible(true);
              }}
            />
          )
        )
      }
      title={t('terminationCodePage|pageTitle')}
    >
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="terminationCodes-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/lists/termination-codes/${k}`);
          }
        }}
      >
        <Tab eventKey="active" title="Active">
          <TerminationCodeTable showOnlyHidden={false} />
        </Tab>
      </Tabs>
      {terminationCodeModalVisible && (
        <TerminationCodeModal onClose={(): void => setTerminationCodeModalVisible(false)} />
      )}
    </Page>
  );
};

export default ListsTerminationCodesPage;
