// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import { useState } from 'react';
import { Col, Placeholder, PlaceholderButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { TYPES } from '../../../types';
import CardStatsPlaceholder from '../../animals/components/CardStatsPlaceholder';
import Button, { ButtonVariant } from '../../common/components/Button';
import ButtonEdit from '../../common/components/ButtonEdit';
import DataCell from '../../common/components/DataCell';
import DateTime from '../../common/components/DateTime';
import ImageUploaded from '../../common/components/ImageUploaded';
import LightBoxModal from '../../common/components/LightBoxModal';
import Page from '../../common/components/Page';
import Product from '../../common/entities/product';
import ProductService from '../../common/services/productService';
import { useAuth } from '../../common/store/useAuth';
import { IconCreate, isSuperAdmin } from '../../common/utilities';
import { QueryKey } from '../../shared/enums';
import ProductAdminModal from './ProductAdminModal';
import ProductAdminTable from './ProductAdminTable';
import ProductModal from './ProductModal';

interface RouteParams {
  productId: string;
}

const ProductsDetailPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { productId } = useParams<RouteParams>();
  const productService = useInjection<ProductService>(TYPES.productService);

  const auth = useAuth();

  const [productModalVisible, setProductModalVisible] = useState<boolean>(false);
  const [productAdminModalVisible, setProductAdminModalVisible] = useState<boolean>(false);

  const [lightBoxImageUrl, setLightBoxImageUrl] = useState<string>('');
  const [lightBoxModalVisible, setLightBoxModalVisible] = useState<boolean>(false);

  const query = useQuery<Product | undefined>(
    [QueryKey.Products, 'get', productId],
    () => productService.get(Number(productId)),
    {
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
    },
  );
  const item = query.data;

  const hasImages = item && item.imageUrlsSigned && item.imageUrlsSigned.length > 0;

  const images = hasImages && item.imageUrlsSigned ? item.imageUrlsSigned : [];

  return (
    <Page
      breadcrumbs={[
        { label: t('Lists'), to: '/lists' },
        { label: t('Products'), to: '/lists/products' },
      ]}
      buttons={
        query.isLoading ? (
          <PlaceholderButton style={{ width: '8rem' }} xs={2} />
        ) : (
          isSuperAdmin(auth) && (
            <ButtonEdit
              label={t('Modify')}
              onClick={(): void => {
                setProductModalVisible(true);
              }}
            />
          )
        )
      }
      className={'container'}
      title={item ? item.name : ''}
    >
      {!item && (
        <>
          <CardStatsPlaceholder />
          <Placeholder animation={'glow'}>
            {[...Array(3).keys()].map((num) => (
              <div className={'my-4'} key={num}>
                <Placeholder size={'lg'} xs={6} />
                <hr className={'my-1'} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={8} />
              </div>
            ))}
          </Placeholder>
        </>
      )}
      {item && (
        <>
          <Row>
            <Col xs={6}>
              <DataCell label={t('Class')} value={item?.productClass.name} />
            </Col>
            <Col sm={6}>
              <DataCell label={t('Type')} value={item.productType.name} />
            </Col>
          </Row>

          <h2>{t('Description')}</h2>
          {item.description ? item.description : <em>{t('No description')}</em>}
          <h2>{t('Instructions')}</h2>
          {item.instructions ? item.instructions : <em>{t('No instructions')}</em>}
          <h2>{t('Dosage and Administration')}</h2>
          <ProductAdminTable hideProductNameColumn={true} productId={Number(productId)} />
          {isSuperAdmin(auth) && (
            <div className={'text-center'}>
              <Button
                icon={IconCreate}
                label={'Add'}
                onClick={(): void => setProductAdminModalVisible(true)}
                type={'button'}
                variant={ButtonVariant.OutlinePrimary}
              />
            </div>
          )}
          <h2>{t('Images')}</h2>
          <div className={'d-flex flex-wrap justify-content-start mb-3 gap-2'}>
            {hasImages &&
              images.map((imageUrl, index) => (
                <div
                  className={imageUrl.signedUrl && 'cursor-pointer'}
                  key={index}
                  onClick={(): void => {
                    if (imageUrl.signedUrl) {
                      setLightBoxModalVisible(true);
                      setLightBoxImageUrl(imageUrl.signedUrl);
                    }
                  }}
                >
                  <ImageUploaded
                    alt={t('Product image {{value}}', { value: index })}
                    src={imageUrl.signedUrl ?? '/img/failure-image-cow.png'}
                  />
                </div>
              ))}
            {!hasImages && <em>{t('No images')}</em>}
          </div>
          <h2>{t('More information')}</h2>
          <p>
            {item.websiteUrl ? (
              <a href={item.websiteUrl} rel="noreferrer" target={'_blank'}>
                {item.websiteUrl}
              </a>
            ) : (
              t('None')
            )}
          </p>
          <Row>
            <Col sm={6}>
              <DataCell label={t('Created')}>
                <DateTime date={item.createdDate} />
              </DataCell>
            </Col>
            <Col sm={6}>
              <DataCell label={t('Modified')}>
                <DateTime date={item.modifiedDate} />
              </DataCell>
            </Col>
          </Row>
        </>
      )}

      {productModalVisible && (
        <ProductModal
          editId={Number(productId)}
          onClose={(): void => setProductModalVisible(false)}
        />
      )}
      {productAdminModalVisible && (
        <ProductAdminModal
          onClose={(): void => setProductAdminModalVisible(false)}
          productId={Number(productId)}
        />
      )}
      {lightBoxModalVisible && (
        <LightBoxModal
          imageUrl={lightBoxImageUrl}
          onClose={(): void => setLightBoxModalVisible(false)}
        />
      )}
    </Page>
  );
};

export default ProductsDetailPage;
