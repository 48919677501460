// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Form, Placeholder, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalDelete from '../../common/components/ButtonModalDelete';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import DeleteConfirmModal from '../../common/components/DeleteConfirmModal';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import { useDeleteConfirmModal } from '../../common/hooks';
import { useFocusField } from '../../common/hooks/useFocusField';
import SireService from '../../common/services/sireService';
import Logger from '../../logger/logger';
import { QueryKey } from '../../shared';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * The primary id of a sire we want to edit.
   */
  editId?: number;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

interface Payload {
  aiCompany: string;
  name: string;
  nationalId: string;
  owningEntity: string;
  semenCode: string;
}

/**
 * Sire modal component for adding animal on arrival.
 */
const SireModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const logger = useInjection<Logger>(TYPES.logger);
  const sireService = useInjection<SireService>(TYPES.sireService);
  const queryClient = useQueryClient();
  const history = useHistory();

  const [aiCompany, setAiCompany] = useState<string>('Semex EastGen');
  const [name, setName] = useState<string>('');
  const [nationalId, setNationalId] = useState<string>('');
  const [owningEntity, setOwningEntity] = useState<string>('');
  const [semenCode, setSemenCode] = useState<string>('');

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    deleteConfirmModalOpen,
    deleteConfirmModalErrorMessage,
    setDeleteConfirmModalErrorMessage,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
  } = useDeleteConfirmModal();

  const query = useQuery(
    [QueryKey.Sires, props.editId],
    () => sireService.get(Number(props.editId) ?? -1),
    {
      keepPreviousData: true,
      enabled: !!props.editId,
      onSuccess: (data) => {
        // populate animal data state with animal data from API
        if (data) {
          Object.keys(data).forEach((key) => (data[key] = data[key] ?? ''));
          if (data.aiCompany) {
            setName(String(data.aiCompany));
          }
          if (data.name) {
            setName(String(data.name));
          }
          if (data.nationalId) {
            setNationalId(String(data.nationalId));
          }
          if (data.owningEntity) {
            setOwningEntity(String(data.owningEntity));
          }
          if (data.semenCode) {
            setSemenCode(String(data.semenCode));
          }
        }

        return;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const mutation = useMutation(
    () => {
      const data: Partial<Payload> = {
        aiCompany,
        name,
        nationalId,
        semenCode,
        owningEntity,
      };

      // if (data.temperature && units.temperatureUnit !== 'C') {
      //   data.temperature = convert(parseFloat(data.temperature)).from('F').to('C').toFixed(2);
      // }

      if (props.editId) {
        return sireService.patch(Number(props.editId), data);
      } else {
        return sireService.post(data);
      }
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Sires);
      },
    },
  );

  const deleteMutation = useMutation(() => {
    if (!props.editId) {
      throw Error('no data to delete');
    }
    return sireService.delete(props.editId);
  });

  const formActionsDisabled = useMemo(
    () => mutation.isLoading || query.isLoading || deleteMutation.isLoading,
    [mutation, query, deleteMutation],
  );

  const deleteSire = useCallback(async () => {
    try {
      await deleteMutation.mutateAsync();
      closeDeleteConfirmModal();
      props.onClose();
      history.replace('/sires');
    } catch (err) {
      logger.error('This item failed to be deleted', err);
      setDeleteConfirmModalErrorMessage(t('common|itemCannotDeleteError'));
    }
  }, [
    deleteMutation,
    closeDeleteConfirmModal,
    logger,
    setDeleteConfirmModalErrorMessage,
    props,
    t,
    history,
  ]);

  // when the form is validated scroll to the invalid elements
  useFocusField({ validated });

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();

      props.onClose();
    } catch (err) {
      logger.error('Sire could not be saved.', err);
      setErrorMessage(t('Sire could not be saved.'));
    }
  };

  const placeholder = (
    <Form>
      <Placeholder animation={'glow'}>
        <Placeholder xs={6} />
        <Placeholder className={'mb-5'} size={'lg'} xs={12} />
        <Placeholder xs={6} />
        <Placeholder className={'mb-5'} size={'lg'} xs={12} />
        <Placeholder xs={6} />
        <Placeholder className={'mb-5'} size={'lg'} xs={12} />
        <Placeholder xs={6} />
        <Placeholder className={'mb-5'} size={'lg'} xs={12} />
      </Placeholder>
      <div className="modal-footer modal-footer-in-form">
        <PlaceholderButton variant="secondary" xs={2} />
        <PlaceholderButton xs={2} />
      </div>
    </Form>
  );

  return (
    <Modal
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'xl'}
      title={`${props.editId ? 'Edit' : 'Add'} Sire`}
      visible={true}
    >
      {query.isLoading ? (
        placeholder
      ) : (
        <Form noValidate onSubmit={onFormSubmit} validated={validated}>
          <Form.Group className="form-group mb-3" controlId="formName">
            <Form.Label>
              {t('Name')} <Required />
            </Form.Label>
            <Form.Control
              name={'name'}
              onChange={(e): void => setName(e.target.value)}
              placeholder={t('Example: {{value}}', { value: 123 })}
              required
              type="text"
              value={name}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">{t('sireModal|sireNameInstructions')}</Form.Text>
          </Form.Group>
          <Form.Group className="form-group mb-3" controlId="formNationalId">
            <Form.Label>{t('National ID')}</Form.Label>
            <Form.Control
              name={'nationalId'}
              onChange={(e): void => setNationalId(e.target.value)}
              placeholder={t('Example: {{value}}', { value: 123 })}
              type="text"
              value={nationalId}
            />
            <Form.Control.Feedback type={'invalid'}>
              {t('common|fieldRequiredFeedback')}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">{t('sireModal|sireIdInstructions')}</Form.Text>
          </Form.Group>
          <Form.Group className="form-group mb-3" controlId="formSemenCode">
            <Form.Label>{t('Semen Code')}</Form.Label>
            <Form.Control
              name={'semenCode'}
              onChange={(e): void => setSemenCode(e.target.value)}
              placeholder={t('Example: {{value}}', { value: 443 })}
              type="text"
              value={semenCode}
            />
            <Form.Text className="text-muted">{t('sireModal|sireSemenCodeInstructions')}</Form.Text>
          </Form.Group>
          <Form.Group className="form-group mb-3" controlId="formOwningEntity">
            <Form.Label>{t('Owning Entity')}</Form.Label>
            <Form.Control
              name={'owningEntity'}
              onChange={(e): void => setOwningEntity(e.target.value)}
              placeholder={t('Example: {{value}}', { value: 443 })}
              type="text"
              value={owningEntity}
            />
            <Form.Text className="text-muted">
              {t('sireModal|sireOwningEntityInstructions')}
            </Form.Text>
          </Form.Group>
          <Form.Group className="form-group mb-3" controlId="formAiCompany">
            <Form.Label>{t('sireModal|aiCompanyLabel')}</Form.Label>
            <Form.Control
              name={'owningEntity'}
              onChange={(e): void => setAiCompany(e.target.value)}
              placeholder={t('Example: {{value}}', { value: 443 })}
              type="text"
              value={aiCompany}
            />
            <Form.Text className="text-muted">{t('Enter the name of the AI company.')}</Form.Text>
          </Form.Group>

          <AlertErrorForModal message={errorMessage} />
          <div className="modal-footer modal-footer-in-form">
            <ButtonModalCancel disabled={formActionsDisabled} onClick={props.onClose} />

            {!!props.editId && (
              <ButtonModalDelete
                busy={deleteMutation.isLoading}
                disabled={formActionsDisabled}
                onClick={openDeleteConfirmModal}
              />
            )}

            <ButtonModalSave busy={mutation.isLoading} disabled={formActionsDisabled} />
          </div>
        </Form>
      )}
      <DeleteConfirmModal
        busy={deleteMutation.isLoading}
        cancelOnClick={closeDeleteConfirmModal}
        errorMessage={deleteConfirmModalErrorMessage}
        okOnClick={deleteSire}
        value={t('sire')}
        visible={deleteConfirmModalOpen}
      />
    </Modal>
  );
};

export default SireModal;
