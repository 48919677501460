// Copyright © 2023 CATTLEytics Inc.

import {
  addMonths,
  endOfDay,
  format,
  getDay,
  isFuture,
  parse,
  parseISO,
  startOfDay,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';
import { groupBy } from 'lodash';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Calendar,
  CalendarProps,
  dateFnsLocalizer,
  Event,
  EventProps,
  SlotInfo,
  View,
} from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'usehooks-ts';

import Button from '../common/components/Button';
import DeleteConfirmModal from '../common/components/DeleteConfirmModal';
import { useDeleteConfirmModal } from '../common/hooks';
import SettingsContext from '../common/store/settings-context';
import { useAuth } from '../common/store/useAuth';
import { useSettingsContext } from '../common/store/useSettingsContext';
import { formatDate, IconCancel, isSiteAdminOrAbove } from '../common/utilities';
import { api } from '../common/utilities/api';
import { useSettingsScheduleContext } from '../settings/context/ScheduleContext';
import {
  ApiResourceV1,
  HttpMethod,
  QueryKey,
  Shift,
  UserSchedule,
  utcEndDateOfSchedule,
  utcStartDateOfSchedule,
} from '../shared';
import { EventItem } from './components/EventItem';

type ResourceType = { canSwap?: boolean; schedules: UserSchedule[]; shift: Shift };

const eventPropGetter: CalendarProps['eventPropGetter'] = (event) => ({
  style: {
    backgroundColor: isFuture(parseISO(event.resource.startDate))
      ? (event.resource.shift as Shift).color
      : (event.resource.shift as Shift).color,
  },
  className: 'schedule-event',
});

function AgendaEvent({ event }: EventProps): JSX.Element {
  const { t } = useTranslation();
  const schedules = useMemo(() => event.resource?.schedules as UserSchedule[], [event]);
  const queryClient = useQueryClient();
  const [showDeleteUserSchedule, setShowDeleteUserSchedule] = useState<UserSchedule>();
  const shift = useMemo(() => event.resource?.shift as Shift, [event]);
  const auth = useAuth();

  const {
    deleteConfirmModalOpen,
    deleteConfirmModalErrorMessage,
    openDeleteConfirmModal,
    closeDeleteConfirmModal,
  } = useDeleteConfirmModal();

  const openModal = useCallback(
    (userSchedule: UserSchedule) => {
      setShowDeleteUserSchedule(userSchedule);
      openDeleteConfirmModal();
    },
    [openDeleteConfirmModal, setShowDeleteUserSchedule],
  );

  const onSuccess = useCallback(() => {
    closeDeleteConfirmModal();
    queryClient.invalidateQueries(QueryKey.UserSchedules);
  }, [queryClient, closeDeleteConfirmModal]);

  const { isLoading: deleteIsLoading, mutateAsync: deleteAsync } = useMutation<
    UserSchedule | undefined
  >(
    () => {
      if (showDeleteUserSchedule?.id) {
        return api(
          HttpMethod.Delete,
          `${ApiResourceV1.UserSchedules}/${showDeleteUserSchedule.id}`,
        );
      }
      return Promise.reject();
    },
    {
      onSuccess,
    },
  );

  const onDelete = useCallback(() => deleteAsync(), [deleteAsync]);

  return (
    <div onClick={(e): void => e.stopPropagation()}>
      <div>{shift.name}:</div>
      <ul className="text-body list-group">
        {schedules.reduce<ReactNode[]>((prev, schedule) => {
          if (schedule.user) {
            const { firstName, lastName, id } = schedule.user;

            prev.push(
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                key={id}
                style={{ backgroundColor: 'rgba(0,0,0,0.05)' }}
              >
                <div>{`${firstName} ${lastName}`}</div>
                {isSiteAdminOrAbove(auth) && (
                  <IconCancel
                    onClick={(): void => {
                      openModal(schedule);
                    }}
                    style={{ cursor: 'pointer', width: '1.3rem', height: '1.3rem' }}
                  />
                )}
              </li>,
            );
          }

          return prev;
        }, [])}
      </ul>
      <DeleteConfirmModal
        busy={deleteIsLoading}
        cancelOnClick={(): void => closeDeleteConfirmModal()}
        errorMessage={deleteConfirmModalErrorMessage}
        okLabel={t('Yes, remove this schedule')}
        okOnClick={onDelete}
        title={t('Remove this schedule')}
        value={t('schedule')}
        visible={deleteConfirmModalOpen}
      />
    </div>
  );
}

function AgendaTime(props: any): JSX.Element {
  const settings = useSettingsContext();

  const label = useMemo(() => {
    if ('event' in props) {
      const { shift, schedules } = props.event.resource as ResourceType;
      const schedule = schedules[0];

      if (schedule) {
        const utcShiftStart = shift
          ? utcStartDateOfSchedule(shift, schedule, settings.timeZone)
          : undefined;

        const utcShiftEnd = utcEndDateOfSchedule(schedule, settings.timeZone);

        const startDate = utcShiftStart
          ? utcToZonedTime(utcShiftStart, settings.timeZone)
          : undefined;
        const endDate = utcShiftEnd ? utcToZonedTime(utcShiftEnd, settings.timeZone) : undefined;

        if (startDate && endDate && startDate.getDate() < endDate.getDate()) {
          return `${formatDate(startDate, 'p')} – ${formatDate(endDate, 'MMM d, p')}`;
        }
      }
    }

    if ('label' in props) {
      return props.label as string;
    }

    return '';
  }, [props, settings.timeZone]);

  return <div style={{ textTransform: 'none' }}>{label}</div>;
}

const defaultComponents: CalendarProps['components'] = {
  event: EventItem,
  agenda: {
    event: AgendaEvent,
    time: AgendaTime,
  },
};

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type Props = {
  allowSelect?: (userId: number) => boolean;
  components?: CalendarProps['components'];
  customEventFilter?: (schedule: UserSchedule) => boolean;
  initialDateRange?: {
    endDate: Date;
    startDate: Date;
  };
  onCreateNewSchedule?: (date: Date) => void;
  onRefreshed?: (schedules: UserSchedule[]) => void;
  onSelectedSchedules?: (schedules: UserSchedule[]) => void;
  onView?: (view: View) => void;
  shiftIds?: number[];
  showGrouped?: boolean;
  style?: React.CSSProperties;
  userIds?: number[];
  view?: View;
};

export function SchedulesCalendar({
  onCreateNewSchedule,
  onSelectedSchedules,
  onRefreshed,
  onView = undefined,
  shiftIds = [],
  userIds = [],
  view = undefined,
  style,
  showGrouped = true,
  components = defaultComponents,
  customEventFilter: customFilter,
}: Props): JSX.Element {
  // hooks
  const auth = useAuth();
  const { featureFlags } = useSettingsScheduleContext();
  const settings = useContext(SettingsContext);

  // state
  const [date, setDate] = useState<Date>(startOfDay(new Date()));
  const dateStart = useMemo(() => subMonths(date, 1), [date]);
  const dateEnd = useMemo(() => addMonths(date, 1), [date]);
  const isEditable = useMemo(() => isSiteAdminOrAbove(auth), [auth]);
  const [size, setSize] = useState<'sm' | 'md' | 'lg'>('md');
  const [focusDate, setFocusDate] = useState<Date | undefined>(undefined);
  const calendarRef = useRef<Calendar>(null);
  const bounceFocusDate = useDebounce(focusDate, 250);

  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // data
  const { data } = useQuery<UserSchedule[]>(
    [QueryKey.UserSchedules, dateStart, dateEnd, userIds, shiftIds, localTz],
    () => {
      const params =
        userIds.length > 0 || shiftIds.length > 0
          ? {
              dateEnd: dateEnd.toISOString(),
              dateStart: dateStart.toISOString(),
              userIds: userIds.map((id) => String(id)),
              shiftIds: shiftIds.map((id) => String(id)),
            }
          : {
              dateEnd: dateEnd.toISOString(),
              dateStart: dateStart.toISOString(),
            };
      return api(HttpMethod.Get, ApiResourceV1.UserSchedules, {
        params: params as unknown as Record<string, string>,
      });
    },
    {
      onSuccess: (data) => {
        onRefreshed?.(data);
      },
    },
  );

  const events = useMemo(() => {
    if (data === undefined || (data ?? []).length === 0) {
      return [];
    }

    const filteredData = customFilter ? data.filter(customFilter) : data;

    const groupDates = showGrouped
      ? // grouped by date+shift
        groupBy(filteredData, ({ date, shiftId }) => `${date}-${shiftId}`)
      : // not grouped
        groupBy(filteredData, ({ id }) => `${id}`);

    return Object.values(groupDates).reduce<Event[]>((prev, list) => {
      const shift = list[0].shift;
      if (shift) {
        const users = list
          .reduce<string[]>((prev, { user }) => (user ? [...prev, user.firstName] : prev), [])
          .join(', ');
        const loggedInUserSchedule = list.find(({ userId }) => userId === auth.userId);
        const canSwap =
          featureFlags.allowSwapping &&
          loggedInUserSchedule !== undefined &&
          loggedInUserSchedule.allowSwap;

        const utcStartDate = utcStartDateOfSchedule(shift, list[0], settings.timeZone);
        const utcEndDate = utcEndDateOfSchedule(list[0], settings.timeZone);
        const startDate =
          utcStartDate !== undefined ? utcToZonedTime(utcStartDate, settings.timeZone) : undefined;
        let endDate =
          utcEndDate !== undefined ? utcToZonedTime(utcEndDate, settings.timeZone) : undefined;

        if (
          startDate !== undefined &&
          endDate !== undefined &&
          startDate.getDate() !== endDate.getDate()
        ) {
          endDate = endOfDay(startDate);
        }

        return [
          ...prev,
          {
            title: users,
            start: startDate,
            end: endDate,
            resource: { shift, schedules: list, canSwap } as ResourceType,
          },
        ];
      }

      return prev;
    }, []);
  }, [auth.userId, customFilter, data, featureFlags.allowSwapping, settings.timeZone, showGrouped]);

  // callbacks
  const onNavigate = useCallback((date: Date) => {
    setDate(date);
  }, []);

  const onSelectSlot = useCallback(
    ({ start }: SlotInfo): void => {
      const sd = startOfDay(start);
      const now = startOfDay(new Date());
      if (sd >= now) {
        onCreateNewSchedule?.(start);
      }
    },
    [onCreateNewSchedule],
  );

  const onEventSelected = useCallback(
    (evt: Event): void => {
      onSelectedSchedules?.(evt.resource.schedules);
    },
    [onSelectedSchedules],
  );

  const getDrilldownView = useCallback((targetDate, currentViewName, configuredViewNames) => {
    if (currentViewName === 'month' && configuredViewNames.includes('week')) return 'week';

    return null;
  }, []);

  // scroll to element - this is for react-big-calendar, when changing the size of the calendar, it looks for the date that was clicked
  // and scrolls to it. This keeps the clicked value in view.
  useEffect(() => {
    const dayOfMonth = bounceFocusDate?.getDate()?.toString();
    const domDays = document.querySelectorAll(`.rbc-button-link`);
    const dayElement = Array.from(domDays).find(
      (el) => el.textContent === dayOfMonth,
    ) as HTMLElement;

    if (dayElement) {
      // compute the scroll position from the bounding rectangle.
      const rect = dayElement.getBoundingClientRect();
      window.scrollTo({
        top: rect.top,
        behavior: 'smooth',
      });
    }
  }, [size, bounceFocusDate]);

  return (
    <section className="mt-3">
      <Calendar
        className="scheduleCalender"
        components={components}
        date={date}
        dayLayoutAlgorithm="overlap"
        doShowMoreDrillDown={false}
        // dayPropGetter={dayPropGetter}
        endAccessor="end"
        eventPropGetter={eventPropGetter}
        events={events}
        getDrilldownView={getDrilldownView}
        localizer={localizer}
        onNavigate={onNavigate}
        onSelectEvent={onEventSelected}
        onSelectSlot={onSelectSlot}
        onShowMore={(_events, date): void => {
          setSize('lg');
          setFocusDate(date);
        }}
        onView={onView}
        ref={calendarRef}
        scrollToTime={focusDate}
        selectable={isEditable}
        showAllEvents={size === 'lg'}
        startAccessor="start"
        style={{ minHeight: size === 'md' ? 805 : 1805, ...style }}
        view={view} // Display four rows on five-week calendars
        views={{ day: false, month: true, week: true, agenda: true }}
      />
      {size === 'lg' && (
        <Button className="mt-2" onClick={(): void => setSize('md')} variant={'primary'}>
          Collapse
        </Button>
      )}
    </section>
  );
}
