// Copyright © 2024 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

import { addDays, isPast, isToday, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Task } from '../entities';

export function isOverdue(task: Task, timeZone: string): boolean {
  const isDue =
    task.completed === false &&
    !!task.dueDate &&
    !isToday(parseISO(task.dueDate)) &&
    isPast(parseISO(task.dueDate));

  const startDate = task.startDate ? utcToZonedTime(task.startDate, timeZone) : undefined;
  if (!isDue && startDate && task.toleranceDays !== undefined && task.toleranceDays >= 0) {
    // do something else

    const toleranceDate = addDays(startDate, task.toleranceDays);

    const toleranceDue =
      task.completed === false &&
      !!task.dueDate &&
      !isToday(toleranceDate) &&
      isPast(toleranceDate);

    if (toleranceDue) {
      return toleranceDue;
    }
  }

  return isDue;
}
