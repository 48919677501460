// Copyright © 2024 CATTLEytics Inc.

import React, { useState } from 'react';
import { Form, FormGroup, PlaceholderButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AlertError from '../../common/components/AlertError';
import ButtonSave from '../../common/components/ButtonSave';
import PlaceholderForm from '../../common/components/PlaceholderForm';
import Toast from '../../common/components/Toast';
import Setting from '../../common/entities/setting';
import { useFocusField } from '../../common/hooks/useFocusField';
import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared/enums';
import { isBoolean, isTruthy } from '../../shared/utilities/boolean';

type AiRecord = { key: string; value: string };

/**
 * FeatureFlags tab.
 */
export const FeatureFlagsTab = (): JSX.Element => {
  const { t } = useTranslation();

  const [toastVisible, setToastVisible] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [records, setRecords] = useState<AiRecord[]>([]);

  const queryClient = useQueryClient();

  const query = useQuery<Setting | undefined>(
    [QueryKey.Settings, 'feature-flags'],
    () => api<Setting>(HttpMethod.Get, `${ApiResourceV1.Settings}/feature-flags`),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
      onSuccess: (data) => {
        const recs: AiRecord[] = Object.keys(data ?? {})
          .map((k) => {
            if (!data) {
              return undefined;
            }
            return { key: k, value: `${data[k]}` };
          })
          .filter((v) => v !== undefined) as AiRecord[];
        setRecords(recs);
      },
    },
  );

  const mutation = useMutation(
    () => {
      return api(HttpMethod.Patch, `${ApiResourceV1.Settings}/feature-flags`, {
        body: {
          ...records.reduce((acc, v) => {
            acc[v.key] = v.value;
            return acc;
          }, {} as Setting),
        },
      });
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Settings);
      },
    },
  );

  useFocusField({ validated });

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      setToastVisible(true);
    } catch (err) {
      console.error('Settings could not be saved.', err);
      setErrorMessage(t('animalsTab|settingsNotSavedError'));
    }
  };

  const placeholder = <PlaceholderForm fields={3} />;

  const onChange = (itemKey: string, value: string): void => {
    setRecords((prevData) => {
      let nextData = prevData ?? [];

      nextData = nextData.map((d) => {
        if (d.key === itemKey) {
          return { ...d, value: value };
        }
        return d;
      });

      return nextData;
    });
  };

  return (
    <Form noValidate onSubmit={onFormSubmit} validated={validated}>
      {query.isFetching ? (
        placeholder
      ) : (
        <>
          {records.map((item) => {
            return (
              <FormGroup className="mb-3" key={item.key}>
                <Form.Label>{item.key}:</Form.Label>
                {isBoolean(item.value) && (
                  <Form.Check
                    checked={isTruthy(item.value)}
                    name={item.key}
                    onChange={(ev): void => {
                      if (item.key === 'Tasks/Magic' || item.key === 'TaskTemplates') {
                        onChange(item.key, ev.target.checked ? 'True' : 'False');
                      } else {
                        onChange(item.key, ev.target.checked ? 'true' : 'false');
                      }
                    }}
                    type="checkbox"
                  />
                )}
                {!isBoolean(item.value) && (
                  <Form.Control
                    name={item.key}
                    onChange={(ev): void => {
                      onChange(item.key, ev.target.value);
                    }}
                    type="text"
                    value={item.value}
                  />
                )}
              </FormGroup>
            );
          })}
        </>
      )}

      {errorMessage ? <AlertError message={errorMessage} /> : null}

      <p className={'d-flex justify-content-center'}>
        {query.isFetching ? (
          <PlaceholderButton xs={2} />
        ) : (
          <ButtonSave busy={mutation.isLoading} disabled={mutation.isLoading} />
        )}
      </p>
      <Toast onClose={(): void => setToastVisible(false)} show={toastVisible}>
        {t('dataImportTab|settingSavedToast')}
      </Toast>
    </Form>
  );
};
