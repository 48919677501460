// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { Card, Col, Placeholder, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TYPES } from '../../../types';
import AnimalAutocomplete from '../../animals/components/AnimalAutocomplete';
import Site from '../../common/entities/site';
import SiteService from '../../common/services/siteService';
import { useAuth } from '../../common/store/useAuth';
import { getFirstLetters } from '../../common/utilities';
import { Animal } from '../../shared';
import { QueryKey } from '../../shared';
import TasksListWhiteboard from '../../tasks/components/TasksListWhiteboard';
import HerdInfo from './HerdInfo';
import PenMap from './PenMap';
import QuickActions from './QuickActions';
import Stats from './Stats';

/**
 * Home dashboard component.
 */
const Dashboard = (): JSX.Element => {
  document.title = `Dashboard - CATTLEytics`;

  const { t } = useTranslation();

  const history = useHistory();
  const siteService = useInjection<SiteService>(TYPES.siteService);
  const auth = useAuth();
  const [site, setSite] = useState<Site>();
  const [mapUrl, setMapUrl] = useState<string>();

  const querySite = useQuery(
    [QueryKey.Sites, auth.siteId],
    () => siteService.getSite(Number(auth.siteId)),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  useEffect(() => {
    const data = querySite.data;
    if (data) {
      setSite(data);
      setMapUrl(data.mapUrlSigned);
    }
  }, [querySite.data]);

  return (
    <div className={'dashboard'}>
      <div className="mb-3 d-md-flex">
        <div className="me-md-3 d-flex justify-content-center">
          <div className={'avatar'}>
            {site?.iconUrlSigned ? (
              <img alt={site?.name} className={'rounded-circle'} src={site?.iconUrlSigned} />
            ) : (
              <div
                className={'d-flex h-100 justify-content-center align-items-center'}
                style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}
              >
                {site?.name && <span>{getFirstLetters(site?.name, 2)}</span>}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 mt-md-0">
          {querySite.isFetching && (
            <Placeholder animation={'glow'}>
              <Placeholder style={{ width: '16rem' }} />
            </Placeholder>
          )}
          {!querySite.isFetching && site?.logoUrlSigned && (
            <img
              alt={site?.name}
              src={site?.logoUrlSigned}
              style={{ maxWidth: '100%', maxHeight: 75 }}
            />
          )}
          {!querySite.isFetching && !site?.logoUrlSigned && (
            <h1
              className="mb-1 text-center text-md-start"
              style={{
                fontFamily: 'Lora, sans-serif',
                textTransform: 'none',
                fontWeight: 'normal',
              }}
            >
              <span>{site?.name}</span>
            </h1>
          )}
        </div>
      </div>
      <Row>
        <Col lg={8} md={6}>
          <h2>{t('Animal Lookup')}</h2>
          <AnimalAutocomplete
            id="animal-lookup"
            onSelect={(animal): void => {
              if (animal) {
                history.push(`/animals/${(animal as Animal).id}`);
              }
            }}
          />
          <h2>{t('Herd Info')}</h2>
          <HerdInfo />
        </Col>
        <Col lg={4} md={6}>
          <h2>{t('Quick Actions')}</h2>
          <QuickActions />
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} xl={8}>
          <h2>{t('Pen Map')}</h2>
          <Card className="mb-3" style={{ borderRadius: '1rem', padding: 0 }}>
            <PenMap busy={querySite.isLoading} debug={false} mapId={site?.mapUrl} mapUrl={mapUrl} />
          </Card>
        </Col>
        <Col xl={4}>
          <Row>
            <Col md={6} xl={12}>
              <h2>{t('Stats: Last 5 years')}</h2>
              <Stats className={'stats'} />
            </Col>
            <Col md={6} xl={12}>
              <h2>{t('To-do List')}</h2>
              <Card.Body>
                <TasksListWhiteboard />
              </Card.Body>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
