// Copyright © 2023 CATTLEytics Inc.

import { resolve } from 'inversify-react';
import React, { RefObject } from 'react';
import { Card } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { TYPES } from '../../../types';
import Button from '../../common/components/Button';
import Page from '../../common/components/Page';
import Required from '../../common/components/Required';
import Toast from '../../common/components/Toast';
import { IconImport } from '../../common/utilities';
import type Logger from '../../logger/logger';
import ExternalSystemType from '../entities/externalSystemType';
import ImportDataType from '../entities/importDataType';
import type ImportService from '../interfaces/importService';

/**
 * @description Describes the state of the @see Import component.
 * @interface State
 */
export interface State {
  /**
   * @description List of available external system types.
   * @type {ExternalSystemType[]}
   * @memberOf State
   */
  externalSystemTypes?: ExternalSystemType[];

  /**
   * @description List of available import data types.
   * @type {ImportDataType[]}
   * @memberOf State
   */
  importDataTypes?: ImportDataType[];

  /**
   * @description The ID of the selected external system.
   * @type {number}
   * @memberOf State
   */
  selectedExternalSystemTypeId?: number;

  /**
   * @description The ID of the selected import data type.
   * @type {number}
   * @memberOf State
   */
  selectedImportDataTypeId?: number;

  /**
   * @description The selected file.
   * @type {File}
   * @memberOf State
   */
  selectedImportFile?: File;

  /**
   * @description Captures an informational status message for the user.
   * @type {{
   *     message: string;
   *     isError: boolean;
   *   }}
   * @memberOf State
   */
  status?: {
    isError: boolean;
    message: string;
  };

  /**
   * @description Whether the import data is currently uploading.
   * @type {boolean}
   * @memberOf State
   */
  uploading: boolean;
}

/**
 * @description The root data import component.
 * @class Import
 * @extends {React.Component}
 * @TODO refactor to functional component
 */
class Import extends React.Component<WithTranslation, State> {
  /**
   * @description Logger.
   * @private
   * @type {Logger}
   * @memberOf Import
   */
  @resolve(TYPES.logger)
  private logger!: Logger;

  /**
   * @description Service used for importing data.
   * @private
   * @type {ImportService}
   * @memberOf Import
   */
  @resolve(TYPES.importService)
  private importService!: ImportService;

  private readonly formRef: RefObject<HTMLFormElement>;

  /**
   * Creates an instance of Import.
   * @param {WithTranslation} props The component's input properties.
   * @memberOf Import
   */
  constructor(props: WithTranslation) {
    super(props);

    this.state = {
      uploading: false,
    };
    this.formRef = React.createRef<HTMLFormElement>();
  }

  /**
   * @description Fetches the list of external systems when the
   *  component has mounted.
   * @returns {Promise<void>}
   * @memberOf Import
   */
  async componentDidMount(): Promise<void> {
    this.logger.debug('Fetching available external system types...');

    try {
      const externalSystemTypes = await this.importService.getExternalSystemTypes();
      const importDataTypes =
        externalSystemTypes && externalSystemTypes.length > 0
          ? externalSystemTypes[0].importDataTypes
          : [];
      this.setState({
        externalSystemTypes,
        importDataTypes,
        selectedExternalSystemTypeId:
          externalSystemTypes && externalSystemTypes.length > 0
            ? externalSystemTypes[0].id
            : undefined,
        selectedImportDataTypeId: importDataTypes.length > 0 ? importDataTypes[0].id : undefined,
      });
    } catch (error) {
      this.logger.error(`Error loading external system types: ${error}`);

      // TODO - figure out a better way to present and recover from these types of errors.
      this.setState({
        status: {
          isError: true,
          message: this.props.t('An unrecoverable error has occurred.'),
        },
      });
    }
  }

  /**
   * @description Renders the component.
   * @returns {JSX.Element}
   * @memberOf Import
   */
  render(): JSX.Element {
    const content = !this.state.externalSystemTypes ? (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">{this.props.t('common|loading')}</span>
      </div>
    ) : (
      this.getForm()
    );

    return (
      <Page title={this.props.t('Import Data')}>
        <Card className={'col-md-6'}>
          <Card.Body>{content}</Card.Body>
        </Card>
        <Toast
          onClose={(): void => this.setState({ status: undefined })}
          show={!!this.state.status}
        >
          {this.state.status && this.props.t(this.state.status.message)}
        </Toast>
      </Page>
    );
  }

  /**
   * @description Handles the submission of the import form.
   * @param {React.SyntheticEvent} event The form submit event.
   * @memberOf Import
   */
  private async onFormSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();
    this.logger.debug('The import form was submitted...');

    if (
      !this.state.selectedExternalSystemTypeId ||
      !this.state.selectedImportDataTypeId ||
      this.state.selectedImportDataTypeId === -1 ||
      !this.state.selectedImportFile
    ) {
      return;
    }

    this.setState({
      uploading: true,
    });

    try {
      await this.importService.importData(
        this.state.selectedExternalSystemTypeId,
        this.state.selectedImportDataTypeId,
        this.state.selectedImportFile,
      );
      // await this.importService.startImportCleanupJob();
      // await this.importService.startAnimalUpdateJob();
      // await this.importService.startPenUpdateJob();
      // await this.importService.startReindexJob();

      // this does NOT clear state which means the isFormValid() method will not
      // be affected by this (import button stays enabled)
      this.formRef.current?.reset();

      // we are resetting only the file state because of the way the initial
      // state is set for the dropdowns
      this.setState({
        uploading: false,
        status: {
          isError: false,
          message: this.props.t('Import has started.'),
        },
        selectedImportFile: undefined,
      });
    } catch (error) {
      this.logger.error(`Error caught from 'importData': ${error}`);
      this.setState({
        uploading: false,
        status: {
          isError: true,
          message: `${this.props.t(
            'An unexpected error occurred importing the data.',
          )} ${this.props.t('Please try again.')}`,
        },
      });
    }
  }

  /**
   * @description Determines if the current form input data is valid.
   * @private
   * @return {boolean}
   * @memberOf Import
   */
  private isFormValid(): boolean {
    return (
      !!this.state.selectedExternalSystemTypeId &&
      !!this.state.selectedImportDataTypeId &&
      this.state.selectedImportDataTypeId !== -1 &&
      !!this.state.selectedImportFile
    );
  }

  /**
   * @description Gets the import form.
   * @private
   * @return {JSX.Element}
   * @memberOf Import
   */
  private getForm(): JSX.Element {
    return (
      <form onSubmit={this.onFormSubmit.bind(this)} ref={this.formRef}>
        <fieldset disabled={this.state.uploading}>
          <div className="mb-3">
            <label className="form-label" htmlFor="externalSystemType">
              {this.props.t('Source Type')} <Required />
            </label>
            <select
              className="form-control"
              id="externalSystemType"
              name="externalSystemType"
              onChange={(e): void => {
                const id = parseInt(e.currentTarget.value);
                const importTypes =
                  this.state.externalSystemTypes?.find((x) => x.id === id)?.importDataTypes ?? [];
                this.setState({
                  selectedExternalSystemTypeId: id,
                  importDataTypes: importTypes,
                  selectedImportDataTypeId: importTypes.length > 0 ? importTypes[0].id : undefined,
                });
              }}
              value={this.state.selectedExternalSystemTypeId}
            >
              {this.state.externalSystemTypes?.map((type) => {
                return (
                  <option key={type.id} value={type.id}>
                    {type.description}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="importDataType">
              {this.props.t('Data Type')} <Required />
            </label>
            <select
              className="form-control"
              id="importDataType"
              name="importDataType"
              onChange={(e): void =>
                this.setState({
                  selectedImportDataTypeId: parseInt(e.currentTarget.value),
                })
              }
              value={this.state.selectedImportDataTypeId}
            >
              <option value={-1}>(none)</option>
              {this.state.importDataTypes?.map((type) => {
                return (
                  <option key={type.id} value={type.id}>
                    {type.description}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="sourceFile">
              {this.props.t('Source File')} <Required />
            </label>
            <input
              className="form-control"
              id="sourceFile"
              name="sourceFile"
              onChange={(e): void => {
                if (
                  e.currentTarget.files &&
                  e.currentTarget.files.length > 0 &&
                  e.currentTarget.files[0].size > 134_217_728
                ) {
                  this.setState({
                    status: {
                      isError: false,
                      message: this.props.t('Import file exceeds 134217728 bytes.'),
                    },
                    selectedImportFile: undefined,
                  });
                  return;
                }
                this.setState({
                  selectedImportFile:
                    e.currentTarget.files && e.currentTarget.files.length > 0
                      ? e.currentTarget.files[0]
                      : undefined,
                });
              }}
              type="file"
            />
          </div>
        </fieldset>
        <Button
          busy={this.state.uploading}
          disabled={!this.isFormValid()}
          icon={IconImport}
          type="submit"
        >
          {this.props.t('Import')}
        </Button>
      </form>
    );
  }
}

export default withTranslation()(Import);
