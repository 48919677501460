// Copyright © 2023 CATTLEytics Inc.

import React, { useEffect, useState } from 'react';
import { Alert, Form, InputGroup, ListGroup, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Modal from '../../common/components/Modal';
import { useFocusField } from '../../common/hooks/useFocusField';
import { useAuth } from '../../common/store/useAuth';
import { getFirstLetters, IconInfo, IconSearch } from '../../common/utilities';
import { api } from '../../common/utilities';
import { QueryKey } from '../../shared';
import { ApiResourceV1, HttpMethod, Site } from '../../shared';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * A modal for switching sites.
 */
const SiteSwitcherModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const auth = useAuth();

  const [site, setSite] = useState<Site>();

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [searchQuery, setSearchQuery] = useState<string>('');

  const query = useQuery<Site[]>(
    [QueryKey.Sites],
    () =>
      api<Site[]>(HttpMethod.Get, ApiResourceV1.Sites, {
        params: { sortField: 'name', sortDirection: 'asc' },
      }),
    {},
  );
  useEffect(() => {
    if (query.data) {
      const found = query.data.find((site) => site.id === auth.siteId);
      if (found) {
        setSite(found);
      }
    }
  }, [query.data, auth.siteId]);

  useFocusField({ validated });

  const mutation = useMutation(
    () => {
      if (!site) {
        throw new Error('Site is required');
      }
      const payload: Record<string, null | string | number> = {
        siteId: site.id,
      };

      return api(HttpMethod.Patch, ApiResourceV1.Auth + '/1', { body: payload });
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.resetQueries();
        await queryClient.invalidateQueries();
      },
    },
  );

  const queryClient = useQueryClient();

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      props.onClose();
      await mutation.mutateAsync();
    } catch (err) {
      console.error('Site could not be switched.', err);
      setErrorMessage(t('Site could not be switched.'));
    }
  };

  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
    </Placeholder>
  );

  const filterSites = (data: Site[]): Site[] => {
    return data.filter((item: { name: string }) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  const updateSearchQuery = (event: { target: { value: React.SetStateAction<string> } }): void => {
    setSearchQuery(event.target.value);
  };

  return (
    <Modal
      onClose={props.onClose}
      scrollable={true}
      size={'lg'}
      title={t(`Switch site`)}
      visible={true}
    >
      <Form
        noValidate={true}
        onSubmit={onFormSubmit}
        style={{ minHeight: 'calc(100vh - 191px)' }}
        validated={validated}
      >
        {auth.isSuperAdmin && (
          <Alert variant={'info'}>
            <IconInfo className={'me-2'} />
            You are a super administrator. You can see all sites.
          </Alert>
        )}
        {!auth.isSuperAdmin && (
          <Alert variant={'info'}>
            <IconInfo className={'me-2'} />
            You have access to the following sites.
          </Alert>
        )}

        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <IconSearch />
          </InputGroup.Text>
          <Form.Control autoFocus onChange={updateSearchQuery} placeholder={t('Search')} />
        </InputGroup>

        {query.isLoading ? (
          placeholder
        ) : (
          <ListGroup as="ul">
            {query.data &&
              filterSites(query.data).map((item) => (
                <ListGroup.Item
                  action
                  active={site && item.id === site.id}
                  className={'d-flex justify-content-start align-items-center'}
                  key={item.id}
                  onClick={(): void => setSite(item)}
                  type={'submit'}
                >
                  {item.iconUrlSigned && (
                    <img
                      alt={item.name}
                      className={'me-3 rounded'}
                      src={item.iconUrlSigned}
                      style={{ height: '48px', width: '48px' }}
                    />
                  )}
                  {!item.iconUrlSigned && (
                    <>
                      <div
                        className={'me-3 d-flex justify-content-center align-items-center'}
                        style={{
                          color: 'var(--bs-text)',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          width: '48px',
                          height: '48px',
                          border: '2px solid',
                          borderColor: 'var(--bs-text)',
                          borderRadius: '50%',
                        }}
                      >
                        {item?.name ? <span>{getFirstLetters(item.name, 2)}</span> : null}
                      </div>
                    </>
                  )}
                  <div>{item.name}</div>
                </ListGroup.Item>
              ))}
          </ListGroup>
        )}

        <AlertErrorForModal message={errorMessage} />
      </Form>
    </Modal>
  );
};

export default SiteSwitcherModal;
