// Copyright © 2023 CATTLEytics Inc.

import { useInjection } from 'inversify-react';
import React, { useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { TYPES } from '../../../types';
import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import ButtonModalCancel from '../../common/components/ButtonModalCancel';
import ButtonModalSave from '../../common/components/ButtonModalSave';
import Modal from '../../common/components/Modal';
import Required from '../../common/components/Required';
import { useFocusField } from '../../common/hooks/useFocusField';
import UserService from '../../common/services/userService';
import { useAuth } from '../../common/store/useAuth';
import { QueryKey } from '../../shared';
import { passwordValidation } from '../../shared/utilities/passwordValidation';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * A modal for a user to change passwords.
 */
const UserPasswordChangeModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();

  const userService = useInjection<UserService>(TYPES.userService);

  const auth = useAuth();

  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [passwordConfirmCheck, setPasswordConfirmCheck] = useState<boolean>(false);
  const [passwordCheck, setpasswordCheck] = useState<boolean>(false);
  const [passwordMatch, setpasswordMatch] = useState<boolean>(false);

  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const queryClient = useQueryClient();

  useFocusField({ validated });

  const mutation = useMutation(() => {
    const payload: Record<string, string> = {
      password: password,
    };

    return userService.patch(Number(auth.userId), payload);
  });

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity() && passwordMatch && passwordCheck;

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      await mutation.mutateAsync();
      await queryClient.invalidateQueries(QueryKey.Users);
      props.onClose();
    } catch (err) {
      console.error('User password change failure.', err);
      setErrorMessage(t(`We're sorry but your password could not be changed.`));
    }
  };

  return (
    <Modal onClose={props.onClose} size={'lg'} title={t(`Change password`)} visible={true}>
      <Form noValidate={true} onSubmit={onFormSubmit}>
        <FormGroup className="form-group mb-3" controlId="formPassword">
          <Form.Label>
            {t('Password')} <Required />
          </Form.Label>
          <Form.Control
            disabled={false}
            isInvalid={!passwordCheck && validated}
            isValid={passwordCheck && validated}
            name={'password'}
            onChange={(e): void => {
              setPassword(e.target.value);
              // regex to check if password contains 1 upper, 1 lower, and 1 number
              const check = passwordValidation(e.target.value);
              setpasswordCheck(check);
              // check if passwords match
              if (e.target.value === passwordConfirm) {
                setpasswordMatch(true);
              } else {
                setpasswordMatch(false);
              }
            }}
            placeholder={t('Enter a password')}
            required
            type={'password'}
            value={password}
          />
          <Form.Control.Feedback type={'invalid'}>
            {t('signUpForm|passwordRequirements')}
          </Form.Control.Feedback>
        </FormGroup>
        <FormGroup className="form-group mb-3" controlId="formPasswordConfirm">
          <Form.Label>
            {t('Confirm Password')} <Required />
          </Form.Label>
          <Form.Control
            disabled={false}
            isInvalid={(!passwordMatch || !passwordCheck) && validated}
            isValid={passwordMatch && passwordCheck && validated}
            name={'passwordConfirm'}
            onChange={(e): void => {
              setPasswordConfirm(e.target.value);
              // validation for re-enter input
              const check = passwordValidation(e.target.value);
              setPasswordConfirmCheck(check);
              // check if passwords match
              if (e.target.value === password) {
                setpasswordMatch(true);
              } else {
                setpasswordMatch(false);
              }
            }}
            placeholder={t('Re-enter password')}
            required
            type={'password'}
            value={passwordConfirm}
          />
          <Form.Control.Feedback type={'invalid'}>
            {!passwordMatch
              ? t('signUpForm|passwordMatch')
              : !passwordConfirmCheck
              ? t('signUpForm|passwordRequirements')
              : ''}
          </Form.Control.Feedback>
        </FormGroup>

        <AlertErrorForModal message={errorMessage} />
        <div className="modal-footer modal-footer-in-form">
          <ButtonModalCancel
            busy={mutation.isLoading}
            disabled={mutation.isLoading}
            onClick={props.onClose}
          />
          <ButtonModalSave busy={mutation.isLoading} disabled={mutation.isLoading} />
        </div>
      </Form>
    </Modal>
  );
};

export default UserPasswordChangeModal;
