// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { Nav } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IconNavCaretRight } from '../../common/utilities';

interface Props {
  className?: string;

  eventKey?: string;

  label: string;

  labelStyle?: React.CSSProperties;

  link: string;
}

/**
 * Navigation sub item for left nav
 */
const NavSubItem = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <Nav.Link as={Link} className={'py-1'} eventKey={props.eventKey} to={props.link}>
      <IconNavCaretRight style={{ color: '#ccc', fontSize: '10px' }} />
      <span className={`ps-2`} style={{ ...props.labelStyle }}>
        {' '}
        {props.label}
      </span>
    </Nav.Link>
  );
};

export default withTranslation()(NavSubItem);
